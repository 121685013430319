<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormSignature
      :Signature="editSignature"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSignature from '../components/FormSignature.vue';

export default {
  components: {
    FormSignature,
  },
  name: 'EditSignature',
  module: 'Documents',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Signature. Default to {}.
      */
      editSignature: {},
      /**
       * Query Id for Signature
       */
      signatureId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('signatures', ['signatures', 'signature']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.signatureId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_signature(this.signatureId);
      this.editSignature = this.signature;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('signatures', ['LOAD_signature', 'CREATE_signature', 'UPDATE_signature']),
    /** Method used to save the item
     */
    async save(signature) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_signature(signature);
      } else {
        await this.UPDATE_signature(signature);
      }
      this.isSaving = false;
      this.$router.push({ name: 'signature-ListSignature' });
    },
  },
};
</script>

<docs>
# EditSignature example

'''js
<EditSignature :Signature="Signature" />
'''
</docs>
