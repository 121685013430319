<template>
  <v-card elevation="3" style="height: 100%;">
    <v-btn icon
      color="primary"
      class="icon text-right"
      :to="{
          name: 'products-ProductDetail',
          query: { productCode: item.productCode }
      }">
      <v-icon
      color="primary"
      v-on:click="$router.push()"
      dark>
        mdi-information
      </v-icon>
    </v-btn>
    <div style="padding: 5px">
      <div class="super-deal text-center" v-if="item.discountType === 'SuperDeal'">
        Super Deal
      </div>
      <div class="super-deal-discount text-center" v-if="item.discountType === 'SuperDeal'">
        {{ item.discountPercentage}}% OFF
      </div>
    </div>
    <v-card-text style="text-align: center;">
      <v-row no-gutters align="center" justify="center" class="fill-height">
        <v-col cols="6" lg="12" xl="12">
          <router-link :to="{
            name: 'products-ProductDetail',
            query: { productCode: item.productCode }
          }">
            <ProductImage :item="item" />
          </router-link>
        </v-col>
        <v-col cols="6" lg="12" xl="12">
          <p class="caption">
            {{ item.stockDescription }}
          </p>
        </v-col>
      </v-row>
      <div class="stock-meta subtitle-1">
        <div>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  outlined
                  filled
                  :value="amount"
                  type="number"
                  class="quantity-textfield"
                  append-icon="mdi-plus"
                  @click:append="increment_amount(item.stockCode)"
                  prepend-inner-icon="mdi-minus"
                  @click:prepend-inner="decrement_amount(item.stockCode)">
                </v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="primary-block text-center">
                <b style="display: block">Your Price</b>
                <span class="discount-block">R {{ formatDiscount(item) }}</span>
              </div>
            </v-col>
          </v-row>
          <p class="caption" style="color: #4caf50; padding: 0"
            v-if="available(cart)">{{ item.available }} in stock</p>
          <p class="caption" style="color: #FF4500; padding: 0" v-else>On backorder</p>

          <p class="caption mb-1">
            Stock Code: {{ item.stockCode }}
          </p>
          <!-- <p class="c-price block">&nbsp;</p> -->
        </div>
      </div>
      <!-- <span v-if="!item.hasOptions" class="caption">Stock Code: {{item.stockCode}}</span>
      <span v-else class="caption">&nbsp;</span>
      <p class="caption">
        {{ item.leadTime }}
      </p> -->
    </v-card-text>
    <v-card-actions class="action-buttons-container">
      <v-btn
        class="primary rounded-lg text-capitalize"
        block
        data-cy="productList-addToCart"
        v-on:click="$emit('remove', item)">
      Remove
      </v-btn>
    </v-card-actions>
    </v-card>
</template>

<script>
import ProductImage from './ProductImage.vue';
import cart from './cartMixin';

export default {
  name: 'CartItem',
  components: {
    ProductImage,
  },
  mixins: [cart],
  props: {
    item: {
      type: Object,
      default: undefined,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    backgroundColour() {
      return process.env.VUE_APP_PRIMARY_COLOUR;
    },
  },
  methods: {
    available(item) {
      return item.available > 0;
    },
    formatDiscount(item) {
      return this.formatCurrency(item.price * ((100 - item.discountPercentage) / 100));
    },
  },
};
</script>
<style lang="scss" scoped>
.basic-block {
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 15px;
  color: black;
}
.primary-block {
  padding: 10px;
  background-color: var(--v-primary-base);
  border-radius: 15px;
  color: white;
}
.super-deal {
  background-color: #5ab55e;
  color: white;
  border-radius: 15px;
  position: absolute;
  left: 5px;
  top: 5px;
  height: 70px;
  width: 70px;
  z-index: 2;
  padding: 10px;
}
.super-deal-discount {
  background-color: var(--v-primary-base);
  color: white;
  border-radius: 15px;
  position: absolute;
  left: 5px;
  top: 80px;
  height: 70px;
  width: 70px;
  z-index: 2;
  padding: 10px;
}
.action-buttons-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 64px;
}
.stock-description {
  word-break: break-word;
  margin-top: auto;
  margin-bottom: auto;
}

.action-buttons {
  width: 100%;
}
.mb-40 {
  margin-bottom: 40px;
}
p.caption {
  margin-bottom: 5px;
}
</style>
<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .stock-description
      min-height: 64px

  @media #{map-get($display-breakpoints, 'md-and-down')}
    .stock-meta
      min-height: 175px
</style>
