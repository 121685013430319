<template>
     <v-card>
       <v-card-title>
         {{ localContact.contactName }}
       </v-card-title>
       <v-card-text>
         <template>
           <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
              :items="['ACCOUNTS', 'BUYING']"
              v-model="localContact.stockCategory"
              label="Category"
            ></v-select>
             <v-text-field
               label="Name"
               v-model="localContact.contactName"
             ></v-text-field>
             <v-text-field
               label="Office"
               v-model="localContact.contactOffice"
             ></v-text-field>
             <v-text-field
               label="Mobile"
               v-model="localContact.contactMobile"
             ></v-text-field>
             <v-text-field
               label="E-mail"
               v-model="localContact.eMailAddress"
               required
             ></v-text-field>
             <v-btn
              class="primary mb-2 rounded-lg text-capitalize"
               @click="submit"
               :loading="isLoading"
               :disabled="!valid"
             >
               Save
             </v-btn>
             <v-btn
              class="mb-2 rounded-lg text-capitalize float-right"
              :loading="isDeleting"
               @click="remove(localContact)"
             >
               Delete
             </v-btn>
           </v-form>
         </template>
       </v-card-text>
     </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Contact',
  props: {
    contact: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      localContact: {},
      isLoading: false,
      isDeleting: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters('contacts', ['getContactCategories']),
  },
  mounted() {
    this.loadContact();
  },
  methods: {
    ...mapActions('contacts', ['CREATE_contact', 'DELETE_contact', 'UPDATE_contact']),
    loadContact() {
      this.localContact = this.contact;
    },
    async submit() {
      const { localContact } = this;
      this.isLoading = true;
      if (this.localContact.id) {
        await this.UPDATE_contact(localContact);
      } else {
        await this.CREATE_contact(localContact);
        this.$emit('created', localContact);
      }
      this.isLoading = false;
      this.$root.$emit('toast:notify', `Saved ${localContact.contactName}`);
    },
    async remove(contact) {
      const res = await this.$confirm(`Do you really want to delete ${contact.contactName}?`, { title: 'Confirm' });
      if (res) {
        this.isDeleting = true;
        const { localContact } = this;
        await this.DELETE_contact(localContact.id);
        this.isDeleting = false;
        this.$root.$emit('toast:notify', `Deleted ${localContact.contactName}`);
      }
    },
  },
};
</script>

<style scoped>
.c-column {
  margin-top: -30px;
}
</style>
