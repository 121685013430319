<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
            max-height="64px"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              Tracking
            </v-toolbar-title>
          </v-toolbar>
          <iframe :src="trackingUrl" class="trackpod-frame" />
      </v-card>
    </v-dialog>
    <v-card v-if="trackingItems" :elevation="elev">
      <v-card-text no-title>
        <v-data-table
          :items="documents"
          item-key="reference"
          :headers="headers"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          min-width="700"
          sort-by="documentDate"
          :sort-desc="true"
          :dense="$vuetify.breakpoint.mdAndDown"
        >
          <template v-slot:item.documentDate="{ item }">
            {{ formatDocumentDate(item.documentDate) }}
          </template>
          <template v-slot:item.lastInvoice="{ item }">
            {{ formatLastInvoice(item) }}
          </template>
          <template v-slot:item.active="{ item }">
            {{ formatActive(item.active) }} {{ item.active }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="primary mb-2 rounded-lg mr-5"
              small
              @click="reorder(item)">
                Reorder
            </v-btn>
            <v-btn
              class="mb-2 rounded-lg text-capitalize mr-5"
              v-if="!item.isInvoiced && item.active === 'Y'"
              small
              @click="cancel(item)">
                Cancel
            </v-btn>
            <v-btn
              v-if="isTracked(item.lastInvoice)"
              class="primary mb-2 rounded-lg"
              @click="track(item)"
              :loading="itemsLoading.includes(item)"
              small
            >
              Track
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ isMobile, headers, item }">
            <div v-if="isMobile">
                <table
                  class="table" style="width: 100%"
                  v-for="line in item.salesOrderLines"
                 :key="line.id">
                  <tr>
                    <td colspan="2">
                      {{ line.stockDescription }}
                    </td>
                  </tr>
                  <tr class="mb-2">
                    <td>
                      Stock Code
                    </td>
                    <td class="text-right">
                      {{ line.stockCode }}
                    </td>
                  </tr>
                  <tr class="mb-2">
                    <td>
                      Qty
                    </td>
                    <td class="text-right">
                      {{ line.quantity }}
                    </td>
                  </tr>
                  <tr class="mb-2">
                    <td>
                      Unit Price ex VAT
                    </td>
                    <td class="text-right">
                      R {{ formatCurrency(line.unitSellingPriceExcl) }}
                    </td>
                  </tr>
                  <tr class="mb-2">
                    <td>
                      VAT
                    </td>
                    <td class="text-right">
                      R {{ formatCurrency(line.vat) }}
                    </td>
                  </tr>
                  <tr class="mb-2">
                    <td>
                      Total (incl VAT)
                    </td>
                    <td class="text-right">
                      R {{ formatCurrency(line.total) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <hr />
                    </td>
                  </tr>
                </table>
            </div>
           <td v-else :colspan="headers.length">
             <v-data-table
              :items="item.salesOrderLines"
              :headers="lineHeaders"
              class="mt-10"
            >
              <template v-slot:item.unitSellingPriceExcl="{ item }">
                R {{ formatCurrency(item.unitSellingPriceExcl) }}
              </template>
              <template v-slot:item.subtotal="{ item }">
                R {{ formatCurrency(item.subtotal) }}
              </template>
              <template v-slot:item.vat="{ item }">
                R {{ formatCurrency(item.vat) }}
              </template>
              <template v-slot:item.total="{ item }">
                R {{ formatCurrency(item.total) }}
              </template>
              <template v-slot:expanded-item="">
                &nbsp;
              </template>
             </v-data-table>
           </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { sumBy } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  mixins: [loading],
  data() {
    return {
      dialog: false,
      expanded: [],
      itemsLoading: [],
      singleExpand: false,
      trackingKey: '',
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('trackingItems', ['trackingItems']),
    documents() {
      return this.items.map((i) => {
        const result = { ...i };
        console.debug(result.lastInvoice);
        if (result.lastInvoice === null && result.active === 'N') {
          console.debug('cancelled order');
          result.lastInvoice = 'Cancelled Order';
        }
        result.salesOrderLines = i?.salesOrderLines?.map((l) => {
          const line = { ...l };
          line.subtotal = l.quantity * l.unitSellingPriceExcl;
          line.vat = line.subtotal * (l.vatRate / 100);
          line.total = line.subtotal + line.vat;
          return line;
        });
        result.total = `R ${this.formatCurrency(sumBy(result.salesOrderLines, (v) => v.total))}`;
        return result;
      });
    },
    headers() {
      const result = [
        { text: '', value: 'data-table-expand' },
        { text: 'Sales Order Reference', value: 'reference' },
        { text: 'Customer Order', value: 'customerOrderNo' },
        { text: 'Reference', value: 'lastInvoice' },
        { text: 'Date', value: 'documentDate' },
      ];
      // eslint-disable-next-line default-case
      switch (this.$vuetify.breakpoint.name) {
      case 'md':
      case 'lg':
      case 'xl':
        result.push({ text: 'Total (incl VAT)', value: 'total' });
        break;
      }
      result.push({ text: '', value: 'actions' });
      return result;
    },
    lineHeaders() {
      switch (this.$vuetify.breakpoint.name) {
      default:
        return [
          { text: 'Code', value: 'stockCode', width: '20%' },
          { text: 'Qty', value: 'quantity', width: '10%' },
          { text: 'Unit Price ex VAT', value: 'unitSellingPriceExcl', width: '15%' },
          { text: 'VAT', value: 'vat', width: '15%' },
          { text: 'Total (incl VAT)', value: 'total', width: '20%' },
        ];
      case 'md':
      case 'lg':
      case 'xl':
        return [
          { text: 'Description', value: 'stockDescription', width: '20%' },
          { text: 'Code', value: 'stockCode', width: '10%' },
          { text: 'Quantity', value: 'quantity', width: '10%' },
          { text: 'Unit Price ex VAT', value: 'unitSellingPriceExcl', width: '10%' },
          { text: 'Subtotal', value: 'subtotal', width: '10%' },
          { text: 'VAT', value: 'vat', width: '10%' },
          { text: 'Total (incl VAT)', value: 'total', width: '10%' },
        ];
      }
    },
    trackingUrl() {
      return `https://web.track-pod.com/en/track?id=${this.trackingKey}`;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_trackingItems();
    console.log('loaded');
    this.isLoading = true;
  },
  methods: {
    ...mapActions('products', ['LOAD_productFromCode']),
    ...mapActions('salesOrders', ['DELETE_salesOrder', 'LOAD_tracking']),
    ...mapActions('trackingItems', ['LOAD_trackingItems']),
    ...mapMutations('cart', ['ADD_TO_CART', 'RESET_CART']),
    formatActive(active) {
      if (active === 'Y') {
        return 'Yes';
      }
      return 'No';
    },
    formatDocumentDate(date) {
      return date?.toString().substring(0, 10);
    },
    formatLastInvoice(item) {
      return item.lastInvoice ?? 'Processing Order';
    },
    isTracked(ref) {
      return this.trackingItems
        .filter((t) => t.orderNumber === ref)
        .length > 0;
    },
    reorder(item) {
      this.RESET_CART();
      item.salesOrderLines.forEach(async (l) => {
        try {
          const product = await this.LOAD_productFromCode(l.stockCode);
          if (product) {
            product.description = product.stockDescription;
            product.price = product.exclUnitSellingPrice;
            this.ADD_TO_CART({
              amount: l.orderedQty,
              product,
            });
          }
        } catch (error) {
          this.$root.$emit('toast:error', `This product ${l.stockCode} is currently unavailable`);
        }
      });
      this.$router.push('/products/cart');
    },
    async cancel(item) {
      // eslint-disable-next-line no-alert
      const result = window.confirm(
        'Are you sure you want to cancel this order?',
      );
      if (result) {
        await this.DELETE_salesOrder(item.reference);
        this.$root.$emit('toast:notify', 'Sales order cancelled');
      }
    },
    async track(item) {
      try {
        this.itemsLoading.push(item);
        const tracking = await this.LOAD_tracking(item.lastInvoice);
        if (tracking?.trackKey?.length > 1) {
          this.dialog = true;
          this.trackingKey = tracking?.trackKey;
        } else {
          this.$root.$emit('toast:error', 'No tracking information available yet. Your order is still being processed. Tracking information will be available shortly. Please try again a bit later.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.itemsLoading = this.itemsLoading.filter((i) => i !== item);
      }
    },
  },
};
</script>

<style scoped>
.trackpod-frame {
  width: 100%;
  height: 100%;
}
</style>
<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'
  @media #{map-get($display-breakpoints, 'md-and-down')}
    .v-data-table__expanded
      box-shadow: unset !important
</style>
