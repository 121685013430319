<template>
  <v-container fluid>
    <v-card class="pa-4">
      <ImageUpload @input="updateImage" />
      <v-img v-if="product" :src="product.merchandiseImgUrl" height="500" contain />
        <v-card-title v-if="product">
          {{ product.stockDescription }}
        </v-card-title>
        <v-card-text v-if="product">
          <div class="text--primary">
            <VueEditor v-model="product.merchandiseDescription" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color=""
          >
           Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="save"
          >
           Save
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/ImageUpload.vue';

export default {
  name: 'EditProduct',
  components: {
    ImageUpload,
    VueEditor,
  },
  data() {
    return {
      productId: this.$route.query.id,
      products: [],
      product: undefined,
      isSaving: false,
    };
  },
  async created() {
    await this.refresh();
  },
  methods: {
    ...mapActions('products', ['LOAD_productByProductCode', 'UPDATE_product']),
    async refresh() {
      this.products = await this.LOAD_productByProductCode(this.productId);
      if (this.products?.length > 0) {
        [this.product] = this.products;
      }
    },
    async save() {
      this.isSaving = true;
      await this.UPDATE_product({
        stockCode: this.product.stockCode,
        merchandiseDescription: this.product.merchandiseDescription,
        merchandiseImgUrl: this.product.merchandiseImgUrl,
      });
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Product updated successfully');
    },
    updateImage($evt) {
      this.product.merchandiseImgUrl = $evt;
    },
  },
};
</script>
