import axios from 'axios';

const customActions = {
  async LOAD_clientAppSettings({ commit }) {
    const result = await axios.get('auroraApi/clientAppSettings/jsApp');
    commit('SET_clientAppSettings', result.data);
  },
};

export default { ...customActions };
