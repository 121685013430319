<template>
  <v-btn text class="grey--text rounded-pill"
    data-cy="buttonCart"
    :to="{
      name: 'products-Cart',
    }"
    >
    <v-badge
      color="primary"
      left
      transition="slide-x-transition"
    >
      <template v-slot:badge>
        {{ total_items }}
      </template>
      <v-icon color="black" class="mr-1" size="26"> mdi-cart-outline </v-icon>
      R {{ formatCurrency(total_amount) }}
    </v-badge>
  </v-btn>
</template>

<script>
import cart from '../modules/products/src/components/cartMixin';

export default {
  mixins: [cart],
  computed: {
    isLoggedIn() {
      return this.$store.state.app.isLoggedIn;
    },
  },
};
</script>
