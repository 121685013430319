<template>
  <div>
    <h3>Terms and Conditions</h3>
    <div v-for="(term, index) in terms" :key="index">
      <h4 v-if="term.name.length > 0">
        {{index + 1}}. {{ term.name}}
      </h4>
      <p
        v-for="(paragraph, pIndex) in term.paragraphs"
        :key="`paragraph-${pIndex}`">
        {{ paragraph }}
      </p>
      <ul v-if="term.points" class="mb-10">
        <li v-for="(point, pIndex) in term.points" :key="`point-${pIndex}`">
          {{ point }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import terms from './Terms.json';

export default {
  name: 'CreditApplicationTerms',
  data() {
    return {
      terms,
    };
  },
};
</script>
