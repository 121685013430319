<template>
  <v-data-table
    :headers="headers"
    :items="creditApplications"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="CreditApplication-button-new"
          @click="$router.push({
            name: 'documents-EditCreditApplication'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          New Credit Application
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'documents-EditCreditApplication',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListCreditApplication',
  module: 'Documents',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('creditApplications', ['creditApplications']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Registered Company Name',
          value: 'registeredCompanyName',
        },
        {
          text: 'Company Registration Number',
          value: 'companyRegistrationNumber',
        },
        {
          text: 'Credit Required Per Month',
          value: 'creditRequiredPerMonth',
        },
        {
          text: 'Accounts Contact Name',
          value: 'accountsContactName',
        },
        {
          text: 'Accounts Contact Email',
          value: 'accountsContactEmail',
        },
        {
          text: 'Has Surety',
          value: 'isSurety',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_creditApplications();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('creditApplications', ['LOAD_creditApplications', 'DELETE_creditApplication']),
    /** Delete CreditApplication from the collection
     */
    async remove(creditApplication) {
      this.isLoading = true;
      await this.DELETE_creditApplication(creditApplication.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# CreditApplication example

'''js
<CreditApplication />
'''
</docs>
