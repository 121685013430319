<template>
  <div>
    <h3>Definitions</h3>
    <div v-for="(term, index) in items" :key="index">
      <h4 v-if="term.name.length > 0">
        {{index + 1}}. {{ term.name}}
      </h4>
      <p
        v-for="(paragraph, pIndex) in term.paragraphs"
        :key="`paragraph-${pIndex}`">
        {{ paragraph }}
      </p>
      <ul v-if="term.points" class="mb-10">
        <li v-for="(point, pIndex) in term.points" :key="`point-${pIndex}`">
          {{ point }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import items from './Definitions.json';

export default {
  name: 'CreditApplicationTerms',
  data() {
    return {
      items,
    };
  },
};
</script>
