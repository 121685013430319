<template>
  <v-data-table
    :headers="headers"
    :items="signatures"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="Signature-button-new"
          @click="$router.push({
            name: 'documents-EditSignature'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'documents-EditSignature',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSignature',
  module: 'Documents',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('signatures', ['signatures']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('signatureType'),
          value: 'signatureType',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('position'),
          value: 'position',
        },
        {
          text: this.$$t('date'),
          value: 'date',
        },
        {
          text: this.$$t('signature'),
          value: 'signature',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_signatures();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('signatures', ['LOAD_signatures', 'DELETE_signature']),
    /** Delete Signature from the collection
     */
    async remove(signature) {
      this.isLoading = true;
      await this.DELETE_signature(signature);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Signature example

'''js
<Signature />
'''
</docs>
