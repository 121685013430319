import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('salesOrder', 'salesOrders');

const customActions = {
  async LOAD_SalesOrdersWithLines({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('salesOrders')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'SalesOrderLines',
        $orderby: 'documentDate DESC',
      });
    commit('SET_salesOrders', data);
  },
  async DELETE_salesOrder({ commit, rootGetters, state }, id) {
    const i = odata.parseId(id);
    await odata.o(rootGetters)
      .delete(`salesOrders/${i}`)
      .query();
    const items = state.salesOrders;
    items.forEach((s) => {
      if (s.reference === id) {
        // eslint-disable-next-line no-param-reassign
        s.active = 'N';
      }
    });
    commit('SET_salesOrders', items);
  },
  async LOAD_tracking({ rootGetters }, orderNo) {
    const data = await odata.o(rootGetters)
      .get(`salesOrders/Tracking(orderNo='${orderNo}')`)
      .query();
    return data;
  },
};

export default { ...actions, ...customActions };
