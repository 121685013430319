<template>
  <v-container>
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col lg="8">
        <v-card flat class="rounded-0 ma-3 pa-4">
          <FormLogin @login="onLogin" />
          <v-container>
              <ForgotPasswordModal label="Forgot Login?" />
          </v-container>
          <h4><span>Or</span></h4>
          <v-container>
              <v-row class="mt-0" justify="center">
                <v-btn text class="title" to="/auth/register">
                  Register New Account
                </v-btn>
              </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormLogin from '../components/FormLogin.vue';
import ForgotPasswordModal from '../components/ForgotPasswordModal.vue';

export default {
  name: 'login',
  components: {
    FormLogin,
    ForgotPasswordModal,
  },
  methods: {
    async onLogin() {
      this.$root.$emit('auth:login');
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
   width: 100%;
   text-align: center;
   border-bottom: 1px solid #000;
   line-height: 0.1em;
   margin: 10px 0 20px;
}

h4 span {
    background:#fff;
    padding:0 10px;
}
</style>
