<template>
  <v-container>
    <v-row class="hidden-md-and-down">
      <v-col cols="10">
        <carousel
          :perPageCustom="[[300,2 ], [768, 4], [1024, 5], [1200, 6]]"
          :navigationEnabled="true" :paginationEnabled="false">
          <slide v-for="brand in brands" :key="brand.name">
            <v-img
              max-width="128"
              class="mr-4 brand-image"
              contain
              :src="brand.imageUrl"
              @click="$router.push({ name: 'products-ProductList',
                query: { filterBrand: brand.name }})"
              style="cursor: pointer;"
            />
          </slide>
        </carousel>
      </v-col>
      <v-col cols="2">
        <div class="brandsLink">
          <v-btn
              :to="{ name: 'products-ProductList' }"
              text
              x-large
            >All Brands</v-btn>
        </div>
      </v-col>
    </v-row>
    <slot></slot>
  </v-container>
</template>
<style scoped>
.brandsLink{
  margin-top: 39px;
}
.brand-image {
  min-height: 128px;
}
</style>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
  },
  data() {
    return {
      slider: undefined,
    };
  },
  computed: {
    ...mapState('products', ['productBrands']),
    ...mapState('brands', ['brands']),
    items() {
      const { productBrands } = this;
      return productBrands
        .filter((b) => b.merchandiseBrand !== null && b.merchandiseBrand !== '')
        .map((b) => ({
          name: b.merchandiseBrand,
          imageUrl: this.getImageUrl(b),
        }));
    },
  },
  async created() {
    await this.LOAD_brands();
    await this.LOAD_productBrands();
  },
  methods: {
    ...mapActions('products', ['LOAD_productBrands']),
    ...mapActions('brands', ['LOAD_brands']),
    getImageUrl(brand) {
      return this.brands.find((b) => b.name === brand.merchandiseBrand)?.imageUrl ?? 'https://business.landw.co.za/image-404.png';
    },
  },
  // directives: {
  //   swiper: directive,
  // },
};
</script>
