<template>
  <div class="custom-card rounded-10 rounded-lg"
    :class="{ 'elevation-3': $vuetify.breakpoint.mdAndUp }">
    <v-row no-gutters>
      <v-col
        cols="12" lg="4" xl="4" class="primary custom-card-title"
        :class="$vuetify.breakpoint.lgAndUp ? 'rounded-l-lg' : ''"
      >
        {{ heading }}
      </v-col>
      <v-col
        cols="12" lg="8" xl="8" class="custom-card-body"
        :class="$vuetify.breakpoint.lgAndUp ? 'rounded-r-lg' : ''"
      >
        {{ value }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: 'Heading',
    },
    value: {
      type: String,
      default: 'Value',
    },
  },
};
</script>
<style scoped>
.custom-card-title {
  color: white;
  padding: 16px !important;
}
.custom-card-body {
  background-color: white;
  padding: 16px !important;
  margin-right: -1px;
}
</style>
