<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    color="primary"
    type="article"
  >
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <v-btn text color="primary" :to="{ name: 'products-ProductList' }">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-form v-model="isValid" ref="form">
            <v-row v-if="product">
              <v-col
                class="d-flex align-stretch" cols="12" sm="12" xl="6" lg="6"
              >
                <v-card class="product-detail-card" style="width: 100%" :elevation="elev">
                  <v-card-text>
                    <div class="super-deal text-center" v-if="product.discountType === 'SuperDeal'">
                      Super Deal
                    </div>
                    <div class="super-deal-discount text-center"
                      v-if="product.discountType === 'SuperDeal'"
                    >
                      {{ product.discountPercentage}}% OFF
                    </div>
                    <ProductImage :item="product" :showInfo="false" :maxHeight="350" />
                    <UploadFile v-if="canEdit"
                      class="mt-2"
                      accept="image/*"
                      label="Upload new product image"
                      @url="updateImage"
                      >
                      <v-icon>mdi-pencil</v-icon>
                      Upload new image
                    </UploadFile>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="product"
                class="d-flex align-stretch" cols="12" xl="6" lg="6"
              >
                <v-card style="width: 100%" :elevation="elev">
                  <v-card-text>
                    <h2 class="mx-3 mt-1 mb-2">{{product.description}}</h2>
                    <p class="mx-3 pb-2 p-section">
                      <v-row>
                        <v-col v-if="!isPublic" cols="6">
                          <div class="basic-block text-center">
                            <b style="display: block">List Price</b>
                            <span>
                              R {{formatCurrency(product.price)}}
                            </span>
                          </div>
                        </v-col>
                        <v-col :cols="isPublic ? '12' : '6'">
                          <div class="primary-block text-center">
                            <b style="display: block">
                              {{ isPublic ? 'Price' : 'Your Price' }}
                            </b>
                            <span class="discount-block">R {{ formatDiscount(product) }}</span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span
                            class="caption d-block text-center"
                            style="color: #4caf50"
                            v-if="available(product)">
                              {{ product.available }} in stock
                          </span>
                          <span
                            class="caption"
                            style="color: #FF4500"
                            v-else>On backorder</span>
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <span class="text-subtitle-2 text-black">excl VAT</span>
                        </v-col>
                      </v-row>
                    </p>
                    <p class="mx-3 pb-2 p-section">
                        <v-container fluid class="ma-0 pa-0">
                          <v-row class="ma-0 mt-5">
                            <v-col v-if="productOptions.length > 1" lg="3" class="ma-0 pa-0 mr-2">
                              <v-select
                                outlined
                                filled
                                :items="productOptions"
                                v-model="productOption"
                                item-text="option"
                                item-value="stockCode"
                                label="Options"
                                @input="productOptionChanged"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" xl="3" lg="3"
                                class="ma-0 pa-0 mr-2 text-center">
                              <v-text-field
                                class="quantity-textfield"
                                outlined
                                filled
                                v-model="amount"
                                type="number"
                                :rules="[rules.non_zero, minQty, validateIncrement]"
                                append-icon="mdi-plus"
                                @click:append="increment"
                                prepend-inner-icon="mdi-minus"
                                @click:prepend-inner="decrement">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" xl="3" lg="3" class="ma-0 mt-1 pa-0">
                              <v-btn
                                height="50"
                                block
                                color="success"
                                class="mb-2 rounded-lg text-capitalize"
                                v-on:click="add_to_cart">
                              Add to cart
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                          <span class="caption">
                            Minimum Order Quantity: {{product.minimumOrderQuantity}}
                          </span>
                          <br />
                          <span class="caption">
                            Stock Code: {{product.stockCode}}
                          </span>
                          <br/>
                          <span class="caption">
                            Category: {{ product.stockCategory }}
                          </span>
                          <br/>
                          <span class="caption">
                            Product Weight: {{ product.unitGrams }}g
                          </span>
                          <br/>
                          <span class="caption">
                            Unit Of Measure: {{ product.packSize }}
                          </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product">
              <v-col cols="12" v-if="product.merchandiseDescription || canEdit">
                <v-card :elevation="elev">
                  <v-btn
                    v-if="mode === 'view' && canEdit"
                    @click="mode = 'edit'"
                    title="Edit description"
                  >
                    <v-icon class="mr-2" color="secondary">mdi-pencil</v-icon>
                    Edit Description
                  </v-btn>
                  <v-card-text v-if="mode === 'view'"
                    class="merch-desc" v-html="product.merchandiseDescription"
                  >
                  </v-card-text>
                  <VueEditor v-else v-model="product.merchandiseDescription" />
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="canEdit && product">
              <v-col cols="12">
                <v-textarea v-model="product.keywords" label="SEO Keywords" outlined></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn class="float-right" color="success" @click="saveProduct">
                  <v-icon>mdi-check</v-icon>
                  Save Product
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-skeleton-loader>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { sortBy } from 'lodash';
import { VueEditor } from 'vue2-editor';
import ProductImage from '../components/ProductImage.vue';
import content from '../../../../mixins/content';
import UploadFile from '../components/UploadFile.vue';

export default {
  name: 'ProductDetail',
  components: {
    ProductImage,
    UploadFile,
    VueEditor,
  },
  mixins: [content],
  data() {
    return {
      isLoading: true,
      /**
      * Hold form validation state
      */
      isValid: false,
      mode: 'view',
      product: null,
      amount: 1,
      rules: {
        required: (value) => !!value || 'Required.',
        non_zero: (value) => (value <= 0 ? 'Add proper Amount' : true),
      },
      productCode: this.$route.query.productCode,
      productOption: null,
      productsByCode: [],
    };
  },
  computed: {
    // imageHeight() {
    //   return this.$refs.colRight?.clientHeight ?? 400;
    // },
    /** Vuex getters
     */
    ...mapGetters('app', ['isPublic']),
    ...mapGetters('products', ['getProduct', 'productList', 'getProductsByCode', 'shopProduct', 'shopProducts']),
    ...mapState('products', ['products']),
    ...mapState('customerDeals', ['customerDeals']),
    ...mapState('app', ['isLoggedIn']),
    productOptions() {
      return this.sortedProducts.map((p) => {
        if (p.productOpt2?.length > 0) {
          return {
            stockCode: p.stockCode,
            option: `${p.productOpt1} - ${p.productOpt2}`,
          };
        }
        return {
          stockCode: p.stockCode,
          option: `${p.productOpt1}`,
        };
      });
    },
    sortedProducts() {
      return sortBy(this.productsByCode, (o) => parseInt(o.length, 10)).reverse();
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['DELETE_product', 'LOAD_productByProductCode', 'UPDATE_product']),
    ...mapActions('customerDeals', ['LOAD_customerDeals']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    available(item) {
      return item.available > 0;
    },
    minQty(val) {
      const { product } = this;
      if (val < product?.minimumOrderQuantity) {
        return `Must order ${product.minimumOrderQuantity} or more`;
      }
      return true;
    },
    updateImage(url) {
      console.debug(url);
      this.product.merchandiseImgUrl = url;
    },
    validateIncrement(number) {
      const { product } = this;
      // eslint-disable-next-line max-len
      const round = Math.round(number / product.minimumOrderQuantity);

      if ((number / product.minimumOrderQuantity) === round) {
        return true;
      }
      return `${number} must be an increment of ${product.minimumOrderQuantity}.`;
    },
    increment() {
      const { product } = this;
      this.amount = parseInt(this.amount, 10) + product.minimumOrderQuantity;
    },
    decrement() {
      const { product } = this;
      this.amount = parseInt(this.amount, 10) - product.minimumOrderQuantity;
    },
    add_to_cart() {
      if (this.amount <= 0) {
        return;
      }
      const result = this.$refs.form.validate();
      if (result) {
        this.ADD_TO_CART({
          amount: this.amount,
          product: this.product,
        });
        this.$root.$emit('toast:notify', `Added ${this.product.description} to your cart`);
      }
    },
    productOptionChanged(stockCode) {
      this.product = this.productsByCode.find((p) => p.stockCode === stockCode);
    },
    formatDiscount(item) {
      return this.formatCurrency(item.price * ((100 - item.discountPercentage) / 100));
    },
    async saveProduct() {
      const {
        keywords, merchandiseDescription, merchandiseImgUrl, stockCode,
      } = this.product;
      try {
        await this.$http.post('api/product-content', {
          stockCode,
          keywords,
          merchandiseDescription,
          merchandiseImgUrl,
        });
        this.$root.$emit('toast:notify', `Saved ${stockCode}`);
        this.mode = 'view';
      } catch (error) {
        this.$root.$emit('toast:error', `Error saving ${stockCode}`);
      }
    },
  },
  async created() {
    this.isLoading = true;
    let products = null;
    await Promise.all([
      (async () => {
        products = await this.LOAD_productByProductCode(this.productCode);
      })(),
      (async () => {
        if (this.customerDeals?.length === 0 && this.isLoggedIn) {
          await this.LOAD_customerDeals();
        }
      })(),
    ]);
    this.productsByCode = this.shopProducts(products);
    this.product = this.productsByCode[0] ?? null;
    if (this.product?.hasOptions) {
      this.productOption = this.productOptions[0] ?? null;
    }
    if (this.product?.minimumOrderQuantity > 1) {
      this.amount = this.product.minimumOrderQuantity;
    }
    this.isLoading = false;
  },
};
</script>

<style scoped>
.p-section {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.c-select-field {
  width: 245px;
}
</style>

<style scoped>
.basic-block {
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 15px;
  color: black;
}
.primary-block {
  padding: 10px;
  background-color: var(--v-primary-base);
  border-radius: 15px;
  color: white;
}
.v-text-field__slot > input {
  text-align: center;
}
</style>

<style>
.merch-desc img {
  display: none;
}
.feature_box,
.note_box,
#tc-desc-header {
  display: none;
}
</style>
