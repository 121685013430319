<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-left">
        <div class="text-h5">
          Company Information
        </div>
        <v-row>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormCreditDocument-accountName"
            label="Legg & Wessel Account Name:"
            v-model="localCreditDocument.accountName"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-checkbox
            v-model="localCreditDocument.creditIncrease"
            label="Credit increase Required?"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormCreditDocument-currentCreditLimit"
            label="Current Credit Limit:"
            v-model="localCreditDocument.currentCreditLimit"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormCreditDocument-newCreditLimit"
            label="New Credit Limit Being Requested:"
            v-model="localCreditDocument.newCreditLimit"
            />
          </v-col>
        </v-row>
        <div class="text-h5">
          Director / Propietor Information
        </div>
        <v-row>
          <v-col cols="4">
              <v-text-field
              filled
              data-cy="FormCreditDocument-directorName1"
              label="Name:"
              v-model="localCreditDocument.directorName1"
              />
          </v-col>
          <v-col cols="4">
              <v-text-field
              filled
              data-cy="FormCreditDocument-directorIdNumber1"
              label="ID No:"
              v-model="localCreditDocument.directorIdNumber1"
              />
          </v-col>
          <v-col cols="4">
              <v-text-field
              filled
              data-cy="FormCreditDocument-directorAddress1"
              label="Address:"
              v-model="localCreditDocument.directorAddress1"
              />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorName2"
            label="Name:"
            v-model="localCreditDocument.directorName2"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorIdNumber2"
            label="ID No:"
            v-model="localCreditDocument.directorIdNumber2"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorAddress2"
            label="Address:"
            v-model="localCreditDocument.directorAddress2"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorName3"
            label="Name:"
            v-model="localCreditDocument.directorName3"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorIdNumber3"
            label="ID No:"
            v-model="localCreditDocument.directorIdNumber3"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-directorName3ectorAddress3"
            label="Address:"
            v-model="localCreditDocument.directorAddress3"
            />
          </v-col>
        </v-row>
        <div class="text-h5">
          Signatories
        </div>
        <v-row>
          <v-col cols="4">
            <div>
            Signed:
            </div>
            <VueSignaturePad width="370px" height="150px" ref="signaturePadSigned1"
              :customStyle="blackBorder" />
          </v-col>
          <v-col cols="4">
            <div>
            Witness:
            </div>
            <VueSignaturePad width="370px" height="150px" ref="signaturePadSigned2"
              :customStyle="blackBorder" />
          </v-col>
          <v-col cols="4">
            <div>
            Witness:
            </div>
            <VueSignaturePad width="370px" height="150px" ref="signaturePadSigned3"
              :customStyle="blackBorder" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Name1"
            label="Name:"
            v-model="localCreditDocument.SignatoryName1"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Name2"
            label="Name:"
            v-model="localCreditDocument.SignatoryName2"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Name3"
            label="Name:"
            v-model="localCreditDocument.SignatoryName3"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Position1"
            label="Position:"
            v-model="localCreditDocument.SignatoryPosition1"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Position2"
            label="Position:"
            v-model="localCreditDocument.SignatoryPosition2"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Position3"
            label="Position:"
            v-model="localCreditDocument.SignatoryPosition3"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Date1"
            label="Date:"
            v-model="localCreditDocument.SignatoryDate1"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Date2"
            label="Date:"
            v-model="localCreditDocument.SignatoryDate2"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
            filled
            data-cy="FormCreditDocument-Date3"
            label="Date:"
            v-model="localCreditDocument.SignatoryDate3"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCreditDocument-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormCreditDocument-button-save">
              Save for Later
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormCreditDocument',
  module: 'CreditDocument',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CreditDocument: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      blackBorder: { border: 'black 3px solid' },
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCreditDocument
      */
      localCreditDocument: {},
    };
  },
  watch: {
    CreditDocument() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    /**
     * Executes load
     */
    load() {
      this.localCreditDocument = this.CreditDocument;
      this.$refs.signaturePadSigned1.fromDataURL(this.CreditDocument.signed1);
      this.$refs.signaturePadSigned2.fromDataURL(this.CreditDocument.signed2);
      this.$refs.signaturePadSigned3.fromDataURL(this.CreditDocument.signed3);
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      const setSignatures = (pad) => {
        const { isEmpty, data } = pad.saveSignature();
        console.log(isEmpty);
        console.log(data);
        return data;
      };
      this.localCreditDocument.signed1 = setSignatures(this.$refs.signaturePadSigned1);
      this.localCreditDocument.signed2 = setSignatures(this.$refs.signaturePadSigned2);
      this.localCreditDocument.signed3 = setSignatures(this.$refs.signaturePadSigned3);
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCreditDocument);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCreditDocument example

'''js
<FormCreditDocument :CreditDocument="CreditDocument" />
'''
</docs>
