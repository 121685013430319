<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h2>Payment Failure</h2>
          </v-card-title>
          <v-card-text>
            <p>Sorry, your payment failed.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PaymentFailure',
};
</script>
