<template>
  <div>
    <v-menu
      v-model="localOpenModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="localDate"
          :label="label"
          :rules="[rules.required]"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="localDate"
        @input="inputDate()"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import validation from './validation';

export default {
  name: 'DatePicker',
  mixins: [validation],
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Date',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localOpenModal: this.openModal,
      localDate: this.value,
    };
  },
  mounted() {
    if (this.value.length > 10) {
      this.localDate = this.value.substring(0, 10);
    }
  },
  methods: {
    inputDate() {
      this.localOpenModal = false;
      this.$emit('input', this.localDate);
    },
  },
};
</script>
