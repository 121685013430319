import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('page', 'pages');

const customActions = {
  async LOAD_pageByName({ commit, rootGetters }, name) {
    const data = await odata.o(rootGetters)
      .get('pages')
      .query({ $filter: `Slug eq '${name}'` });

    if (data.length === 0) {
      return null;
    }
    commit('SET_page', data[0]);
    return data[0];
  },
};

export default { ...actions, ...customActions };
