<template>
  <v-container v-if="show">
    <div class="justify-space-between align-center">
      <h2>{{ title }}</h2>
    </div>
    <v-progress-linear :indeterminate="true" v-if="isLoading"></v-progress-linear>
    <v-row>
      <v-col cols="12">
        <carousel
              :perPageCustom="[[300,2 ], [768, 3], [1024, 3], [1280, 3]]"
              :navigationEnabled="true" >
          <slide v-for="item in newProductList" :key="item.id">
            <div style="padding: 10px">
            <ProductItem
              class="new-arrival-product-item"
              :item="item"
            />
            </div>
          </slide>
        </carousel>
        <!-- <v-slide-group
          multiple
          show-arrows
          class="slider"
        >
          <v-slide-item
            v-for="item in newProductList"
            :key="item.id"
          >
            <ProductItem
              class="new-arrival-product-item"
              :item="item"
            />
          </v-slide-item>
        </v-slide-group> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<style>

/* .VueCarousel-slide {
  max-width: 300px;
} */
  .slider .v-slide-group__content {
    padding: 20px;
  }
  .new-arrival-product-item{
    min-height: 512px;
    max-width: 300px;
    margin-right: 10px;
  }
  .row-new-arrivals > div {
    margin-right: -40px;
    margin-left: -40px;
  }
</style>
<style scoped>
</style>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import ProductItem from '../ProductItem.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductItem,
  },
  props: {
    title: {
      type: String,
      default: 'New Arrivals',
    },
  },
  data() {
    return {
      show: true,
      slider: undefined,
      // options: {
      //   perPage: 5,
      //   gap: '20px',
      // },
      isLoading: true,
      itemsPerPage: 12,
      sortBy: 'description',
      sortDesc: false,
      search: '',
      keys: [
        'Price',
        'Description',
        'Available',
        'StockCode',
      ],
    };
  },
  computed: {
    ...mapGetters('products', ['newProductList']),
    slidesPerView() {
      switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 2;
      case 'sm': return 2;
      case 'md': return 3;
      case 'lg': return 3;
      case 'xl': return 4;
      default: return 4;
      }
    },
    swiperOptions() {
      const { slidesPerView } = this;
      return {
        slidesPerView,
        updateOnWindowResize: true,
        spaceBetween: 20,
        loop: false,
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: true,
        // },
        navigation: {
          nextEl: '#productsNext',
          prevEl: '#productsPrev',
        },
      // Some Swiper option/callback...
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_newProducts();
    this.isLoading = false;
  },
  // created() {
  //   window.addEventListener('resize', this.resizeHandler);
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resizeHandler);
  // },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_newProducts']),
    ...mapActions('customerDeals', ['LOAD_customerDeals']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    next() {},
    prev() {},
    resizeHandler() {
      // this.$refs.swiper.updateSize();'
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
