<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormCreditApplication
      :CreditApplication="editCreditApplication"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCreditApplication from '../components/FormCreditApplication.vue';

export default {
  components: {
    FormCreditApplication,
  },
  name: 'EditCreditApplication',
  module: 'Documents',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing CreditApplication. Default to {}.
      */
      editCreditApplication: {
        bankSignatures: [{}],
        signatory: {
          name: '',
        },
        witnessSignatures: [{}, {}],
        tradeReferences: [{}, {}, {}],
        debtorSignatures: [{}],
        suretySignatures: [{}],
        suretyWitnessSignatures: [{}, {}],
      },
      /**
       * Query Id for CreditApplication
       */
      creditApplicationId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('creditApplications', ['creditApplications', 'creditApplication']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.creditApplicationId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_creditApplication(this.creditApplicationId);
      this.editCreditApplication = this.creditApplication;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('creditApplications', ['LOAD_creditApplication', 'CREATE_creditApplication', 'UPDATE_creditApplication']),
    /** Method used to save the item
     */
    async save(creditApplication) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_creditApplication(creditApplication);
      } else {
        await this.UPDATE_creditApplication(creditApplication);
      }
      this.isSaving = false;
      this.$router.push({ name: 'MyAccount', query: { tab: 'documents' } });
    },
  },
};
</script>

<docs>
# EditCreditApplication example

'''js
<EditCreditApplication :CreditApplication="CreditApplication" />
'''
</docs>
