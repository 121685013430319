<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormSuperDeal
      :SuperDeal="editSuperDeal"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: 'products-ListSuperDeal' })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSuperDeal from '../components/FormSuperDeal.vue';

export default {
  components: {
    FormSuperDeal,
  },
  name: 'EditSuperDeal',
  module: 'SuperDeal',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing SuperDeal. Default to {}.
      */
      editSuperDeal: {},
      /**
       * Query Id for SuperDeal
       */
      superDealId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('customers', ['customers']),
    ...mapState('superDeals', ['superDeals', 'superDeal']),
    /** Get the mode for this view, create or update
     */
    mode() {
      console.log(this.superDealId);
      if (this.superDealId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_superDeal(this.superDealId);
      this.editSuperDeal = this.superDeal;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('superDeals', ['LOAD_superDeal', 'CREATE_superDeal', 'UPDATE_superDeal']),
    /** Method used to save the item
     */
    async save(superDeal) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_superDeal(superDeal);
      } else {
        await this.UPDATE_superDeal(superDeal);
      }
      this.isSaving = false;
      this.$router.push({ name: 'products-ListSuperDeal' });
    },
  },
};
</script>

<docs>
# EditSuperDeal example

'''js
<EditSuperDeal :SuperDeal="SuperDeal" />
'''
</docs>
