export default {
  getSalesOrder: (state) => state.salesOrder,
  salesOrdersWithLineInfo: (state, getters, rootState, rootGetters) => {
    const invoiceOrderNumbers = rootGetters['salesDocuments/getInvoices'].map((i) => i.orderNumber);
    return state.salesOrders.map((r) => {
      const result = { ...r };
      result.isInvoiced = invoiceOrderNumbers.includes(r.reference);
      result.lineItems = r.salesOrderLines?.map((l) => `${l.orderedQty} x ${l.stockCode}`).join(', ');
      return result;
    });
  },
};
