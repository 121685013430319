<template>
  <v-data-table
    :headers="headers"
    :items="salesReps"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="SalesRep-button-new"
          @click="$router.push({
            name: 'admin-EditSalesRep'
          })"
        >
          <v-icon class="mr-2">
            mdi-plus
          </v-icon>
          New
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn small :to="{
          name: 'admin-EditSalesRep',
          query: {id: item.id}
        }">Edit</v-btn>
      <v-btn small @click="remove(item)" color="error">
        Delete
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSalesRep',
  module: 'SalesRep',
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('salesReps', ['salesReps']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_salesReps();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('salesReps', ['LOAD_salesReps', 'DELETE_salesRep']),
    /** Delete SalesRep from the collection
     */
    async remove(salesRep) {
      const result = await this.$confirm('Are you sure you want to delete this?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_salesRep(salesRep.id);
        this.isLoading = false;
      }
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
  },
};
</script>
