<template>
  <v-card :loading="isLoading">
    <v-card-title class="text-h5 grey lighten-2">
      Product Group Deal
    </v-card-title>

    <v-card-text>
      <FormProductGroupDeal :ProductGroupDeal="editProductGroupDeal"
        @cancel="$router.push({ name: 'auth-ProductGroupDeal' })"
        @save="save"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormProductGroupDeal from '../components/FormProductGroupDeal.vue';

export default {
  name: 'CustomerProduct',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    FormProductGroupDeal,
  },
  data() {
    return {
      dialog: false,
      editProductGroupDeal: {},
    };
  },
  async mounted() {
    this.isLoading = true;
    const { id } = this.$route.query;
    if (id) {
      this.editProductGroupDeal = await this.LOAD_productGroupDeal(id);
    }
    await Promise.all([
      (async () => {
        await this.LOAD_customerCategories();
      })(),
      (async () => {
        await this.LOAD_productGroups();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('customers', ['LOAD_customerCategories']),
    ...mapActions('products', ['LOAD_productGroups', 'DELETE_productGroup', 'CREATE_productGroup']),
    ...mapActions('productGroupDeals', ['LOAD_productGroupDeals', 'LOAD_productGroupDeal', 'UPDATE_productGroupDeal', 'CREATE_productGroupDeal']),
    async save(item) {
      this.isLoading = true;
      this.dialog = false;
      if (item.id) {
        await this.UPDATE_productGroupDeal(item);
      } else {
        await this.CREATE_productGroupDeal(item);
      }
      this.$router.push({ name: 'auth-ProductGroupDeals' });
      this.isLoading = false;
    },
  },
};
</script>
