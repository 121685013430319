export default {
  async LOAD_jobsRecurring({ commit }) {
    const result = await this.$http.get('api/hangfire/jobs/recurring');
    commit('SET_jobsRecurring', result.data);
    return result.data;
  },
  async LOAD_jobsBus({ commit }, status) {
    const result = await this.$http.get(`api/hangfire/jobs/bus/${status}`);
    commit('SET_jobsBus', result.data);
    return result.data;
  },
  async LOAD_jobs({ commit }, status) {
    const result = await this.$http.get(`api/hangfire/jobs/${status}`);
    commit('SET_jobs', result.data);
    return result.data;
  },
};
