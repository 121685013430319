<template>
       <v-card elevation="0">
         <v-card-text>
          <v-row class="my-0">
              <v-col lg="10">
                <h4 class="mb-4">Billing Address</h4>
              </v-col>
              <v-container>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.postalAddress1"
                      placeholder="Billing Address Line 1"
                      class="rounded-lg"
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.postalAddress2"
                      placeholder="Billing Address Line 2"
                      filled
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.postalAddress3"
                      placeholder="Billing Address Line 3"
                      filled
                      class="rounded-lg"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.postalAddress4"
                      placeholder="Billing Address Line 4"
                      filled
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.postalCode"
                      placeholder="Billing Address Postal Code"
                      filled
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-row>
          <v-row class="mt-0" style="margin-top: -30px !important">
              <v-col lg="10">
                <h4 class="mb-4">Shipping Address</h4>
              </v-col>
              <v-container>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.physicalAddress1"
                      placeholder="Shipping Address Line 1"
                      class="rounded-lg"
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.physicalAddress2"
                      placeholder="Shipping Address Line 2"
                      filled
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.physicalAddress3"
                      placeholder="Shipping Address Line 3"
                      filled
                      class="rounded-lg"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" xl="6" class="c-column">
                    <v-text-field
                      v-model="localCustomerInfo.physicalAddress4"
                      placeholder="Shipping Address Line 4"
                      filled
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xl="6">
                    <v-btn
                      block
                      class="primary rounded-lg text-capitalize"
                      :loading="isLoading"
                      @click="save"
                    >
                        Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
          </v-row>
         </v-card-text>
       </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BillingAddress',
  data() {
    return {
      localCustomerInfo: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState('customerInfo', ['customerInfo']),
  },
  watch: {
    customerInfo() {
      this.localCustomerInfo = this.customerInfo;
    },
  },
  mounted() {
    if (this.customerInfo) {
      this.localCustomerInfo = this.customerInfo;
    }
  },
  methods: {
    ...mapActions('customerInfo', ['UPDATE_customerInfo']),
    async save() {
      this.isLoading = true;
      await this.UPDATE_customerInfo(this.localCustomerInfo);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
  },
};
</script>

<style scoped>
.c-column {
  margin-top: -30px;
}
</style>
