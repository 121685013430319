import { render, staticRenderFns } from "./SalesOrders.vue?vue&type=template&id=d23da872&scoped=true"
import script from "./SalesOrders.vue?vue&type=script&lang=js"
export * from "./SalesOrders.vue?vue&type=script&lang=js"
import style0 from "./SalesOrders.vue?vue&type=style&index=0&id=d23da872&prod&scoped=true&lang=css"
import style1 from "./SalesOrders.vue?vue&type=style&index=1&id=d23da872&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d23da872",
  null
  
)

export default component.exports