<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs" v-on="on"
        @click="open"
        icon
        class="white--text"
      >
        <v-icon>
          mdi-emoticon-happy-outline
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:default="">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
        We would love to hear your feedback! Tell us how we can make our Online Store Better?
        </v-toolbar>
        <v-card-text>
          <v-textarea
            label="Let us know what you think"
            textarea
            v-model="message"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            @click="send"
            :loading="isLoading"
          >Send</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-img v-if="screenshot" :src="screenshot" />
          <v-btn v-else @click="saveScreenShot" :loading="isLoadingScreenshot">
            Add Screenshot
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mdiEmoticonHappyOutline } from '@mdi/js';
import html2canvas from 'html2canvas';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'Feedback',
  mixins: [loading],
  data() {
    return {
      dialog: false,
      isLoadingScreenshot: false,
      message: '',
      screenshot: undefined,
      mdiEmoticonHappyOutline,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.saveScreenShot();
    },
    async saveScreenShot() {
      try {
        this.isLoadingScreenshot = true;
        const screenshotTarget = document.body;
        const canvas = await html2canvas(screenshotTarget);
        const base64image = canvas.toDataURL('image/png');
        this.screenshot = base64image;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingScreenshot = false;
      }
    },
    async send() {
      try {
        this.isLoading = true;
        const { message, screenshot } = this;
        await this.$http.post('odata/feedbackItems', {
          message,
          screenshot,
        });
        this.dialog = false;
        this.$root.$emit('toast:notify', 'Thank you for your feedback');
        this.message = '';
        this.screenshot = '';
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
