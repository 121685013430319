import Mutations from '../../odata/mutations';
import { set } from '../../utils';

const customMutations = {
  SET_newProducts: set('newProducts'),
  SET_productBrands: set('productBrands'),
  SET_favourites: set('favourites'),
  SET_totalProducts: set('totalProducts'),
  SET_stockCategories: set('stockCategories'),
  SET_productGroups: set('productGroups'),
  SET_productDeals: set('productDeals'),
};

export default { ...new Mutations('product'), ...new Mutations('products'), ...customMutations };
