<template>
  <div class="top-section">
    <div class="child-1" />
    <div class="child-2" v-if="customerInfo">
      <HomePageBoxItem
        src="header"
        :text="`HELLO, ${customerInfo.customerName}`"
        buttonText="GO TO MY ACCOUNT"
        :background-color="backgroundColour"
        :href="{ name: 'MyAccount' }"
      />
    </div>
    <div class="child-3" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HomePageBoxItem from '../HomePageBoxItem.vue';

export default {
  components: {
    HomePageBoxItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('customerInfo', ['customerInfo']),
    backgroundColour() {
      return process.env.VUE_APP_PRIMARY_COLOUR;
    },
  },
  methods: {
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
  },
  async created() {
    await this.LOAD_customerInfo();
  },
};
</script>
<style scoped lang="scss">
.top-section {
  position: relative;
  height: 298px;
}
.top-section > div {
  position: absolute;
}
.child-1 {
  width: 33vw;
  margin-left: -33vw;
  left: 0;
  height: 298px;
  background-color: var(--v-primary-base);
  margin-top: -12px;
}
.child-2 {
  width: 100%;
}
.child-3 {
  width: 33vw;
  margin-right: -33vw;
  right: 0;
  height: 298px;
  background-color: var(--v-primary-base);
  margin-top: -12px;
}
</style>
