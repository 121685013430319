<template>
  <div class="custom-card rounded-10 rounded-lg text-center"
    :class="{ 'elevation-3': $vuetify.breakpoint.mdAndUp }">
    <v-row no-gutters>
      <div
        v-for="(item, index) in money" :key="item.id"
        :class="itemClass(index)">
        <h3>{{item.text}}</h3>
        <h3>{{item.price}}</h3>
      </div>
      <!-- <v-col cols="8" class="custom-card-body rounded-r-lg">
        {{ value }}
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: 'Heading',
    },
    value: {
      type: String,
      default: 'Value',
    },
    money: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemClass(index) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return `col-12 custom-card-item custom-card-item-${index}`;
      }
      let result = `custom5cols custom-card-item custom-card-item-${index}`;
      // eslint-disable-next-line default-case
      switch (index) {
      case 0:
        result += ' rounded-l-lg';
        break;
      case 4:
        result += ' rounded-r-lg';
        break;
      }
      return result;
    },
  },
};
</script>
<style>
.custom5cols {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
.custom-card-item {
  /* background-color: white; */
  padding: 16px !important;
  /* margin-right: -1px; */
}
.custom-card-item-0 {
  color: #fff;
  background-color: #f79091;
}
.custom-card-item-1 {
  background-color: #fff;
}
.custom-card-item-2 {
  color: #fff;
  background-color: #acbcfd;
}
.custom-card-item-3 {
  background-color: #fff;
}
.custom-card-item-4 {
  color: #fff;
  background-color: #66cd74;
}
</style>
