<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            data-cy="FormBrand-name"
            label="Name"
            v-model="localBrand.name"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            block
            data-cy="FormBrand-imageUrl"
            label="Image URL"
            v-model="localBrand.imageUrl"
          />
          <UploadFile
            class="mt-2"
            accept="image/*"
            label="Upload new brand image"
            @url="updateImage"
            >
            <v-icon>mdi-pencil</v-icon>
            Upload new image
          </UploadFile>
        </v-col>
        <v-col cols="12">
          <img :src="localBrand.imageUrl" class="w-100" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            data-cy="FormBrand-sortOrder"
            label="Order"
            type="number"
            v-model.number="localBrand.sortOrder"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn color="default" outlined class="ml-4 btn-cancel" @click="$emit('cancel')"
          data-cy="FormBrand-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn color="primary" type="submit" class="ml-4"
          data-cy="FormBrand-button-save">
            {{ $t('common.buttons.save') }}
          </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import UploadFile from './UploadFile.vue';

export default {
  name: 'FormBrand',
  components: {
    UploadFile,
  },
  props: {
    brand: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      /**
      * Local value used for property localBrand
      */
      localBrand: {},
    };
  },
  watch: {
    brand() {
      this.localBrand = this.brand;
    },
  },
  mounted() {
    this.localBrand = this.brand;
  },
  methods: {
    updateImage(url) {
      this.localBrand.imageUrl = url;
      this.$forceUpdate();
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localBrand);
      }
    },
  },
};
</script>
