import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('app', ['isInRole']),
    canEdit() {
      return this.isInRole('Content Administrators');
    },
  },
  data() {
    return {
      mode: 'view',
    };
  },
};
