import Cookies from 'js-cookie';

export default {
  SET_error(state, value) {
    state.error = value;
  },
  SET_loading(state, value) {
    state.loading = value;
  },
  SET_token(state, value) {
    state.token = value;
    Cookies.set('token', value, '7d');
  },
  SET_username(state, value) {
    state.username = value;
    Cookies.set('username', value, '7d');
  },
  SET_roles(state, value) {
    if (value) {
      state.roles = value?.split(',');
    } else {
      state.roles = [];
    }
  },
};
