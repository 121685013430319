import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('customerProduct', 'customerProducts');

const customActions = {
  async UPLOAD_customerProducts({ rootGetters }, fileId) {
    const data = await odata.o(rootGetters)
      .post('uploadCustomerProducts', { fileId })
      .query();
    return data;
  },
};

export default { ...actions, ...customActions };
