<template>
  <v-progress-linear color="primary" :value="progressValue" />
</template>

<script>
export default {
  name: 'ProgressBarTimeout',
  props: {
    timeout: {
      type: Number,
      default: 20000,
    },
  },
  data: () => ({
    progressValue: 0,
    steps: 1,
    interval: null,
  }),
  mounted() {
    this.startProgress();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    startProgress() {
      if (this.timeout < 1) {
        return;
      }

      clearInterval(this.interval);

      this.interval = setInterval(() => {
        if (this.steps === this.timeout) {
          clearInterval(this.interval);
          return;
        }

        this.steps += 100;
        this.progressValue = Math.round((this.steps / this.timeout) * 100);
      }, 100);
    },
  },
};
</script>
