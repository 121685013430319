<template>
  <v-data-table
    :headers="headers"
    :items="superDeals"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        height="80"
      >
        <v-btn
          color="success"
          data-cy="SuperDeal-button-new"
          :to="{
            name: 'products-EditSuperDeal'
          }"
        >
          <v-icon class="mr-2">
            mdi-plus
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
        <v-btn class="ml-2" color="error" @click="deleteAll()">
          Delete All
        </v-btn>
        <v-spacer></v-spacer>
        <upload-file @id="fileUploaded" />
      </v-toolbar>
      <v-row>
        <v-col cols="12" class="ml-5" v-html="uploadResult" />
      </v-row>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'products-EditSuperDeal',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import UploadFile from '../components/UploadFile.vue';

export default {
  name: 'ListSuperDeal',
  module: 'SuperDeal',
  components: {
    TableBasicButtons,
    UploadFile,
  },
  mixins: [loading],
  data() {
    return {
      uploadResult: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('superDeals', ['superDeals']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Stock Code',
          value: 'stockCode',
        },
        {
          text: 'Industry',
          value: 'industry',
        },
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Product Group',
          value: 'productGroup',
        },
        {
          text: 'Product Brand',
          value: 'brand',
        },
        {
          text: 'Customer Account',
          value: 'customerAccount',
        },
        {
          text: 'Discount Percentage',
          value: 'discountPercentage',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_superDeals();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('superDeals', ['LOAD_superDeals', 'DELETE_superDeal', 'UPLOAD_superDeal']),
    /** Delete SuperDeal from the collection
     */
    async remove(SuperDeal) {
      this.isLoading = true;
      await this.DELETE_superDeal(SuperDeal.id);
      this.isLoading = false;
    },
    async fileUploaded(id) {
      this.uploadResult = '';
      await this.$nextTick();
      this.isLoading = true;
      this.uploadResult = await this.UPLOAD_superDeal(id);
      await this.$nextTick();
      await this.LOAD_superDeals();
      this.isLoading = false;
    },
    async deleteAll() {
      const res = await this.$confirm('Are you sure you want to delete all the super deals?', { title: 'Confirm' });
      if (res) {
        this.isLoading = true;
        await this.$http.post('api/admin/DeleteAllSuperDeals');
        await this.LOAD_superDeals();
        this.isLoading = false;
      }
    },
  },
};
</script>

<docs>
# SuperDeal example

'''js
<SuperDeal />
'''
</docs>
