<template>
  <v-data-table
    :loading="isSaving"
    :items="items"
    item-key="id"
    :headers="headers"
    :search="searchQuery.search"
    :items-per-page.sync="searchQuery.itemsPerPage"
    :sort-by="searchQuery.sortBy"
    :sort-desc.sync="searchQuery.sortDesc"
    :server-items-length="serverTotal"
    :page.sync="searchQuery.page"
    :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }">
    <template v-slot:top>
      <v-row no-gutters>
        <v-col offset="6" cols="6">
          <v-text-field
            v-model="searchQuery.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.productImageUrl="{ item }">
      <a :href="item.productImageUrl" target="_blank" nofollow>
        <v-img contain width="64" :src="item.productImageUrl" />
      </a>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-checkbox v-model="item.isActive" @change="$emit('change', item)"></v-checkbox>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'VermontProductList',
  data() {
    return {
      headers: [
        { text: 'StockCode', value: 'model' },
        { text: 'Product Image', value: 'productImageUrl' },
        { text: 'Barcode', value: 'barcode' },
        { text: 'Availability', value: 'availability' },
        { text: 'Brand', value: 'manufacturer' },
        { text: 'name', value: 'name' },
        { text: 'price', value: 'price' },
        { text: 'Product Group', value: 'category' },
        { text: 'Show Online', value: 'isActive' },
      ],
      searchQuery: {
        itemsPerPage: 50,
        page: 1,
        search: '',
        sortDesc: false,
        sortBy: 'model',
      },
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    serverTotal: {
      type: Number,
      default: 0,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    searchQuery: {
      immediate: true,
      deep: true,
      handler() {
        const { searchQuery } = this;
        this.$emit('query', searchQuery);
      },
    },
  },
  methods: {
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
  },
};
</script>
