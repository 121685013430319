import imgCurrentDeals from '../assets/images/LW_CurrentDeals.png';
import imgSafety2 from '../assets/images/LW_Safety2.png';
import imgPackaging from '../assets/images/LW_Packaging.png';
import imgHygiene from '../assets/images/LW_Hygiene.png';

export default {
  props: {
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Show Products',
    },
    href: {
      type: Object,
      default: { path: '/auth/my-account' },
    },
    src: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      switch (this.$vuetify.breakpoint.name) {
      case 'md':
      case 'xs':
      case 'sm':
        return '';
      default:
        return { backgroundImage: `url(${this.imageSource})` };
      }
    },
    imageSource() {
      // eslint-disable-next-line default-case
      switch (this.src) {
      case 'LW_CurrentDeals.png':
        return imgCurrentDeals;
      case 'LW_Safety2.png':
        return imgSafety2;
      case 'LW_Packaging.png':
        return imgPackaging;
      case 'LW_Hygiene.png':
        return imgHygiene;
      case 'header':
        return process.env.VUE_APP_HomeBanner;
      }
      return '';
    },
  },
};
