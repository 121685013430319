<template>
    <v-card ref="card" :elevation="elev" class="item-card my-5 p-10">
        <v-card-title class="mb-0">
          <h3 class="text-center">Shopping Cart</h3>
        </v-card-title>
        <v-card-text v-if="$vuetify.breakpoint.lgAndUp">
          <v-simple-table class="mb-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    <h3>Description</h3>
                  </th>
                  <th class="stock-code-col">
                    <h3>Code</h3>
                  </th>
                  <th class="qty-col">
                    <h3>Quantity</h3>
                  </th>
                  <th class="qty-col">
                    <h3>Available</h3>
                  </th>
                  <th>
                    <h3>Unit Price excl VAT</h3>
                  </th>
                  <th class="price-col">
                    <h3>Discount</h3>
                  </th>
                  <th class="price-col">
                    <h3>Subtotal</h3>
                  </th>
                  <th class="price-col">
                    <h3>VAT</h3>
                  </th>
                  <th class="price-col">
                    <h3>Total</h3>
                  </th>
                  <th class="action-col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cart in displayCart" :key="cart.id">
                  <td>
                    {{ cart.product.description }}
                  </td>
                  <td class="stock-code-col">
                    {{cart.product.stockCode}}
                  </td>
                  <td class="qty-col">
                    <v-text-field
                      outlined
                      filled
                      v-model="cart.amount"
                      type="number"
                      class="field pt-5"
                      :rules="[rules.non_zero]"
                      append-icon="mdi-plus"
                      @click:append="increment_amount(cart.product.stockCode)"
                      :prepend-inner-icon="getDecreaseIcon(cart.amount)"
                      @click:prepend-inner="decrement_amount(cart.product.stockCode)">
                    </v-text-field>
                  </td>
                  <td v-if="cart.product.available > 0" class="stock-code-col">
                    {{cart.product.available}}
                  </td>
                  <td v-else class="stock-code-col" style="color: #FF4500;">
                    Backordered
                  </td>
                  <td class="price-col">
                    R {{formatCurrency(cart.product.price)}}
                  </td>
                  <td class="price-col">
                    {{ cart.product.discountPercentage }}%
                  </td>
                  <td class="price-col">
                      R {{formatCurrency(cart.subtotal)}}
                  </td>
                  <td class="price-col">
                    R {{formatCurrency(cart.vat)}}
                  </td>
                  <td class="price-col">
                    R {{formatCurrency(cart.total)}}
                  </td>
                  <td class="action-col text-right">
                    <v-btn color="error" icon @click="remove(cart.product.stockCode)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="cart.length > 0">
                  <td colspan="6"></td>
                  <td colspan="3" class="text-right">
                    <h2 class="mt-9">
                      <span>Total:</span>
                      R {{ formatCurrency(total_amount) }}
                    </h2>
                  </td>
                </tr>
                <tr v-if="cart.length > 0">
                  <td colspan="2">
                    <v-btn @click="RESET_CART">Clear</v-btn>
                  </td>
                  <td colspan="2">
                  </td>
                  <td colspan="2">
                    <v-btn
                      v-if="isLoggedIn && !isPublic"
                      :loading="savingCart"
                      color="primary"
                      @click="saveCart"
                    >
                      Save Cart
                    </v-btn>
                  </td>
                  <td colspan="3" class="text-right">
                    <CheckoutDialog v-if="isLoggedIn" />
                    <v-btn
                      v-else
                      data-cy="register-for-checkout"
                      color="primary"
                      :to="{ name: 'auth-Register' }"
                    >
                      Register to Checkout
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <p v-if="cart.length <= 0" class="ma-5">
            <v-icon small class="primary--text mr-2 mb-1">
              fa-info
            </v-icon>
            No item in the cart! Please add items to the cart.
          </p>
        </v-card-text>
        <v-card-text v-else>
          <h3 v-if="cart.length > 0" class="mt-0 text-right" style="display: block">
            <span>Subtotal:</span>
            R {{ formatCurrency(total_amount) }}
          </h3>
          <h3 v-if="cart.length > 0" class="mt-0 mb-2 text-right" style="display: block">
            <span>VAT:</span>
            R {{ formatCurrency(totalVat) }}
          </h3>
          <h1 v-if="cart.length > 0" class="mb-5 text-right">
            <span>Total:</span>
            R {{ formatCurrency(total) }}
          </h1>
          <CheckoutDialog v-if="cart.length > 0" :block="true" />
          <v-btn
            v-if="cart.length > 0 && isLoggedIn"
            :loading="savingCart"
            block
            class="mt-2 rounded-lg"
            color="primary"
            @click="saveCart">
            Save Cart
          </v-btn>
          <v-row class="mt-5" v-if="displayCart.length > 0">
            <v-col
              cols="12" sm="6" md="6" lg="4" xl="4"
              v-for="(cartItem, index) in displayCart"
              :key="index"
            >
              <CartItem
                :amount="cartItem.amount"
                :item="cartItem.product"
                @remove="remove(cartItem.product.stockCode)"
              />
            </v-col>
          </v-row>
          <v-row v-else style="min-height: 1000px">
            <v-col cols="12">
              Your is empty.
              <v-btn class="mt-10" block color="success"
              to="/products/product-list"
              >Go Shopping</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </template>

<script>
import { mapGetters } from 'vuex';
import CheckoutDialog from './CheckoutDialog.vue';
import CartItem from './CartItem.vue';
import cartMixin from './cartMixin';

export default {
  components: {
    CheckoutDialog,
    CartItem,
  },
  mixins: [cartMixin],
  computed: {
    ...mapGetters('app', ['isLoggedIn', 'isPublic']),
    fixedCheckout() {
      return false;
    },
    totalVat() {
      return Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.vat))), 0));
    },
    total() {
      return Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.total))), 0));
    },
  },
  name: 'Cart',
  created() {
    window.addEventListener('scroll', this.handleScroll);
    console.debug(this.$store);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      console.debug(this.$refs.card, this.$refs.card.$el.scrollHeight, event);
      // Any code to be executed when the window is scrolled
    },
  },
};
</script>
  <style>
  div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #666;
    padding: 40px;
    font-size: 25px;
  }
  </style>

  <style scoped>
  .price-col {
    width: 100px;
  }
  .action-col {
    width: 50px;
  }
  .qty-col {
    width: 150px;
  }
  .stock-code-col {
    width: 150px;
  }
  .container {
    position: relative;
  }
  .sticky-div {
      position: sticky;
      top: 0;
  }
  </style>
