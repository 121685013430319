<template>
  <v-card :loading="isLoading">
    <v-card-title primary-title>
      <v-btn
          color="success"
          class="mr-2"
          @click="dialog = true"
        >
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
        <v-btn class="mr-2" color="error" @click="deleteAll()">
          Delete All
        </v-btn>
        Customer Products
        <v-spacer></v-spacer>
        <upload-file @id="fileUploaded" />
        <ExportToExcelButton
          :data="exportData"
          name="Customer Products"
        />
    </v-card-title>
    <v-card-text v-if="!isLoading">
      <CustomerProductList :items="customerProducts"
        @edit="edit"
        @remove="remove" />
      <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Customer Product
        </v-card-title>

        <v-card-text>
          <FormCustomerProduct :CustomerProduct="editCustomerProduct"
            @cancel="dialog = false"
            @save="save"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCustomerProduct from '../components/FormCustomerProduct.vue';
import CustomerProductList from '../components/CustomerProductList.vue';
import ExportToExcelButton from '../components/ExportToExcelButton.vue';
import UploadFile from '../components/UploadFile.vue';

export default {
  name: 'CustomerProduct',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    CustomerProductList,
    ExportToExcelButton,
    FormCustomerProduct,
    UploadFile,
  },
  data() {
    return {
      dialog: false,
      editCustomerProduct: {},
    };
  },
  computed: {
    ...mapState('customerProducts', ['customerProducts']),
    exportData() {
      return this.customerProducts.map((c) => ({
        'Customer Account': c.customerAccount,
        'Stock Code': c.stockCode,
      }));
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('customers', ['LOAD_customers']),
    ...mapActions('customerProducts', [
      'LOAD_customerProducts',
      'DELETE_customerProduct',
      'CREATE_customerProduct',
      'UPDATE_customerProduct',
      'UPLOAD_customerProducts',
    ]),
    edit(item) {
      this.editCustomerProduct = item;
      this.dialog = true;
    },
    async fileUploaded(id) {
      console.debug(id);
      this.uploadResult = '';
      await this.$nextTick();
      this.isLoading = true;
      this.uploadResult = await this.UPLOAD_customerProducts(id);
      await this.$nextTick();
      this.isLoading = false;
      await this.$nextTick();
      await this.refresh();
    },
    remove(item) {
      this.isLoading = true;
      this.DELETE_customerProduct(item.id);
      this.isLoading = false;
    },
    async save(item) {
      this.isLoading = true;
      this.dialog = false;
      if (item.id) {
        await this.UPDATE_customerProduct(item);
      } else {
        await this.CREATE_customerProduct(item);
      }
      this.isLoading = false;
      await this.refresh();
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_customerProducts();
        })(),
        (async () => {
          await this.LOAD_customers();
        })(),
      ]);
      this.isLoading = false;
    },
    async deleteAll() {
      const res = await this.$confirm('Are you sure you want to delete all the customer products?', { title: 'Confirm' });
      if (res) {
        this.isLoading = true;
        await this.$http.post('api/admin/DeleteAllCustomerProducts');
        await this.LOAD_customerProducts();
        this.isLoading = false;
      }
    },
  },
};
</script>
