<template>
  <router-link class="image-container" :to="to">
    <img :src="src" alt="Overlayed Image" class="overlay-image"/>
    <div
      class="overlay-text d-flex align-center w-full justify-space-between pa-5"
      style="text-decoration: none;"
      :style="{ background: backgroundColor }"
    >
      <span>
        {{ text }}
      </span>
      <img src="../assets/right-arrow.png" alt="Arrow Right" height="32" width="32" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ImageLink',
  props: {
    backgroundColor: {
      type: String,
      default: '#86C0AF',
    },
    src: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
  border-radius: 10px; /* Add rounded corners */
}

.overlay-image {
  display: block;
  width: 100%; /* Adjust based on your needs */
  height: auto;
}

.overlay-text {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
}
</style>
