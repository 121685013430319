<template>
  <v-row class="mt-3" justify="center" no-gutters>
    <v-col lg="6">
      <v-card flat>
        <v-card-title class="text-center justify-center py-">
          <h1 class="font-weight-bold title mb-3 text-capitalize">
            Login
          </h1>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Username"
            outlined
            prepend-inner-icon="mdi-account"
            v-model="form.email"
            :rules="rules.required"
            data-cy="login-email"
          ></v-text-field>
          <v-text-field
            label="Password"
            outlined
            :rules="rules.password"
            v-model="form.password"
            type="password"
            prepend-inner-icon="mdi-lock"
            data-cy="login-password"
            class="mb-0"
          ></v-text-field>
          <v-checkbox label="Remember me" class="mt-0 c-remembet-me"></v-checkbox>
          <v-alert type="error" v-for="error in errors" :key="error">
            {{ error }}
          </v-alert>
          <v-btn
            height="50"
            class="primary mb-2 rounded-lg text-capitalize d-block"
            style="width: 100%"
            data-cy="login-buttonLogin"
            :disabled="isLoading"
            @click="login">
              Login
          </v-btn>
          <ProgressBarTimeout v-if="isLoading" :timeout="20000" />
          <!-- <h4><span>Or</span></h4>
          <v-container>
              <v-row class="mt-0" justify="center">
                <v-btn text class="title" to="/auth/register">
                  Apply for an Account
                </v-btn>
              </v-row>
          </v-container> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import authMixins from '../mixins/auth-mixins';
import ProgressBarTimeout from '../../../../components/ProgressBarTimeout.vue';

export default {
  name: 'FormLogin',
  components: {
    ProgressBarTimeout,
  },
  mixins: [authMixins, loading],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
      },
      tab: null,
    };
  },
  methods: {
    async login() {
      try {
        this.SET_error('');
        this.isLoading = true;
        const result = await this.$auth.login(this.form.email, this.form.password);
        this.SET_token(result.data.access_token);
        this.SET_username(result.data.userName);
        this.SET_roles(result.data.roles ?? '');
        this.$emit('login', {
          email: this.form.email,
          password: this.form.password,
          result: result.data,
        });
      } catch (error) {
        if (error.response) {
          this.SET_error(error.response.data.error_description);
          this.errors = [error.response.data.error_description];
        } else {
          this.errors = ['There was an error communicating with the server, please refresh your browser and try again. Or try again later.'];
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
