<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-left">
        <div class="text-h5">
          Sales Rep
        </div>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormSalesRep-Code"
            label="Code"
            v-model="localSalesRep.code"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormSalesRep-name"
            label="Name"
            v-model="localSalesRep.name"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
            filled
            data-cy="FormSalesRep-email"
            label="Email"
            v-model="localSalesRep.email"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-btn color="default" outlined class="btn-cancel" @click="cancel"
            data-cy="FormSalesRep-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormSalesRep-button-save">
              Save
            </v-btn>
        </v-row>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormSalesRep',
  module: 'SalesRep',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    SalesRep: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      blackBorder: { border: 'black 3px solid' },
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSalesRep
      */
      localSalesRep: {},
    };
  },
  watch: {
    SalesRep() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    /**
     * Executes load
     */
    load() {
      this.localSalesRep = this.SalesRep;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSalesRep);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormSalesRep example

'''js
<FormSalesRep :SalesRep="SalesRep" />
'''
</docs>
