<template>
  <v-data-table
    :headers="headers"
    :items="pages"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">New Page</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="newPage.title"
                label="Title"
                required
              ></v-text-field>
              <v-text-field
                v-model="newPage.slug"
                label="Slug"
                hint="This will be used in the URL (i.e. frequently-asked-questions)"
                required
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="createPage">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.slug="{ item }">
      {{ item.slug }}
      <router-link :to="{ name: 'DisplayPage', params: { slug: item.slug }}">
        <v-icon>mdi-link</v-icon>
      </router-link>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn :to="
        { name: 'admin-EditPage',
        query: { id: item.id }
        }">Edit</v-btn>
      <v-btn color="error" @click="remove(item)">Delete</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListPages',
  module: 'Pages',
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      /**
      * Dialog
      */
      dialog: false,
      dialogTitle: 'New Page',
      newPage: {
        title: '',
        slug: '',
      },
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('pages', ['pages']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'title',
          value: 'title',
        },
        {
          text: 'slug',
          value: 'slug',
        },
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_pages();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('pages', ['LOAD_pages', 'DELETE_page', 'CREATE_page', 'DELETE_page']),
    /** Delete CreditDocument from the collection
     */
    async remove(page) {
      const result = await this.$confirm(`Are you sure you want to delete ${page.name}?`);
      if (result !== true) {
        return;
      }
      this.isLoading = true;
      await this.DELETE_page(page.id);
      this.isLoading = false;
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async createPage() {
      this.isLoading = true;
      const { newPage } = this;
      await this.CREATE_page(newPage);
      this.isLoading = false;
      this.dialog = false;
      this.newPage = {
        title: '',
        slug: '',
      };
    },
  },
};
</script>

<docs>
# CreditDocument example

'''js
<CreditDocument />
'''
</docs>
