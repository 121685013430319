<template>
  <v-menu
    bottom
    offset-y
    :close-on-click="false"
    :max-height="500"
    min-width="80vw"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="grey--text"
        data-cy="buttonCart"
        v-bind="attrs"
        v-on="on">
        <v-badge
          color="primary"
          left
          transition="slide-x-transition"
        >
          <template v-slot:badge>
            {{ total_items }}
          </template>
          <v-icon color="black" class="mr-1" size="26"> mdi-cart-outline </v-icon>
          R {{ formatCurrency(total_amount) }}
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-card-actions style="float: right">
        <v-btn x-small text icon color="primary">
          <v-icon color="black">mdi-close-circle</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-simple-table v-if="cart.length > 0" class="mb-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  <h3>Description</h3>
                </th>
                <th>
                  <h3>Quantity</h3>
                </th>
                <th>
                  <h3>Unit Price</h3>
                </th>
                <th>
                  <h3>Discount</h3>
                </th>
                <th>
                  <h3>Sub Total</h3>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cart in displayCart" :key="cart.id">
                <td><h4>{{ cart.product.description }}</h4></td>
                <td>
                  <h4>{{ cart.amount }}</h4>
                </td>
                <td class="price-col">
                    <h4>
                      R {{formatCurrency(cart.product.price)}}
                    </h4>
                </td>
                <td>
                  <h4>{{ cart.product.discountPercentage }}%</h4>
                </td>
                <td>
                    <h4 style="white-space: nowrap;">R {{formatCurrency(cart.subtotal)}}</h4>
                </td>
                <td>
                  <v-btn color="error" icon @click="remove(cart.product.stockCode)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="ma-5">
          <v-icon small class="primary--text mr-2 mb-1">
            fa-info
          </v-icon>
          No item in the cart! Please add items to the cart.
        </p>
      </v-card-text>
      <v-card-actions v-if="cart.length > 0">
        <v-btn color="default" @click="RESET_CART">Clear</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isLoggedIn"
          :loading="savingCart"
          color="primary"
          @click="saveCart"
        >Save Cart</v-btn>
        <v-btn
          data-cy="checkout"
          color="primary"
          right
          class="ml-3"
          to="/products/cart"
        >Checkout</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import cart from '../modules/products/src/components/cartMixin';

export default {
  mixins: [cart],
  computed: {
    isLoggedIn() {
      return this.$store.state.app.isLoggedIn;
    },
  },
};
</script>
