import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      errors: [],
      rules: {
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        repeat: [
          (v) => !!v || 'This field is required',
          (v) => v === this.form.password || 'Passwords do not match',
        ],
      },
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.errors = [];
      },
    },
  },
  computed: {
    ...mapState('app', ['loading']),
  },
  methods: {
    ...mapMutations('app', ['SET_error', 'SET_loading', 'SET_token', 'SET_username', 'SET_roles']),
  },
};
