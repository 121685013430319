<template>
  <div class="hidden-md-and-down">
    <v-container class="detailsBlocks d-flex">
        <v-row class="blocksWrap">
          <v-col
            class="leftBlocks"
            cols="8"
          >
            <v-row>
              <v-col cols="12">
                <HomePageBoxItem
                  src="LW_CurrentDeals.png"
                  text="Favourites"
                  buttonText="Show Products"
                  background-color="#acbdfd"
                  :href="{ name: 'products-ProductList', query: {filter: 'Favourites'} }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" style="padding-right: 0">
                <HomePageBoxItemLeft
                  src="LW_Safety2.png"
                  text="Safety"
                  buttonText="Show Products"
                  background-color="#86c0af"
                  :href="{ name: 'products-ProductList', query: {filterCategory: 'SAFE'} }"
                />
              </v-col>
              <v-col cols="6">
                <HomePageBoxItemLeft
                  src="LW_Hygiene.png"
                  text="Hygiene"
                  buttonText="Show Products"
                  background-color="#86c0af"
                  :href="{ name: 'products-ProductList', query: {filterCategory: 'HYGI'} }"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" style="margin-top: -12px;">
            <HomePageBoxItemVertical
              src="LW_Packaging.png"
              text="Packaging"
              buttonText="Show Products"
              background-color="#f79190"
              :href="{ name: 'products-ProductList', query: {filterCategory: 'PACK'} }"
            />
          </v-col>
          <!-- <v-col class="packagingBlock" cols="4" >
            <v-btn
              color="white"
              class="rounded-lg"
              elevation="4"
              tile
              x-large
              style="margin-top: 300px; margin-left: 25px;"
              :to="{ name: 'products-ProductList', query: {filterCategory: 'PACK'} }"
              >Show Products
            </v-btn>
          </v-col> -->
        </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
.detailsBlocks{
  height: 630px;
  width: 100%;
  display: grid;
  margin-top: 20px;
  padding: 12px;
  .blocksWrap{

    .packagingBlock{
      padding: 0 !important;
      background-size: 100% 100% !important;
      height: 100%;
      padding-left: 30px;
      background: url('../../assets/images/home__detailsBlocks__packaging.png');
      background-repeat: no-repeat;
    }
    .leftBlocks{
      padding: 0;
      .blocksTopWrap{
        padding:0 15px 0px 0;
        height: 50%;
        margin-left: 0px;
        margin-right: 0px;

        .dealsBlock{
          padding: 0;
          background-size: 100% 100% !important;
          background: url('../../assets/images/home__detailsBlocks__deals.png');
          background-repeat: no-repeat;
          display: flex;
          align-items: flex-end;
          padding: 40px;
          span{
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 21px;
            /* identical to box height */

            text-transform: uppercase;

            color: #3164AF;

          }
        }

      }

      .blocksBottomWrap{
        padding:15px 15px 0 0;
        height: 50%;
        .safetyBlock{
          background-size: 100% 100% !important;
          background: url('../../assets/images/home__detailsBlocks__safety.png');
          background-repeat: no-repeat;
          padding: 30px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          span{
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 21px;
            /* identical to box height */

            text-transform: uppercase;

            color: #3164AF;

          }
        }
        .hygieneBlock{
          box-sizing: border-box;
          background-size: 100% 100% !important;
          height: 100%;
          background: url('../../assets/images/home__detailsBlocks__hygiene.png');
          background-repeat: no-repeat;
          padding: 30px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          span{
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 21px;
            /* identical to box height */

            text-transform: uppercase;

            color: #3164AF;

          }
        }
      }

    }
  }

}

</style>
<script>
import HomePageBoxItem from '../HomePageBoxItem.vue';
import HomePageBoxItemLeft from '../HomePageBoxItemLeft.vue';
import HomePageBoxItemVertical from '../HomePageBoxItemVertical.vue';

export default {
  components: {
    HomePageBoxItem,
    HomePageBoxItemLeft,
    HomePageBoxItemVertical,
  },
  props: {

  },
  data() {
    return {};
  },
  computed: {

  },
  methods: {

  },
};
</script>
