import axios from 'axios';
import Actions from '../../odata/actions';

const actions = new Actions('vermontProduct', 'vermontProducts');

const customActions = {
  async LOAD_vermontProducts({ commit }, searchQuery) {
    const {
      page,
      itemsPerPage,
      search,
      sortBy,
      sortDesc,
    } = searchQuery;
    const skip = (page - 1) * itemsPerPage;
    let orderby = sortBy;
    if (sortDesc) {
      orderby = `${orderby} asc`;
    } else {
      orderby = `${orderby} desc`;
    }
    const filters = [];
    if (search) {
      filters.push(`contains(tolower(Model),tolower('${search}')) OR contains(tolower(Name),tolower('${search}'))`);
    }
    const q = {
      $orderby: orderby,
      $skip: skip,
      $top: itemsPerPage,
      $count: true,
    };
    if (filters.length > 0) {
      const $filter = filters.join(' AND ');
      q.$filter = $filter;
    }
    const arrayQuery = Object.entries(q);
    let qs = '';
    arrayQuery.forEach((arr) => {
      qs += `${arr.join('=')}&`;
    });
    const result = await axios.get(`odata/vermontProducts?${qs}`);
    commit('SET_vermontProducts', result.data.value);
    commit('SET_totalProducts', result.data['@odata.count']);
    return result.data.value;
  },
  // async UPDATE_vermontProduct({ rootGetters, state, commit }, data) {
  //   const i = odata.parseId(data.model);
  //   await odata.o(rootGetters)
  //     .patch(`vermontProducts('${i}')`, data)
  //     .query();

  //   const items = state.vermontProducts;
  //   const updatedItem = items.find((l) => l.model === i);
  //   Object.assign(updatedItem, data);

  //   commit('SET_vermontProducts', items);
  //   return data;
  // },
};

export default { ...actions, ...customActions };
