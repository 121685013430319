<template>
  <v-container>
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col lg="8">
        <v-card flat class="rounded-0 ma-3 pa-4">
          <FormRegister @register="onRegister" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import FormRegister from '../components/FormRegister.vue';

export default {
  name: 'Register',
  components: {
    FormRegister,
  },
  methods: {
    ...mapMutations('app', ['SET_token', 'SET_username']),
    async onRegister(registerData) {
      const result = await this.$auth.login(registerData.email, registerData.password);
      this.SET_token(result.data.access_token);
      this.SET_username(result.data.userName);
      this.$root.$emit('auth:login', result.data);
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h4 span {
  background: #fff;
  padding: 0 10px;
}
</style>
