import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultsDeep } from 'lodash';
import localLocales from './locales';

const messages = defaultsDeep(localLocales);

Vue.use(VueI18n);
console.log(messages);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});
