<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-left">
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormSignature-name"
              label="Name"
              v-model="localSignature.name"
            />
          </v-col>
          <v-col cols="4" v-if="showPosition">
            <v-text-field
              filled
              data-cy="FormSignature-position"
              label="Position"
              v-model="localSignature.position"
            />
          </v-col>
          <v-col cols="4" v-if="showIdNumber">
            <v-text-field
              filled
              data-cy="FormSignature-idNumber"
              label="ID Number"
              v-model="localSignature.idNumber"
            />
          </v-col>
          <v-col cols="4" v-if="showDate">
            <DatePicker
              filled
              data-cy="FormSignature-date"
              label="Date"
              v-model="localSignature.date"
            />
          </v-col>
        </v-row>
        <VueSignaturePad width="100%" height="150px" ref="signaturePadSigned"
          :options="{ onEnd }"
          :customStyle="blackBorder" />
        <!-- <v-text-field
          filled
          data-cy="FormSignature-signature"
          :label="$$t('signature')"
          v-model="localSignature.signature"
        /> -->
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePicker from './DatePicker.vue';

export default {
  name: 'FormSignature',
  module: 'Documents',
  components: {
    DatePicker,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Signature: {
      type: Object,
      default: undefined,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showPosition: {
      type: Boolean,
      default: true,
    },
    showIdNumber: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'signature',
    },
  },
  data() {
    return {
      blackBorder: { border: 'black 3px solid' },
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSignature
      */
      localSignature: {
      },
    };
  },
  watch: {
    localSignature: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localSignature);
      },
    },
    Signature() {
      this.localSignature = this.Signature;
    },
  },
  created() {
    this.localSignature = this.Signature;
  },
  methods: {
    onEnd() {
      // this.$refs.signaturePadSigned.fromDataURL(this.localSignature.signature);

      const { isEmpty, data } = this.$refs.signaturePadSigned.saveSignature();
      console.log(isEmpty);
      console.log(data);
      this.localSignature.signatureImage = data;
      this.$emit('input', this.localSignature);
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSignature);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormSignature example

'''js
<FormSignature :Signature="Signature" />
'''
</docs>
