<template>
  <div>
    <v-row class="mt-0" v-if="customerInfo && $vuetify.breakpoint.mdAndUp">
        <v-col cols="12" md="6" lg="6" xl="6">
          <CustomerCard heading="Customer Code" :value="customerInfo.customerAccount" />
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <CustomerCard heading="Customer Name" :value="customerInfo.customerName" />
        </v-col>
        <v-col cols="12">
          <CustomerMoneyCard :money="customerMoney" />
        </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-card :elevation="elev" flat class="rounded-0" :loading="isLoading">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            grow
            class="hidden-md-and-down"
          >
            <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :data-cy="`MyAccount-tab-${tab.key}`"
                class="text-capitalize title">
            <span class="tab-title">{{tab.text}}</span>
            </v-tab>
          </v-tabs>
          <v-select
            :items="tabs"
            v-model="tab"
            item-text="text"
            item-value="index"
            class="hidden-lg-and-up"
            outlined
          ></v-select>
            <v-tabs-items v-model="tab" id="tab-items">
                <v-tab-item key="address">
                    <BillingAddress />
                </v-tab-item>
                <v-tab-item key="contacts">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-btn color="primary" @click="addContact">New Contact</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6" xl="6"
                      v-for="contact in contacts" :key="contact.id"
                    >
                      <Contact :contact="contact" @created="contactCreated" />
                    </v-col>
                  </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item key="statements">
                  <CustomerStatement />
                </v-tab-item>
                <v-tab-item key="orders">
                  <SalesOrders :items="salesOrdersWithLineInfo" />
                </v-tab-item>
                <v-tab-item key="invoices">
                  <SalesDocuments
                    :items="getInvoices"
                    :showDownload="true"
                    type="invoice"
                    groupBy="customerOrderNo"
                    groupDesc="Customer Order No" />
                </v-tab-item>
                <v-tab-item key="creditNotes">
                    <SalesDocuments :items="getCreditNotes"
                      :showDownload="true"
                      type="creditNote" />
                </v-tab-item>
                <v-tab-item key="documents">
                    <ListCreditDocument />
                    <ListCreditApplication />
                </v-tab-item>
              <v-tab-item key="companyDetails">
                <v-row class="mt-0" v-if="customerInfo">
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <CustomerCard heading="Customer Code" :value="customerInfo.customerAccount" />
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <CustomerCard heading="Customer Name" :value="customerInfo.customerName" />
                  </v-col>
                  <v-col cols="12">
                    <CustomerMoneyCard :money="customerMoney" />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import BillingAddress from '../modules/auth/src/components/BillingAddress.vue';
import Contact from '../modules/auth/src/components/Contact.vue';
import CustomerCard from '../modules/auth/src/components/CustomerCard.vue';
import CustomerMoneyCard from '../modules/auth/src/components/CustomerMoneyCard.vue';
import CustomerStatement from '../modules/auth/src/components/CustomerStatement.vue';
import SalesDocuments from '../modules/auth/src/components/SalesDocuments.vue';
import SalesOrders from '../modules/auth/src/components/SalesOrders.vue';
import ListCreditDocument from '../components/ListCreditDocument.vue';
import ListCreditApplication from '../modules/documents/src/components/ListCreditApplication.vue';

export default {
  name: 'MyAccount',
  components: {
    BillingAddress,
    Contact,
    CustomerCard,
    CustomerMoneyCard,
    CustomerStatement,
    SalesDocuments,
    SalesOrders,
    ListCreditDocument,
    ListCreditApplication,
  },
  data() {
    return {
      isLoading: false,
      tab: 0,
    };
  },
  computed: {
    ...mapState('customerInfo', ['customerInfo']),
    ...mapState('customerTransactions', ['customerTransactions']),
    ...mapState('contacts', ['contacts']),
    ...mapState('salesDocuments', ['salesDocuments']),
    ...mapState('salesOrders', ['salesOrders']),
    ...mapGetters('salesDocuments', ['getCreditNotes', 'getInvoices']),
    ...mapGetters('salesOrders', ['salesOrdersWithLineInfo']),
    tabs() {
      const result = [
        { text: 'Address', key: 'address', index: 0 },
        { text: 'Contacts', key: 'contacts', index: 1 },
        { text: 'Statement', key: 'statements', index: 2 },
        { text: 'Orders', key: 'orders', index: 3 },
        { text: 'Invoices', key: 'invoices', index: 4 },
        { text: 'Credit Notes', key: 'creditNotes', index: 5 },
        { text: 'Documents', key: 'documents', index: 6 },
      ];
      if (this.$vuetify.breakpoint.mdAndDown) {
        result.push({
          text: 'Company Details',
          key: 'companyDetails',
          index: 7,
        });
      }
      return result;
    },
    loyaltyLeft() {
      if (!this.customerInfo) {
        return 0;
      }
      return (
        this.customerInfo?.loyaltyTarget
          - this.customerInfo?.turnoverYearToDate ?? 0
      );
    },
    customerMoney() {
      const rebate = parseFloat(this.customerInfo?.rebatePercent) ?? 0;
      const payout = this.customerInfo?.loyaltyTarget * rebate ?? 0;
      return [
        {
          price: `R ${this.formatCurrency(this.customerInfo?.creditLimit) ?? 0}`,
          text: 'Credit limit',
          id: 1,
        },
        {
          price: `R ${this.formatCurrency((parseFloat(this.customerInfo?.remainingCredit))) ?? 0}`,
          text: 'Credit Remaining',
          id: 2,
        },
        {
          price: `R ${this.formatCurrency(this.customerInfo?.loyaltyTarget) ?? 0}`,
          text: 'Loyalty Target',
          id: 3,
        },
        {
          price: `R ${this.formatCurrency(Math.round(this.loyaltyLeft)) ?? 0}`,
          text: 'Spend to Hit Target',
          id: 4,
        },
        {
          price: `R ${this.formatCurrency(Math.round(payout)) ?? 0}`,
          text: 'Payout if Target Hit',
          id: 5,
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customerInfo();
      })(),
      (async () => {
        await this.LOAD_salesDocuments();
      })(),
      (async () => {
        await this.LOAD_SalesOrdersWithLines();
      })(),
      (async () => {
        await this.LOAD_contacts();
      })(),
      (async () => {
        await this.LOAD_customerAgeingTransactions();
      })(),
    ]);
    this.isLoading = false;
    await this.$nextTick();
    if (this.$route.query.tab) {
      this.tab = this.tabs.findIndex(
        (tab) => tab.key === this.$route.query.tab,
      );
    }
  },
  methods: {
    ...mapActions('contacts', ['LOAD_contacts']),
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
    ...mapActions('customerTransactions', ['LOAD_customerTransactions']),
    ...mapActions('products', ['LOAD_productFromCode']),
    ...mapActions('salesDocuments', ['LOAD_salesDocuments']),
    ...mapActions('salesOrders', ['LOAD_SalesOrdersWithLines']),
    ...mapActions('customerAgeingTransactions', ['LOAD_customerAgeingTransactions']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    ...mapMutations('contacts', ['SET_contacts']),
    addContact() {
      const { contacts } = this;
      contacts.unshift({});
      this.SET_contacts(contacts);
    },
    contactCreated() {
      const { contacts } = this;
      const newContacts = contacts.filter((c) => c.id);
      this.SET_contacts(newContacts);
    },
  },
};
</script>

<style scoped>
.left-class {
  background-color: rgba(0, 0, 0, 0);
}
.tab-title {
  font-size: 0.73em !important;
}
.tab-wrapper {
  height:100%; /* Set to desired height of entire tabbed section, or use flex, or ... */
  display:flex;
  flex-direction: column;
}

.tab-wrapper .v-window{
  flex: 1;
}

.tab-wrapper .v-window__container,
.tab-wrapper .v-window-item  {
  height: 100%;
}

/* customise the dimensions of the card content here */
.tab-wrapper .v-card {
  height: 100%;
}
</style>
