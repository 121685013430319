<template>
  <div class="home">
    <h3>Welcome</h3>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
