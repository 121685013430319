export default {
  ADD_TO_CART: (state, data) => {
    const index = state.cart.findIndex((x) => x.product.stockCode === data.product.stockCode);
    if (index !== -1) {
      state.cart[index].amount += Number(data.amount);
    } else {
      state.cart.push(data);
    }
  },
  REMOVE_CART: (state, stockCode) => {
    state.cart = state.cart.filter((x) => x.product.stockCode !== stockCode);
  },
  RESET_CART: (state) => {
    state.cart = [];
  },
  DECREASE_AMOUNT: (state, stockCode) => {
    const cartItem = state.cart.find((x) => x.product.stockCode === stockCode);
    if (cartItem) {
      if (cartItem.amount <= 1) {
        return;
      }
      cartItem.amount -= 1;
    }
  },
  INCREASE_AMOUNT: (state, stockCode) => {
    const cartItem = state.cart.find((x) => x.product.stockCode === stockCode);
    if (cartItem) {
      cartItem.amount += 1;
    }
  },
  SET_deliveryDetails: (state, data) => {
    state.deliveryDetails = data;
  },
};
