<template>
  <v-data-table
    :loading="isSaving"
    :items="items"
    item-key="id"
    :headers="headers"
    sort-by="created"
    :sort-desc="true"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col offset="6" cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn small @click="$emit('edit', item)">Edit</v-btn>
      <v-btn small @click="remove(item)" color="error">
        Delete
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'CustomerProductList',
  data() {
    return {
      headers: [
        { text: 'Customer Account', value: 'customerAccount' },
        { text: 'Stock Code', value: 'stockCode' },
        { text: '', value: 'actions' },
      ],
      search: '',
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async remove(item) {
      const res = await this.$confirm(`Are you sure you want to delete ${item.stockCode} for ${item.customerAccount}`, { title: 'Delete' });
      if (res) {
        this.$emit('remove', item);
      }
    },
  },
};
</script>
