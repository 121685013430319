<template>
  <v-form ref="form" id="signForm" class="mt-6">
    <v-row class="mt-3" justify="center" no-gutters>
      <v-col lg="6">
        <v-card flat>
          <v-card-title class="text-center justify-center py-">
            <h1 class="font-weight-bold title mb-3 text-capitalize">
              Register Account
            </h1>
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Email"
              outlined
              v-model="form.email"
              :rules="rules.required"
              data-cy="register-email"
              prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              label="Password"
              :rules="rules.passwordRules"
              v-model="form.password"
              type="password"
              outlined
              prepend-inner-icon="mdi-lock"
              data-cy="register-password"
              class="mb-0"
            ></v-text-field>
            <!-- Repeat -->
            <v-text-field
              prepend-inner-icon="mdi-lock-outline"
              placeholder="Confirmation"
              :rules="rules.repeat"
              v-model="form.repeat"
              type="password"
              key="repeat"
              outlined
              filled
              data-cy="register-confirm-password"
            />
            <!-- Register Code -->
            <v-checkbox
              label="Existing Customer"
              v-model="existingCustomer"
            />
            <v-text-field
              label="Registration Code"
              v-if="existingCustomer"
              outlined
              v-model="form.registerCode"
              :rules="rules.required"
              prepend-inner-icon="mdi-lock"
              data-cy="register-registration-code"
            ></v-text-field>

            <v-alert type="error" v-for="error in errors" :key="error">
              {{ error }}
            </v-alert>
            <v-btn
              class="primary mb-2 rounded-0 text-capitalize block"
              style="width: 100%"
              :loading="loading"
              @click="register"
              data-cy="register-buttonRegister"
            >
              Register
            </v-btn>
            <hr class="mt-4"/>
            <!-- <v-container>
              <v-row class="mt-0" justify="center">
                <a class="title">Do you have Account?</a>
              </v-row>
            </v-container> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import authMixins from '../mixins/auth-mixins';

export default {
  name: 'Register',
  mixins: [authMixins],
  data() {
    return {
      existingCustomer: false,
      form: {
        email: '',
        password: '',
        repeat: '',
        errors: '',
      },
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
        email: [
          (v) => !!v || 'This field is required',
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        passwordRules: [
          (value) => !!value || 'Please enter a password',
          (value) => !!value || 'Please type password.',
          (value) => (value && /\d/.test(value)) || 'At least one digit',
          (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital letter',
          (value) => (value && /[a-z]{1}/.test(value)) || 'At least one letter',
          (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
          (value) => (value && value.length > 8) || 'minimum 8 characters',
        ],
        repeat: [
          (v) => !!v || 'This field is required',
          (v) => v === this.form.password || 'Passwords do not match',
        ],
      },
    };
  },
  computed: {
    ...mapState('app', ['loading']),
  },
  methods: {
    ...mapMutations('app', ['SET_error', 'SET_loading', 'SET_token', 'SET_username']),
    async register() {
      if (this.$refs.form.validate()) {
        try {
          this.SET_error('');
          this.SET_loading(true);
          if (this.existingCustomer) {
            const codeVerify = await this.$http.post('/join', {
              registerCode: this.form.registerCode,
              userName: this.form.email,
            });
            const registerCode = codeVerify.data;
            switch (registerCode) {
            case 0:
              // eslint-disable-next-line no-case-declarations
              const result = await this.$auth
                .register(this.form.email, this.form.email, this.form.password);
              this.$emit('register', {
                email: this.form.email,
                password: this.form.password,
                result: result.data,
              });
              break;
            default:
            case 1:
              this.errors.push('Invalid registration code');
              break;
            case 2:
              this.errors.push('Registration code has already been used');
              break;
            }
          } else {
            const result = await this.$auth
              .register(this.form.email, this.form.email, this.form.password);
            this.$emit('register', {
              email: this.form.email,
              password: this.form.password,
              result: result.data,
            });
          }
        } catch (error) {
          this.errors = error.response.data.modelState[''];
          this.SET_error(error.response.data.error_description);
        } finally {
          this.SET_loading(false);
        }
      }
    },
  },
};
</script>

<style scoped>
.c-forget-password {
  text-decoration: underline;
  margin-top: -10px;
}

.c-remembet-me {
  margin-top: -10px !important;
}

h4 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h4 span {
  background: #fff;
  padding: 0 10px;
}
</style>
