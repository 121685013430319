var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"text-center"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"mb-10",attrs:{"indeterminate":true}}):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.allProducts,"item-text":p => `${p.stockCode} - ${p.stockDescription}`,"item-value":"stockCode","filled":"","data-cy":"FormSuperDeal-stockCode","label":"Product","append-outer-icon":"mdi-cloud-download-outline","loading":_vm.loadingItems.includes('allProducts')},on:{"click:append-outer":_vm.loadProducts},model:{value:(_vm.localSuperDeal.stockCode),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "stockCode", $$v)},expression:"localSuperDeal.stockCode"}}),_c('v-autocomplete',{attrs:{"items":_vm.productGroups,"item-text":"value","item-value":"key","filled":"","data-cy":"FormSuperDeal-productGroup","label":"Product Group","append-outer-icon":"mdi-cloud-download-outline","loading":_vm.loadingItems.includes('productGroups')},on:{"click:append-outer":function($event){return _vm.loadNetwork({
          name: 'productGroups',
          method: _vm.LOAD_productGroups,
        })}},model:{value:(_vm.localSuperDeal.productGroup),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "productGroup", $$v)},expression:"localSuperDeal.productGroup"}}),_c('v-autocomplete',{attrs:{"items":_vm.productBrands,"item-text":"value","item-value":"key","filled":"","data-cy":"FormSuperDeal-brand","label":"Product Brand","append-outer-icon":"mdi-cloud-download-outline","loading":_vm.loadingItems.includes('productBrands')},on:{"click:append-outer":function($event){return _vm.loadNetwork({
          name: 'productBrands',
          method: _vm.LOAD_productBrands,
        })}},model:{value:(_vm.localSuperDeal.brand),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "brand", $$v)},expression:"localSuperDeal.brand"}}),_c('v-autocomplete',{attrs:{"items":_vm.sortedCustomers,"item-text":"customerName","item-value":"customerAccount","filled":"","data-cy":"FormSuperDeal-customerAccount","label":"Customer Account","append-outer-icon":"mdi-cloud-download-outline","loading":_vm.loadingItems.includes('customers')},on:{"click:append-outer":function($event){return _vm.loadNetwork({
          name: 'customers',
          method: _vm.LOAD_customers,
        })}},model:{value:(_vm.localSuperDeal.customerAccount),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "customerAccount", $$v)},expression:"localSuperDeal.customerAccount"}}),_c('v-autocomplete',{attrs:{"items":_vm.customerCategories,"filled":"","data-cy":"FormSuperDeal-category","label":"Category","append-outer-icon":"mdi-cloud-download-outline","loading":_vm.loadingItems.includes('customers')},on:{"click:append-outer":function($event){return _vm.loadNetwork({
          name: 'customers',
          method: _vm.LOAD_customers,
        })}},model:{value:(_vm.localSuperDeal.category),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "category", $$v)},expression:"localSuperDeal.category"}}),_c('v-select',{attrs:{"items":_vm.industries,"filled":"","data-cy":"FormSuperDeal-industry","label":"Industry"},model:{value:(_vm.localSuperDeal.industry),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "industry", $$v)},expression:"localSuperDeal.industry"}}),_c('v-text-field',{attrs:{"type":"number","filled":"","data-cy":"FormSuperDeal-discountPercentage","label":"Discount Percentage","width":"100"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" % ")]},proxy:true}]),model:{value:(_vm.localSuperDeal.discountPercentage),callback:function ($$v) {_vm.$set(_vm.localSuperDeal, "discountPercentage", $$v)},expression:"localSuperDeal.discountPercentage"}}),_c('div',{staticClass:"d-flex justify-end mb-6"},[_c('v-btn',{staticClass:"ml-4 btn-cancel",attrs:{"color":"default","outlined":"","data-cy":"FormSuperDeal-button-cancel"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","type":"submit","loading":_vm.isSaving,"data-cy":"FormSuperDeal-button-save"}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.save'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }