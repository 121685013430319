import { mapGetters, mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
import pkg from '../../package.json';

export default {
  data() {
    return {
      message: '',
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters('cart', ['total_amount', 'total_items']),
    ...mapGetters('app', ['isLoggedIn', 'isInRole', 'getRoles', 'isPublic']),
    ...mapState('customerInfo', ['customerInfo']),
    ...mapGetters('products', ['visibleStockCategories']),
    appBarHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 120;
      }
      return 0;
    },
    platform() {
      return Capacitor.getPlatform();
    },
    isWeb() {
      return this.platform === 'web';
    },
    isIos() {
      return this.platform === 'ios';
    },
    isAndroid() {
      return this.platform === 'android';
    },
    bannerColour() {
      return process.env.VUE_APP_MOBILE_BANNER_COLOUR;
    },
    isAdministrators() {
      return this.isInRole('Administrators');
    },
    isContentAdministrators() {
      return this.isInRole('Content Administrators');
    },
    isContained() {
      if (this.$route.name === 'Home Page') {
        return false;
      }
      return true;
    },
    logoSrc() {
      return process.env.VUE_APP_Logo;
    },
    showNumber() {
      return process.env.VUE_APP_ShowNumber === 'true';
    },
    supportEmail() {
      return process.env.VUE_APP_Support_Email;
    },
    version() {
      return pkg.version;
    },
  },
};
