<template>
  <div class="home">
    Home Page
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
