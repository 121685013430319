<template>
  <v-card>
    <v-card-title primary-title>
      <v-btn class="mr-1" color="default" icon :to="{ name: 'admin-Prices' }">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Price Details for {{ customerAccount }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="viewPrices"
        class="elevation-1"
        :loading="isLoading"
        :search="search"
        sort-by="stockCode"
      >
        <template v-slot:top>
          <v-row>
            <v-col offset="6" cols="6">
              <v-text-field
                name="Search"
                label="Search"
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.exclUnitSellingPrice="{ item }">
          {{ formatCurrency(item.exclUnitSellingPrice) }}
        </template>
        <template v-slot:item.decisions="{ item }">
          <ul>
            <li v-for="(decision, index) in item.decisions.split(';')" :key="index">
              {{ decision }}
            </li>
          </ul>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '../modules/auth/src/components/ExportToExcelButton.vue';

export default {
  name: 'PriceDetails',
  module: 'Shop',
  mixins: [loading],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ExportToExcelButton,
  },
  data() {
    return {
      dialog: false,
      viewPrices: [],
      customerAccount: this.$route.query.customerAccount,
      search: '',
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Stock Code',
          value: 'stockCode',
        },
        {
          text: 'Cost Price',
          value: 'costPrice',
        },
        {
          text: 'Excl. Unit Selling Price',
          value: 'exclUnitSellingPrice',
        },
        {
          text: 'Discount Type',
          value: 'discountType',
        },
        {
          text: 'Discount Percentage',
          value: 'discountPercentage',
        },
        {
          text: 'Decisions',
          value: 'decisions',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const response = await this.$http.get(`/api/reports/pricing/details/${this.$route.query.customerAccount}`);
      this.viewPrices = response.data;
      this.isLoading = false;
    },
  },
};
</script>
