<template>
  <v-card :loading="isLoading || isSaving">
    <v-card-title primary-title>
      <v-btn
          color="success"
          class="mr-2"
          @click="dialog = true"
          :to="{ name: 'auth-EditProductGroupDeal' }"
        >
          <v-icon>mdi-plus</v-icon> New
        </v-btn>
        Product Group Deal
    </v-card-title>
    <v-card-text v-if="!isLoading">
      <ProductGroupDealList :items="productGroupDeals" @change="save" @delete="remove" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProductGroupDealList from '../components/ProductGroupDealList.vue';

export default {
  name: 'ProductGroupDeals',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    ProductGroupDealList,
  },
  computed: {
    ...mapState('productGroupDeals', ['productGroupDeals']),
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_productGroupDeals();
      })(),
      (async () => {
        await this.LOAD_customerCategories();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('productGroupDeals', ['LOAD_productGroupDeals', 'UPDATE_productGroupDeal', 'DELETE_productGroupDeal']),
    ...mapActions('customers', ['LOAD_customerCategories']),
    async remove(item) {
      await this.DELETE_productGroupDeal(item.id);
    },
    async save(item) {
      this.isSaving = true;
      await this.UPDATE_productGroupDeals(item);
      this.isSaving = false;
    },
  },
};
</script>
