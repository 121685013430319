import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('cart', 'carts');

const customActions = {
  async LOAD_carts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('carts')
      .query({ $filter: 'IsDeleted eq false', $expand: 'Items' });
    commit('SET_carts', data);
    return data;
  },
};

export default { ...actions, ...customActions };
