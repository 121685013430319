import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('salesDocument', 'salesDocuments');

const customActions = {
  async LOAD_salesDocuments({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('salesDocuments')
      .query({ $orderby: 'documentDate DESC' });
    commit('SET_salesDocuments', data);
  },
};

export default { ...actions, ...customActions };
