<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" :overlay="false">
      <v-btn color="primary" dark slot="activator">Open Dialog</v-btn>
      <v-card>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'ModalCreditApplicationTerms',
};
</script>
