<template>
  <v-snackbar
    v-model="snackbar"
    data-cy="snackbar"
    :color="color"
    :multi-line="mode === 'multi-line'"
    :timeout="timeout"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
      </template>
    <v-divider></v-divider>
  </v-snackbar>
</template>

<script>
import { VSnackbar, VBtn } from 'vuetify/lib';

/**
 * Aurora Snackbar Notifications
 *
 */
export default {
  components: {
    VSnackbar, VBtn,
  },
  props: {
    /**
    * Set the timeout for the snackbar message. Default: 10000,
    */
    timeout: {
      type: Number,
      default: 5000,
    },
    /**
    * Set the text for the snackbar message. Default: 'Phone Number',
    */
    text: {
      type: String,
      default: '',
    },
    /**
    * Set the color for the snackbar message. Default: '' Should be 'success', 'danger', etc,
    */
    color: {
      type: String,
      default: '',
    },
    /**
    * Set the mode for the snackbar messagel. Default: '',
    */
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  watch: {
    text() {
      if (this.text?.length > 0) {
        this.notification();
      }
    },
  },
  methods: {
    /** Method used to trigger a new snackbar notification
     */
    notification() {
      this.snackbar = true;
    },
  },
};
</script>

<docs>
# PhoneField example

'''js
<Toasts :text="errors" color="danger" />
'''
</docs>
