import Vue from 'vue';
import VueRouter from 'vue-router';
import { routerHelpers } from '@codehq/aurora-app-core';
import { views as authViews } from '../modules/auth/src';
import { views as productViews } from '../modules/products/src';
import { views as documentViews } from '../modules/documents/src';
import { views as appViews } from '../index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/init',
    name: 'Init',
    component: appViews.Init,
  },
  {
    path: '/',
    name: 'Home Page',
    component: appViews.HomePage,
  },
  {
    path: '/home',
    name: 'Home',
    component: productViews.ProductHome,
  },
  {
    path: '/login',
    name: 'login',
    component: authViews.Login,
  },
  {
    path: '/application/credit',
    name: 'EditCreditDocument',
    component: appViews.EditCreditDocument,
  },
  {
    path: '/application/list',
    name: 'ListCreditDocument',
    component: appViews.ListCreditDocument,
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: appViews.MyAccount,
  },
  {
    path: '/admin/feedback-items',
    name: 'FeedbackItems',
    component: appViews.FeedbackItems,
  },
  {
    path: '/admin/products',
    name: 'admin-ProductList',
    component: productViews.AdminProductList,
  },
  {
    path: '/admin/product',
    name: 'admin-EditProduct',
    component: productViews.EditProduct,
  },
  {
    path: '/admin/sales-reps',
    name: 'admin-ListSalesRep',
    component: appViews.ListSalesRep,
  },
  {
    path: '/admin/omni-pricelists',
    name: 'admin-OmniPricelists',
    component: productViews.AdminPricelist,
  },
  {
    path: '/admin/sales-rep',
    name: 'admin-EditSalesRep',
    component: appViews.EditSalesRep,
  },
  {
    path: '/admin/tags',
    name: 'admin-Tags',
    component: appViews.Tags,
  },
  {
    path: '/admin/customer-pricing',
    name: 'admin-CustomerPricing',
    component: appViews.CustomerPricing,
  },
  {
    path: '/admin/jobs',
    name: 'admin-HangfireJobs',
    component: appViews.HangfireJobs,
  },
  {
    path: '/admin/pages',
    name: 'admin-ListPages',
    component: appViews.ListPages,
  },
  {
    path: '/admin/edit-page',
    name: 'admin-EditPage',
    component: appViews.EditPage,
  },
  {
    path: '/pages/:slug',
    name: 'DisplayPage',
    component: appViews.DisplayPage,
  },
  {
    path: '/saved-carts',
    name: 'SavedCarts',
    component: appViews.SavedCarts,
  },
  {
    path: '/admin/pricing',
    name: 'admin-Prices',
    component: appViews.Prices,
  },
  {
    path: '/admin/pricing/info',
    name: 'admin-PriceDetails',
    component: appViews.PriceDetails,
  },
  {
    path: '/payment/success',
    name: 'payment-Success',
    component: appViews.PaymentSuccess,
  },
  {
    path: '/payment/failure',
    name: 'payment-Failure',
    component: appViews.PaymentFailure,
  },
];

routes.push(...routerHelpers.mapViews('auth', authViews));
routes.push(...routerHelpers.mapViews('products', productViews));
routes.push(...routerHelpers.mapViews('documents', documentViews));

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
