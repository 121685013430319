<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-select
          :items="sortedCustomers"
          :item-text="(c) => `${c.customerAccount} - ${c.customerName}`"
          item-value="customerAccount"
          filled
          data-cy="FormCustomerProduct-customerAccount"
          label="Customer Account"
          v-model="localCustomerProduct.customerAccount"
          :rules="rules.required"
        />
        <v-select
          :items="sortedProducts"
          :item-text="(s) => `${s.stockCode} - ${s.stockDescription}`"
          item-value="stockCode"
          filled
          data-cy="FormCustomerProduct-stockCode"
          label="Stock Code"
          v-model="localCustomerProduct.stockCode"
          :rules="rules.required"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCustomerProduct-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="$parent.isSaving"
            data-cy="FormCustomerProduct-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormCustomerProduct',
  module: 'auth',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CustomerProduct: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
      },
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCustomerProduct
      */
      localCustomerProduct: {},
    };
  },
  computed: {
    ...mapGetters('customers', ['sortedCustomers']),
    ...mapGetters('products', ['sortedProducts']),
  },
  watch: {
    CustomerProduct() {
      this.localCustomerProduct = this.CustomerProduct;
    },
  },
  created() {
    this.localCustomerProduct = this.CustomerProduct;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCustomerProduct);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
