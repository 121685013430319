/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Workbox } from 'workbox-window';

console.log('instantiating service worker');

// eslint-disable-next-line import/no-mutable-exports
let wb;

if ('serviceWorker' in navigator) {
  console.log('service worker registering');
  wb = new Workbox(`${process.env.BASE_URL}sw.js`);

  wb.addEventListener('controlling', () => {
    console.log('service worker: controlling');
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
          console.log('Cache deleted');
        });
      });
    }
    window.location.reload();
  });

  wb.addEventListener('message', (event) => {
    console.log(event);
    if (event.data && event.data.type === 'SKIP_WAITING') {
      wb.skipWaiting();
    }
  });

  wb.addEventListener('waiting', (registration) => {
    console.log(registration);
    document.dispatchEvent(new CustomEvent('serviceWorkerUpdateEvent', { detail: registration }));
  });

  wb.register();
  console.log('service worker registered');
} else {
  console.log('no service worker found in navigator');
  wb = null;
}

export default wb;
