<template>
  <v-data-table
    :headers="headers"
    :items="creditDocuments"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="CreditDocument-button-new"
          @click="$router.push({
            name: 'EditCreditDocument'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          Apply
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn small :to="{
          name: 'EditCreditDocument',
          query: {id: item.id}
        }">Edit</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListCreditDocument',
  module: 'CreditDocument',
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('creditDocuments', ['creditDocuments']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Type',
          value: 'documentType',
        },
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_creditDocuments();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('creditDocuments', ['LOAD_creditDocuments', 'DELETE_creditDocument']),
    /** Delete CreditDocument from the collection
     */
    async remove(creditDocument) {
      this.isLoading = true;
      await this.DELETE_creditDocument(creditDocument.id);
      this.isLoading = false;
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
  },
};
</script>

<docs>
# CreditDocument example

'''js
<CreditDocument />
'''
</docs>
