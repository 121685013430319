<template>
  <v-card>
    <v-card-title primary-title>
      {{ title }}
      <v-btn icon @click="refresh">
        <v-icon>{{ mdiRefresh }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="isLoading"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6">
              <v-text-field
                name="Search"
                label="Search"
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.created="{ item }">
          {{ formatDateTime(item.created) }}
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn @click="run(item)" small color="default">Run Again</v-btn>
        </template> -->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'HangfireBusJobs',
  module: 'Hangfire',
  props: ['title', 'status'],
  mixins: [loading],
  data() {
    return {
      mdiRefresh,
      dialog: false,
      jobsBus: [],
      search: '',
    };
  },
  computed: {
    headers() {
      return [{
        text: this.$$t('created'),
        value: 'created',
      }, {
        text: this.$$t('name'),
        value: 'name',
      }, {
        text: this.$$t('details'),
        value: 'details',
      }, {
        text: '',
        value: 'actions',
      }, {
        text: this.$$t('id'),
        value: 'id',
      }];
    },
    items() {
      const { jobsBus } = this;
      const items = jobsBus.map((j) => {
        const { body } = j;
        const obj = JSON.parse(body);
        console.debug(obj);

        switch (j.destinationAddress) {
        case 'loopback://localhost/urn:message:GourmetFoods.Web.Models:NewOrderNotification':
          return {
            created: obj.message.created,
            id: obj.messageId,
            name: 'New Order Notification',
            details: `Order No: ${obj.message.order.OrderNo} created by ${obj.message.order.createdBy}`,
          };
        default:
          console.warn(j.destinationAddress);
          return undefined;
        }
      });
      const result = items.filter((i) => i !== undefined);
      return result;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('hangfireJobs', ['LOAD_jobsBus']),
    async refresh() {
      this.isLoading = true;
      this.jobsBus = await this.LOAD_jobsBus(this.status);
      this.isLoading = false;
    },
  },
};
</script>
