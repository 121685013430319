import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('pageItem', 'pageItems');

const customActions = {
  async LOAD_pageItemsByPageName({ commit, rootGetters }, name) {
    const data = await odata.o(rootGetters)
      .get('pageItems')
      .query({ $filter: `IsDeleted eq false AND Page/slug eq '${name}'` });
    commit('SET_pageItems', data);
    return data;
  },
};

export default { ...actions, ...customActions };
