export default {
  vermontProductList: (state) => state.vermontProducts.map((p) => ({
    available: p.availability,
    discountType: '',
    discountPercentage: 0,
    stockCode: p.model,
    stockDescription: '',
    merchandiseDescription: p.description,
    hasOptions: false,
    price: p.price,
    packSize: '',
    merchandiseImgUrl: p.productImageUrl,
  })),
};
