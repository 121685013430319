import axios from 'axios';

export default {
  // eslint-disable-next-line no-unused-vars
  async CREATE_userFile({ rootState }, file) {
    const formData = new FormData();

    formData.append('file', file);

    // eslint-disable-next-line no-unused-vars
    // const { organization } = rootState.organizations;
    const result = await axios.post('/auroraApi/Files', formData, {
      headers: {
        // OrganizationId: organization.id,
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  },
};
