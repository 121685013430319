<template>
  <v-progress-linear :indeterminate="true"></v-progress-linear>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Init',
  computed: {
    ...mapGetters('app', ['isLoggedIn']),
  },
  async mounted() {
    if (this.isLoggedIn) {
      await this.LOAD_customerInfo();
      this.$router.push({ name: 'Home' });
    } else {
      this.$router.push({ name: 'auth-Login' });
    }
  },
  methods: {
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
  },
};
</script>
