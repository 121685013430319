<template>
  <div>
    <div class="top-section hidden-sm-and-down">
      <div class="contained-width">
        <v-row dense class="section-min-height">
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="4"
            class="d-flex flex-column justify-center align-start pa-5"
          >
            <div class="text-h4 white--text mb-5">
              Supplying the world’s best industrial products for 150 years.
            </div>
            <v-btn
              class="rounded-lg"
              elevation="4"
              large
              href="/products/product-list"
            >
              Shop Now
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            offset-lg="4"
            offset-xl="4"
            md="6"
            lg="4"
            xl="4"
            class="pa-5 d-flex align-center"
          >
            <div class="d-flex justify-end align-center">
              <v-img src="../assets/banner-image-1.png" height="200" contain />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-container class="contained-width">
      <v-row>
        <v-col
          cols="6" md="6" lg="4" xl="4"
          v-for="(link, index) in links"
          :key="index"
          :data-cy="`image-link-${index}`"
        >
          <ImageLink
            :src="link.src"
            :text="link.text"
            :to="link.to"
            :backgroundColor="link.backgroundColor"
          />
        </v-col>
      </v-row>
      <logosSlider />
      <newArrivals :title="isLoggedIn ? 'Purchase Again' : 'Browsing History'" />
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageLink from '../components/ImageLink.vue';
import logosSlider from '../modules/products/src/components/mainpage/logosSlider.vue';
import newArrivals from '../modules/products/src/components/mainpage/newArrivals.vue';

export default {
  name: 'HomePage',
  components: {
    ImageLink,
    logosSlider,
    newArrivals,
  },
  computed: {
    ...mapState('app', ['isLoggedIn']),
    links() {
      return [
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-safety.png'),
          text: 'Safety Gear',
          to: { name: 'products-ProductList', query: { filterCategory: 'SAFE' } },
        },
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-packaging.png'),
          text: 'Packaging',
          to: { name: 'products-ProductList', query: { filterCategory: 'PACK' } },
        },
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-abrasives.png'),
          text: 'Abrasives',
          to: { name: 'products-ProductList', query: { filterCategory: 'ABRA' } },
        },
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-hygiene.png'),
          text: 'Hygiene',
          to: { name: 'products-ProductList', query: { filterCategory: 'HYGI' } },
        },
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-tooling.png'),
          to: { name: 'products-ProductList', query: { filterCategory: 'TOOl' } },
          text: 'Tooling',
        },
        {
          // eslint-disable-next-line global-require
          src: require('../assets/image-link-catalogues.png'),
          to: { name: 'products-ProductList', query: { filter: 'Super Deals' } },
          text: 'Promotions',
          backgroundColor: '#FAB05A',
        },
      ];
    },
  },
};
</script>
