<template>
  <v-img
    class="product-image"
    :src="cPicture"
    contain
    v-on:error="onImgError"
    :alt="item.stockDescription"
    :height="maxHeight"
    :width="maxWidth"
  />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    maxHeight: {
      type: Number,
      default: 150,
    },
    maxWidth: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      failed_image: false,
    };
  },
  computed: {
    cPicture() {
      if (this.item.merchandiseImgUrl?.length > 0) {
        return this.item.merchandiseImgUrl;
      }
      return process.env.VUE_APP_Logo;
    },
  },
  methods: {
    onImgError() {
      this.failed_image = true;
    },
  },
};
</script>
