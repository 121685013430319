<template>
  <v-card v-if="cart.length > 0">
    <v-card-title>
      <h2>Order Summary</h2>
    </v-card-title>
    <v-card-text class="d-flex d-flex flex-column justify-lg-space-between">
      <h3 class="d-flex justify-space-between mb-5 border-b-thing">
        <span>Your cart:</span>
        <span>R {{ formatCurrency(total_amount) }}</span>
      </h3>
      <h3 class="d-flex justify-space-between mb-5 border-b-thing">
        <span>Delivery:</span>
        <span>{{ deliveryAmount }}</span>
      </h3>
      <h3 class="d-flex justify-space-between mb-5">
        <span>VAT:</span>
        <span>R {{ formatCurrency(totalVat) }}</span>
      </h3>
      <h3 class="d-flex justify-space-between mb-5">
        <b>Total:</b>
        <b>R {{ formatCurrency(total) }}</b>
      </h3>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import cartMixin from './cartMixin';

export default {
  name: 'OrderSummary',
  mixins: [cartMixin],
  computed: {
    ...mapState('cart', ['deliveryDetails']),
    fixedCheckout() {
      return false;
    },
    deliveryAmount() {
      if (this.deliveryDetails.physicalAddress5 === 'Western Cape') {
        return 'R 0';
      }
      if (this.deliveryDetails.deliver === false) {
        return 'Collecting';
      }
      if (this.deliveryDetails.selectedRate) {
        return `R ${this.formatCurrency(this.deliveryDetails.selectedRate.rate)}`;
      }
      return 'TBC';
    },
    totalVat() {
      return Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.vat))), 0));
    },
    total() {
      let total = Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.total))), 0));

      if (this.deliveryDetails.selectedRate
        && this.deliveryDetails.deliver
      ) {
        total += this.deliveryDetails.selectedRate.rate;
      }
      return total;
    },
  },
};
</script>
