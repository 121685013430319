<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import CartBusiness from '../components/CartBusiness.vue';
import CartPublic from '../components/CartPublic.vue';

export default {
  name: 'Cart',
  components: {
    CartBusiness,
    CartPublic,
  },
  computed: {
    resolveLayout() {
      if (this.$store.getters['app/isPublic']) {
        return 'CartPublic';
      }
      return 'CartBusiness';
    },
  },
};
</script>
