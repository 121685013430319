<template>
  <div>
    <!-- <v-card>
      <v-card-text>
        <div class="text-h6 font-weight-bold">Aged Payables</div>
        <v-row no-gutters style="max-width: 900px">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Current
                    </th>
                    <th class="text-left">
                      30 Days
                    </th>
                    <th class="text-left">
                      60 Days
                    </th>
                    <th class="text-left">
                      90 Days
                    </th>
                    <th class="text-left">
                      120 Days+
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>R {{ formatCurrency(agedPayeables.current) }}</td>
                    <td>R {{ formatCurrency(agedPayeables._30Days) }}</td>
                    <td>R {{ formatCurrency(agedPayeables._60Days) }}</td>
                    <td>R {{ formatCurrency(agedPayeables._90Days) }}</td>
                    <td>R {{ formatCurrency(agedPayeables._120DaysAndOver) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
    <v-card class="mt-5 mb-20">
      <v-card-text>
        <v-btn
          color="primary"
          @click="download"
          :loading="isDownloading"
        >
          <v-icon class="mr-2">mdi-file-pdf-outline</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">
            Download Latest PDF Statement
          </span>
          <span v-else>
            Download Statement
          </span>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        &nbsp;
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CustomerStatement',
  data() {
    return {
      date: '2021-02-26',
      isDownloading: false,
    };
  },
  methods: {
    async download() {
      try {
        this.isDownloading = true;
        const response = await axios({
          url: 'odata/statementDownload',
          method: 'GET',
          responseType: 'blob', // important
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Statement.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$root.$emit('toast:error', 'There was an error downloading the statement, please try again later');
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
</script>
