import { odata } from '../../conf';

export default {
  async SAVE_newOrder({ commit, rootGetters }, order) {
    const data = await odata.o(rootGetters)
      .post('newOrder', order)
      .query();
    commit('SET_salesOrder', data);
  },
  async SAVE_newPublicOrder({ commit, rootGetters }, order) {
    const data = await odata.o(rootGetters)
      .post('SalesOrders/NewPublicOrder', order)
      .query();
    commit('SET_salesOrder', data);
    return data;
  },
};
