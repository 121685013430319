<template>
  <v-menu
    bottom
    offset-y
    :close-on-click="false"
    :max-height="500"
    v-if="hasCarts"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="grey--text"
        data-cy="buttonSavedCarts"
        title="Saved Carts"
        :loading="isLoading"
        v-bind="attrs"
        v-on="on">
        <v-badge
          color="primary"
          left
          transition="slide-x-transition"
        >
          <template v-slot:badge>
            {{ carts.length }}
          </template>
          <v-icon color="black" class="mr-1" size="26"> mdi-cart-plus </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-simple-table class="mb-4">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="cart in carts"
                :key="cart.id"
              >
                <td>
                  {{ cart.cartNumber }}
                </td>
                <td>
                  <v-btn color="default" class="mr-1" small @click="load(cart)">Load</v-btn>
                  <v-btn color="primary" class="mr-1" small
                    :href="`${baseUrl}/print/cart?cartId=${cart.id}`"
                  >
                    Download
                  </v-btn>
                  <v-btn color="error" small @click="remove(cart)">
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'SavedCarts',
  mixins: [loading],
  computed: {
    ...mapGetters('carts', ['hasCarts']),
    ...mapState('carts', ['carts']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
  },
  created() {
    this.$root.$on('refresh', async () => {
      await this.refresh();
    });
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('carts', ['LOAD_carts', 'UPDATE_cart']),
    ...mapActions('products', ['LOAD_productFromCode']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    /**
     * Executes load
     */
    async load(cart) {
      cart.items.forEach(async (i, index) => {
        try {
          this.isLoading = true;
          const product = await this.LOAD_productFromCode(i.stockCode.replace('.', '__'));
          product.isCustomerDeal = false;
          product.price = product?.exclUnitSellingPrice;
          product.description = product?.stockDescription;
          this.ADD_TO_CART({
            amount: i.quantity,
            product,
          });
          if (index === cart.items.length - 1) {
            await this.remove(cart);
          }
          this.isLoading = false;
        } catch (e) {
          console.error(e);
          this.$root.$emit('toast:error', 'Error adding item to your saved cart');
        }
      });
      this.$root.$emit('toast:notify', 'Loaded your saved cart');
      await this.refresh();
      this.isLoading = false;
    },
    /**
     * Executes remove
     */
    async remove(cart) {
      this.isLoading = true;
      await this.UPDATE_cart({
        id: cart.id,
        isDeleted: true,
      });
      await this.refresh();
      this.isLoading = false;
    },
    /**
     * Executes data refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_carts();
      this.isLoading = false;
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
  },
};
</script>
