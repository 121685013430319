<template>
    <v-container>
        <v-row class="mt-0" justify="center">
          <v-col cols="12">
            <h3 class="title text-capitalize font-weight-bold">Company Information</h3>
          </v-col>
        </v-row>
        <hr/>
        <v-row class="mt-0" justify="center" v-if="customerInfo">
          <v-col cols="12">
            <v-text-field
              label="Company Name"
              v-model="customerInfo.customerName"
              class="rounded-0"
              filled
              outlined
            ></v-text-field>
            <v-text-field
              label="Account code"
              v-model="customerInfo.customerAccount"
              class="rounded-0"
              filled
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-2" justify="center">
            <v-col lg="12 mt-9">
                <hr/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Account',
  computed: {
    ...mapState('customerInfo', ['customerInfo']),
  },
};
</script>

<style scoped>
.c-btn {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
