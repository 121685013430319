<template>
  <v-app :class="{ ios: isIos }">
    <div class="fill-width top-sticky">
      <div class="d-flex justify-space-between align-center bg-primary">
        <img :src="logoSrc" alt="Logo" contain height="80" />
        <div class="d-flex flex-column">
          <v-btn text :href="`mailto:${supportEmail}`" class="text-lowercase white--text">
            {{ supportEmail }}
          </v-btn>
          <v-btn class="white--text" href="tel:+27215112001" text v-if="showNumber">
            +27 (0)21 511 2001
          </v-btn>
          <div class="d-flex align-self-end mr-5 white--text" style="font-size: 8px;">
            <span>v{{ version }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-app-bar dark :color="bannerColour" height="150px">
      <router-link style="margin-left: auto; margin-right: auto; margin-top: 20px" to="/" >
        <v-img :src="logoSrc"
          contain
          background-color="primary"
          class=""
          height="120"
          />
        </router-link>
    </v-app-bar> -->
    <div class="align-start mb-5 fill-height" :class="{ 'contained-width': isContained }">
      <v-main class="pb-20">
        <router-view />
      </v-main>
    </div>
    <v-footer padless fixed>
      <v-bottom-navigation>
        <v-btn to="/">
          <span>Home</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'products-ProductList' }">
          <span>Shop</span>

          <v-icon>mdi-store</v-icon>
        </v-btn>

        <v-btn  v-if="isLoggedIn" :to="{ name: 'MyAccount' }">
          <span>My Account</span>

          <v-icon>mdi-account</v-icon>
        </v-btn>
        <MobileSavedCarts v-if="isLoggedIn && !isPublic" />

        <v-btn :to="{ name: 'products-Cart' }">
          <span>Cart</span>
          <v-badge
          color="primary"
          left
          transition="slide-x-transition"
        >
          <template v-slot:badge>
            {{ total_items }}
          </template>
          <v-icon>mdi-cart</v-icon>
        </v-badge>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
    <Footer class="hidden-md-and-down"/>
    <Toasts :text="message" color="success" :timeout="850" />
    <ErrorToast :text="errorMessage" @close="errorMessage = ''" />
  </v-app>
</template>

<script>
import { components as auroraAppCore } from '@codehq/aurora-app-core';
// import Feedback from '@codehq/aurora-app-core/src/components/Feedback.vue';
import ErrorToast from '@/components/ErrorToast.vue';
import Footer from '@/components/Footer.vue';
import MobileSavedCarts from '@/components/MobileSavedCarts.vue';
import layout from '../mixins/layout';
import '../scss/site.scss';

const { Toasts } = auroraAppCore;

export default {
  name: 'Mobile',
  components: {
    Footer,
    ErrorToast,
    MobileSavedCarts,
    Toasts,
  },
  mixins: [layout],
  methods: {
    logout() {
      this.$store.dispatch('app/LOGOUT');
      this.$router.push('/');
    },
  },
};
</script>
  <style lang="sass" src="../scss/style.sass"></style>
