<template>
  <v-data-table ref="table" v-bind="$props" v-on="$listeners"
    :server-items-length="count"
    :items="serverItems"
    :loading="isLoading"
    :item-key="idField"
    :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000] }"
    class="custom-class">
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from 'lodash';
import { VDataTable } from 'vuetify/lib';
import { mapState } from 'vuex';
import { o } from 'odata';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { odata } from '../store/conf';

export default {
  extends: VDataTable,
  mixins: [loading],
  data() {
    return {
      count: 0,
      serverItems: [],
      localSearch: '',
      localSortBy: undefined,
      localSortDesc: false,
      pageOptions: {
        itemsLength: 0,
        itemsPerPage: 10,
        page: 1,
        pageCount: 0,
        pageStart: 0,
      },
    };
  },
  props: {
    idField: { default: 'id' },
    filled: { default: true },
    endpoint: { default: '' },
    query: { default: '' },
    items: { default: () => [] },
  },
  computed: {
    ...mapState('app', ['token']),
  },
  watch: {
    query() {
      this.refresh();
    },
  },
  methods: {
    o() {
      const { token } = this;
      console.debug(token);
      return o(`${process.env.VUE_APP_APIURL}/odata/`, odata.getConf(token));
    },
    // eslint-disable-next-line func-names
    refresh: debounce(async function () {
      try {
        this.isLoading = true;
        await this.$nextTick();
        const { pageOptions, query } = this;
        const defaults = { $count: true };
        const filters = { $filter: 'IsDeleted eq false' };
        let orderBy = '';
        if (this.localSortBy?.length > 0) {
          orderBy = { $orderby: `${this.localSortBy} ${this.localSortDesc ? 'desc' : 'asc'}` };
        }
        const top = { $top: pageOptions.itemsPerPage, $skip: pageOptions.pageStart };
        const q = {
          ...filters, ...top, ...defaults, ...query, ...orderBy,
        };
        const arrayQuery = Object.entries(q);
        let qs = '';
        arrayQuery.forEach((arr) => {
          qs += `${arr.join('=')}&`;
        });
        const result = await this.$http.get(`odata/products?${qs}`);
        const data = result.data.value;
        // commit('SET_products', result.data.value);
        // commit('SET_totalProducts', result.data['@odata.count']);
        // const data = await this.o()
        //   .get(this.endpoint)
        //   .query(endpointQuery);
        const count = result.data['@odata.count'];
        this.count = count;
        this.serverItems = data;
        this.$emit('update:items', data);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }),
  },
  async mounted() {
    this.$refs.table.$on('pagination', (page) => {
      console.debug(page);
      this.pageOptions = page;
      this.refresh();
    });
    this.$refs.table.$on('update:options', (options) => {
      console.debug(options);
      if (options.sortBy?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localSortBy = options.sortBy[0];
      }
      if (options.sortDesc?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localSortDesc = options.sortDesc[0];
      }
      this.refresh();
    });
    this.refresh();
  },
  created() {
    this.$root.$on('refresh', this.refresh);
  },
  destroyed() {
    this.$refs.table.$off('pagination');
    this.$refs.table.$off('update:options');
    this.$root.$off('refresh', this.refresh);
  },
};
</script>
