import {
  mapMutations, mapState,
} from 'vuex';

export default {
  computed: {
    ...mapState('filters', ['sortBy', 'sortDesc', 'search', 'filter', 'filterCategory', 'filterProductGroup', 'filterBrand']),
    localSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.SET_search(value);
      },
    },
    localSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.SET_sortBy(value);
      },
    },
    localSortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.SET_sortDesc(value);
      },
    },
    localFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.SET_filter(value);
      },
    },
    localFilterCategory: {
      get() {
        return this.filterCategory;
      },
      set(value) {
        this.SET_filterCategory(value);
      },
    },
    localFilterProductGroup: {
      get() {
        return this.filterProductGroup;
      },
      set(value) {
        this.SET_filterProductGroup(value);
      },
    },
    localFilterBrand: {
      get() {
        return this.filterBrand;
      },
      set(value) {
        this.SET_filterBrand(value);
      },
    },
  },
  mounted() {
    this.refreshFilters();
  },
  methods: {
    /** Vuex methods
     */
    ...mapMutations('filters', [
      'SET_search',
      'SET_sortBy',
      'SET_sortDesc',
      'SET_filter',
      'SET_filterCategory',
      'SET_filterProductGroup',
      'SET_filterBrand',
      'RESET_filters',
    ]),

    refreshFilters() {
      this.SET_filterCategory([]);
      this.SET_filterProductGroup([]);
      this.SET_filterBrand([]);
      console.log('mounted', this.$route.query);
      if (this.$route.query.filter) {
        this.SET_filter(this.$route.query.filter);
      }
      if (this.$route.query.filterCategory) {
        this.SET_filterCategory([this.$route.query.filterCategory]);
      }
      if (this.$route.query.filterBrand) {
        this.SET_filterBrand([this.$route.query.filterBrand]);
      }
      if (this.$route.query.filterDeal) {
        this.SET_filter(['Super Deals']);
      }
    },
  },
};
