<template>
  <v-data-table
    :loading="isSaving"
    :items="items"
    item-key="id"
    :headers="headers"
    sort-by="created"
    :sort-desc="true"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col offset="6" cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'auth-EditProductGroupDeal',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';

export default {
  name: 'ProductGroupDealList',
  components: {
    TableBasicButtons,
  },
  data() {
    return {
      headers: [
        { text: 'Deal Type', value: 'dealType' },
        { text: 'Customer Category', value: 'customerCategory' },
        { text: 'Product Group', value: 'productGroupAndDescription' },
        { text: 'Discount %', value: 'discountPercentage' },
        { text: 'Created', value: 'created' },
        { text: '', value: 'actions' },
      ],
      search: '',
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async remove(item) {
      this.$emit('delete', item);
    },
  },
};
</script>
