<template>
  <div class="filter-container pa-2 rounded-lg">
    <h3>{{ title }}</h3>
    <v-text-field
      v-model="search"
      filled
      prepend-inner-icon="mdi-magnify"
      v-if="canSearch"
      clearable
      :label="`Search ${title}`"
    />
    <div class="filter-selection">
      <v-checkbox
        style="display: block"
        class="ml-2"
        v-for="item in filteredItems"
        :key="item.key"
        :value="item.key"
        v-model="localValue"
        hide-details
        :label="item.value"
        @change="$emit('input', localValue)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyValueFilter',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: undefined,
      search: '',
    };
  },
  computed: {
    filteredItems() {
      // if (this.isMobile && this.localValue) {
      //   return this.items.filter((item) => item.key === this.localValue);
      // }
      const items = this.items.filter((i) => i.value?.length > 0);
      if (this.search?.length > 0) {
        return items.filter((i) => i.value?.toLowerCase().includes(this.search?.toLowerCase()));
      }
      return items;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  mounted() {
    this.localValue = this.value;
  },
};
</script>
<style scoped>
.filter-container {
  border: 1px solid #424242;
}
.filter-selection {
  max-height: 320px;
  overflow: auto;
  margin-left: 2px;
  padding-bottom: 10px;
}
.v-text-field {
  padding-top: 0;
  margin-top: 0;
}
</style>
