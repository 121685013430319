<template>
  <div>
    <ProgressBarTimeout v-if="isLoading" />
    <div v-else>
      <h1>{{ page.title }}</h1>
      <div v-for="pageItem in pageItems" :key="pageItem.id">
        <component :is="pageItem.type" :pageItem="pageItem" />
        <div v-html="pageItem.content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressBarTimeout from '../components/ProgressBarTimeout.vue';

export default {
  name: 'DisplayPage',
  components: {
    ProgressBarTimeout,
  },
  mixins: [loading],
  data() {
    return {
      pageName: this.$route.params.slug,
      dialog: false,
      page: {
        name: 'Loading...',
      },
      pageItems: [],
      type: '',
      types: ['Content'],
    };
  },
  watch: {
    $route() {
      this.pageName = this.$route.params.slug;
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('pages', ['LOAD_pageByName']),
    ...mapActions('pageItems', ['CREATE_pageItem', 'LOAD_pageItemsByPageName', 'UPDATE_pageItem']),
    async addPageItem() {
      await this.CREATE_pageItem({
        pageId: this.page.id,
        pageItemType: this.type,
      });
    },
    async refresh() {
      this.isLoading = true;
      this.page = await this.LOAD_pageByName(this.pageName);
      this.pageItems = await this.LOAD_pageItemsByPageName(this.pageName);
      this.isLoading = false;
      console.log('Refresh');
    },
    async savePageItem(pageItem) {
      await this.UPDATE_pageItem(pageItem);
      await this.refresh();
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
  },
};
</script>
