<template>
<v-container>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <h2>Payment Successful</h2>
        </v-card-title>
        <v-card-text>
          <p>
            Thank you for your payment.
            Your order is now being processed and will be shipped shortly.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>

</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'PaymentSuccess',
  mounted() {
    this.RESET_CART();
  },
  methods: {
    ...mapMutations('cart', ['RESET_CART']),
  },
};
</script>
