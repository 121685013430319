import { sortBy } from 'lodash';

export default {
  shopProduct: (state, getters, rootState) => (p) => {
    const result = { ...p };
    const customerDeal = rootState.customerDeals.customerDeals
      .find((c) => c?.stockCode === p?.stockCode);
    if (customerDeal) {
      result.customerDeal = customerDeal;
      result.isCustomerDeal = true;
      result.price = customerDeal.priceExcl;
    } else {
      result.isCustomerDeal = false;
      result.price = p?.exclUnitSellingPrice;
    }
    result.description = p?.stockDescription;
    result.hasOptions = p?.productOpt1 || p?.productOpt2;
    // if (result.minimumOrderQuantity > 1) {
    //   result.hasOptions = true;
    // }
    if ((p.productCode?.length ?? 0) === 0) {
      result.productCode = p.stockCode;
    }
    return result;
  },
  shopProducts: (state, getters) => (products) => products.map((p) => getters.shopProduct(p)),
  productList: (state, getters) => {
    const { products } = state;
    const formattedProducts = products.map((p) => {
      const result = { ...p };
      if ((p.productCode?.length ?? 0) === 0) {
        result.productCode = p.stockCode;
      }
      return result;
    });
    const uniqueProductCodes = [...new Set(formattedProducts.map((p) => p.productCode))];
    const uniqueProducts = [];
    uniqueProductCodes.forEach((p) => {
      const uniqueProduct = formattedProducts.find((product) => p === product.productCode);
      uniqueProducts.push(uniqueProduct);
    });
    // eslint-disable-next-line max-len
    // const showVermontProducts = rootGetters['clientAppSettings/getClientAppSetting']('showVermontProducts');
    const returnValue = getters.shopProducts(uniqueProducts);
    // if (showVermontProducts === 'true') {
    //   return returnValue.concat(rootGetters['vermontProducts/vermontProductList']);
    // }
    return returnValue;
  },
  brands: (state) => {
    const { products } = state;
    const brands = [...new Set(products
      .filter((p) => p.merchandiseBrand?.length > 0)
      .map((p) => p.merchandiseBrand))].sort();
    return brands;
  },
  productGroups: (state) => {
    const { products } = state;
    const uniqueProductGroups = [...new Set(products.map((p) => p.productGroup))].sort();
    const productGroups = [];
    uniqueProductGroups.forEach((uniqueProductGroup) => {
      const product = products.find((p) => p.productGroup === uniqueProductGroup);
      const productGroup = {
        productGroup: product.productGroup,
        description: product.productGroupDescription,
      };
      productGroups.push(productGroup);
    });
    return productGroups;
  },
  newProductList: (state, getters) => state.newProducts.map((p) => getters.shopProduct(p)),
  getProduct: (state, getters) => (stockCode) => getters.productList
    .find((p) => p.stockCode === stockCode),
  getProductsByCode: (state, getters) => (productCode) => getters.shopProducts(state.products)
    .filter((p) => p.productCode === productCode),
  sortedProducts: (state) => sortBy(state.products, (c) => c.stockCode),
  sortedProductGroups: (state) => sortBy(state.productGroups, (c) => c.value),
  visibleStockCategories: (state) => state.stockCategories.filter((c) => c.key !== 'OTH'),
};
