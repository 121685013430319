<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          :items="documents"
          item-key="customerOrderNo"
          :headers="headers"
          sort-by="documentDate"
          :sort-desc="true"
          :loading="isDownloading"
        >
        <template v-slot:item.actions="{ item }">
          <v-btn
            :loading="item.isActive"
            v-if="showDownload"
            color="success" @click="download(item)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  data() {
    return {
      expanded: [],
      isDownloading: false,
      singleExpand: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'invoice',
    },
  },
  computed: {
    headers() {
      const refName = this.type === 'invoice' ? 'Invoice' : 'Credit';
      const orderNo = this.type === 'invoice' ? 'Order No' : 'Invoice Reference';
      switch (this.$vuetify.breakpoint.name) {
      default:
        return [
          { text: `${refName } Reference`, value: 'reference', width: '20%' },
          { text: 'Date', value: 'documentDate', width: '20%' },
          { text: orderNo, value: 'orderNumber', width: '10%' },
          { text: 'Customer Order No', value: 'customerOrderNo', width: '10%' },
          { text: 'Total', value: 'valueExclAfterDiscount', width: '25%' },
          { text: '', value: 'actions', width: '' },
        ];
      case 'lg':
      case 'xl':
        return [
          { text: `${refName } Reference`, value: 'reference', width: '12%' },
          { text: 'Date', value: 'documentDate', width: '10%' },
          { text: orderNo, value: 'orderNumber', width: '18%' },
          { text: 'Customer Order No', value: 'customerOrderNo', width: '25%' },
          { text: 'Total', value: 'valueExclAfterDiscount', width: '20%' },
          { text: '', value: 'actions', width: '' },
        ];
      }
    },
    lineHeaders() {
      switch (this.$vuetify.breakpoint.name) {
      default:
        return [
          { text: 'Description', value: 'stockDescription', width: '20%' },
          { text: 'Quantity', value: 'quantity', width: '15%' },
          { text: 'Unit Price ex VAT', value: 'unitSellingPriceExcl', width: '15%' },
          { text: 'VAT', value: 'vat', width: '15%' },
          { text: 'Total', value: 'total', width: '15%' },
        ];
      case 'md':
      case 'lg':
      case 'xl':
        return [
          { text: 'Description', value: 'stockDescription', width: '20%' },
          { text: 'Code', value: 'stockCode', width: '10%' },
          { text: 'Quantity', value: 'quantity', width: '10%' },
          { text: 'Unit Price ex VAT', value: 'unitSellingPriceExcl', width: '10%' },
          { text: 'Subtotal', value: 'subtotal', width: '10%' },
          { text: 'VAT', value: 'vat', width: '10%' },
          { text: 'Total', value: 'total', width: '10%' },
        ];
      }
    },
    documents() {
      return _.chain(this.items)
      // Group the elements of Array based on `color` property
        .groupBy('reference')
      // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => (
          {
            reference: key,
            documentDate: value[0].documentDate,
            orderNumber: value[0].orderNumber,
            customerOrderNo: value[0].customerOrderNo,
            valueExclAfterDiscount: `R ${this.formatCurrency(_.sumBy(value, (v) => parseFloat(v.valueInclAfterDiscount)))}`,
          }))
        .value();
    },
    typeDescription() {
      if (this.type === 'invoice') {
        return 'invoice';
      }
      return 'credit note';
    },
  },
  methods: {
    createOrderLines(lines) {
      return lines.map((l) => {
        const result = { ...l };
        result.subtotal = l.quantity * l.unitSellingPriceExcl;
        result.vat = result.subtotal * (l.vatRate / 100);
        result.total = result.subtotal + result.vat;
        return result;
      });
    },
    async download(item) {
      try {
        this.isDownloading = true;
        // eslint-disable-next-line no-param-reassign
        item.isActive = true;
        const { type } = this;
        const { reference } = item;
        let downloadUrl = 'odata/invoiceDownload?invoiceNumber=';
        let downloadType = 'Invoice';
        if (type === 'creditNote') {
          downloadUrl = 'odata/creditNoteDownload?creditNoteNumber=';
          downloadType = 'Credit Note';
        }
        const response = await axios({
          url: `${downloadUrl}${reference}`,
          method: 'GET',
          responseType: 'blob', // important
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${downloadType}-${reference}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$root.$emit('toast:error', `There was an error downloading the ${this.typeDescription}, please try again later`);
      } finally {
        // eslint-disable-next-line no-param-reassign
        item.isActive = false;
        this.isDownloading = false;
      }
    },
  },
};
</script>
