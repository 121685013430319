import { odata } from '../../conf';

export default {
  async LOAD_customers({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('customers')
      .query({ $filter: 'IsDeleted eq false', $select: 'customerName,category,customerCategory,customerAccount,registerCode,created,salesRep,salesRepName' });
    commit('SET_customers', data);
  },
  async LOAD_customerCategories({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('customers/CustomerCategories')
      .query();
    commit('SET_customerCategories', data);
  },
};
