<template>
  <v-card>
    <v-card-title primary-title>
      Prices
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="viewPrices"
        class="elevation-1"
        :loading="isLoading"
        :search="search"
        elevation="0"
        sort-by="customerAccount"
      >
        <template v-slot:top>
          <v-row>
            <v-col offset="6" cols="6">
              <v-text-field
                name="Search"
                label="Search"
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.totalCostPrice="{ item }">
          {{ formatCurrency(item.totalCostPrice) }}
        </template>
        <template v-slot:item.totalSellingPrice="{ item }">
          {{ formatCurrency(item.totalSellingPrice) }}
        </template>
        <template v-slot:item.vermontTotalCostPrice="{ item }">
          {{ formatCurrency(item.vermontTotalCostPrice) }}
        </template>
        <template v-slot:item.vermontTotalSellingPrice="{ item }">
          {{ formatCurrency(item.vermontTotalSellingPrice) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" color="primary" x-small
            :to="{ name: 'admin-PriceDetails', query: { customerAccount: item.customerAccount } }"
          >Details</v-btn>
          <v-btn color="error" x-small @click="reloadPricing(item)">Reload Pricing</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '../modules/auth/src/components/ExportToExcelButton.vue';

export default {
  name: 'Prices',
  module: 'Shop',
  mixins: [loading],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ExportToExcelButton,
  },
  data() {
    return {
      dialog: false,
      viewPrices: [],
      search: '',
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Customer Account',
          value: 'customerAccount',
        },
        {
          text: 'Number of Omni Products',
          value: 'totalProducts',
        },
        {
          text: 'Total Omni Cost Price',
          value: 'totalCostPrice',
        },
        {
          text: 'Total Omni Selling Price',
          value: 'totalSellingPrice',
        },
        // {
        //   text: 'Number of Vermont Products',
        //   value: 'vermontTotalProducts',
        // },
        // {
        //   text: 'Total Vermont Cost Price',
        //   value: 'vermontTotalCostPrice',
        // },
        // {
        //   text: 'Total Vermont Selling Price',
        //   value: 'vermontTotalSellingPrice',
        // },
        // {
        //   text: 'Total Vermont Selling Price',
        //   value: 'vermontTotalSellingPrice',
        // },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const response = await this.$http.get('/api/reports/pricing/overview');
      this.viewPrices = response.data;
      this.isLoading = false;
    },
    async reloadPricing(item) {
      const { customerAccount } = item;
      const result = await this.$confirm(`Are you sure you want to rerun the pricing calculation for ${customerAccount}, this process will take a long time to complete`);
      if (result) {
        this.isLoading = true;
        await this.$http.post(`/api/tasks/pricing/reload/${customerAccount}`);
        await this.refresh();
        this.isLoading = false;
      }
    },
  },
};
</script>
