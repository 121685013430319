<template>
  <v-card elevation="3" style="height: 100%;">
      <v-btn icon
        color="primary"
        class="icon text-right"
        :to="{
            name: 'products-ProductDetail',
            query: { productCode: item.productCode }
        }">
        <v-icon
        color="primary"
        v-on:click="$router.push()"
        dark>
          mdi-information
        </v-icon>
      </v-btn>
      <div style="padding: 5px">
        <div class="super-deal text-center" v-if="item.discountType === 'SuperDeal'">
          Super Deal
        </div>
        <div class="super-deal-discount text-center" v-if="item.discountType === 'SuperDeal'">
          {{ item.discountPercentage}}% OFF
        </div>
      </div>
      <v-card-text style="text-align: center;">
        <v-row no-gutters align="center" justify="center" class="fill-height">
          <v-col cols="6" lg="12" xl="12">
            <router-link :to="{
              name: 'products-ProductDetail',
              query: { productCode: item.productCode }
            }">
              <ProductImage :item="item" />
            </router-link>
          </v-col>
          <v-col cols="6" lg="12" xl="12">
            <p v-if="item.hasOptions" class="caption" style="min-height: 40px">
              {{ item.productCode }}
            </p>
            <p v-else class="caption" style="min-height: 40px">
              {{ item.stockDescription }}
            </p>
          </v-col>
        </v-row>
        <router-link class="stock-meta subtitle-1 text-no-decoration" :to="{
              name: 'products-ProductDetail',
              query: { productCode: item.productCode }
            }">
          <div v-if="!item.hasOptions">
            <v-row>
              <v-col v-if="!isPublic" cols="6">
                <div class="basic-block text-center">
                  <b style="display: block">List Price</b>
                  <span>
                    R {{formatCurrency(item.price)}}
                  </span>
                </div>
              </v-col>
              <v-col :cols="isPublic ? '12' : '6'">
                <div class="primary-block text-center">
                  <b style="display: block">{{ isPublic ? 'Price' : 'Your Price' }}</b>
                  <span class="discount-block">R {{ formatDiscount(item) }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col offset="6" cols="6" class="text-center">
                <div class="text-subtitle-2 text-black text-no-decoration">excl VAT</div>
              </v-col>
            </v-row>
            <p class="caption">
              {{ item.packSize }}
            </p>
            <p class="caption" v-if="!item.hasOptions">Stock Code: {{item.stockCode}}</p>
            <span v-else class="caption">&nbsp;</span>
            <p v-if="item.minimumOrderQuantity > 1" class="caption">
              Minimum order: {{ item.minimumOrderQuantity }}
            </p>
            <p class="caption" style="color: #4caf50; padding: 0"
              v-if="available(item)">{{ item.available }} in stock</p>
            <p class="caption" style="color: #FF4500; padding: 0" v-else>Available on Backorder</p>
            <!-- <p class="c-price block">&nbsp;</p> -->
            <!-- <p class="caption">
              {{ item.leadTime }}
            </p> -->
          </div>
          <div v-if="item.hasOptions && isPublic">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="primary-block text-center">
                  <b style="display: block">Price From</b>
                  <span class="discount-block">R {{ lowestPrice }}</span>
                  <!-- <b style="display: block">To</b>
                  <span class="discount-block">R {{ highestPrice }}</span> -->
                </div>
              </v-col>
              <v-col offset="6" cols="6" class="text-center">
                <div class="text-subtitle-2 text-black text-no-decoration">excl VAT</div>
              </v-col>
            </v-row>
          </div>
        </router-link>
      </v-card-text>
      <v-card-actions class="action-buttons-container">
        <v-btn
          class="rounded-lg text-capitalize"
          color="success"
          v-if="!item.hasOptions"
          block
          data-cy="productList-addToCart"
          v-on:click="add_to_cart(item)">
          {{ addToCartText }}
        </v-btn>
        <v-btn
          class="primary rounded-lg text-capitalize"
          v-else
          block
          data-cy="productList-selectOptions"
          :to="{
            name: 'products-ProductDetail',
            query: { productCode: item.productCode }
          }">
        Select Options
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProductImage from './ProductImage.vue';

export default {
  components: {
    ProductImage,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      productsByCode: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isPublic']),
    ...mapGetters('products', ['getProduct', 'productList', 'getProductsByCode', 'shopProduct', 'shopProducts']),
    addToCartText() {
      if (this.item.minimumOrderQuantity > 1) {
        return `Add ${this.item.minimumOrderQuantity} to cart`;
      }
      return 'Add to cart';
    },
    backgroundColour() {
      return process.env.VUE_APP_PRIMARY_COLOUR;
    },
    productOptions() {
      return this.productsByCode.map((p) => {
        if (p.productOpt2?.length > 0) {
          return {
            stockCode: p.stockCode,
            option: `${p.productOpt1} - ${p.productOpt2}`,
          };
        }
        return {
          stockCode: p.stockCode,
          option: `${p.productOpt1}`,
        };
      });
    },
    prices() {
      return this.productsByCode.map((p) => this.formatDiscount(p));
    },
    highestPrice() {
      return Math.max(...this.prices);
    },
    lowestPrice() {
      return Math.min(...this.prices);
    },
  },
  methods: {
    ...mapActions('products', ['LOAD_productByProductCode']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    add_to_cart(product) {
      const amount = this.item?.minimumOrderQuantity ?? 1;
      this.ADD_TO_CART({
        amount,
        product,
      });
      this.$root.$emit('toast:notify', `Added ${product.description} to your cart`);
    },
    available(item) {
      return item.available > 0;
    },
    formatDiscount(item) {
      return this.formatCurrency(item.price * ((100 - item.discountPercentage) / 100));
    },
  },
  async created() {
    if (this.item.hasOptions) {
      let products = null;
      products = await this.LOAD_productByProductCode(this.item.productCode);
      this.productsByCode = this.shopProducts(products);
    }
  },
};
</script>
<style lang="scss" scoped>
.action-buttons-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 64px;
}
.stock-description {
  word-break: break-word;
  margin-top: auto;
  margin-bottom: auto;
}

.action-buttons {
  width: 100%;
}
.mb-40 {
  margin-bottom: 40px;
}
p.caption {
  margin-bottom: 5px;
}
</style>
