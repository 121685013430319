export default {
  async checkout() {
    await new Promise((r) => setTimeout(r, 15000)); // simulate waiting for network
  },
  decrement_amount({ commit }, id) {
    commit('DECREASE_AMOUNT', id);
  },
  increment_amount({ commit }, id) {
    commit('INCREASE_AMOUNT', id);
  },
};
