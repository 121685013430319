<template>
  <v-card :loading="isLoading || isSaving">
    <v-card-title primary-title>
        Vermont Products
    </v-card-title>
    <v-card-text>
      <CustomerProductList
        :items="vermontProducts"
        @change="save"
        @query="query"
        :serverTotal="totalProducts" />
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CustomerProductList from '../components/VermontProductList.vue';

export default {
  name: 'CustomerProduct',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    CustomerProductList,
  },
  computed: {
    ...mapState('vermontProducts', ['vermontProducts', 'totalProducts']),
  },
  data() {
    return {
      searchQuery: {
        page: 1,
        itemsPerPage: 50,
        search: '',
        sortDesc: false,
        sortBy: 'model',
      },
    };
  },
  async mounted() {
    const { searchQuery } = this;
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.loadData(searchQuery);
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('vermontProducts', ['LOAD_vermontProducts', 'UPDATE_vermontProduct']),
    async save(item) {
      this.isSaving = true;
      await this.UPDATE_vermontProduct(item);
      this.isSaving = false;
    },
    // eslint-disable-next-line func-names
    loadData: debounce(async function () {
      this.isLoading = true;
      const { searchQuery } = this;
      await this.LOAD_vermontProducts(searchQuery);
      this.isLoading = false;
    }),
    async query(q) {
      this.searchQuery = q;
      await this.loadData();
    },
  },
};
</script>
