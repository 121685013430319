<template>
    <div
      :style="`background-color: ${color}`">
        <span
          :class="`title font-weight-bold`">
         {{price}}</span>
        <br/>
        <span class="grey--text">{{text}}</span>
    </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    price: {},
    text: {},
    discount: {},
    color: {},
  },
};
</script>

<style scoped>
.c-icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
