<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { components as auroraAppCore } from '@codehq/aurora-app-core';
import { mapActions } from 'vuex';
import { clarity } from 'clarity-js';
import Desktop from './layouts/Desktop.vue';
import Mobile from './layouts/Mobile.vue';
import ErrorToast from './components/ErrorToast.vue';
import Footer from './components/Footer.vue';
import MiniCart from './components/MiniCart.vue';
import SavedCarts from './components/SavedCarts.vue';
import MobileSavedCarts from './components/MobileSavedCarts.vue';
import Feedback from './components/Feedback.vue';
import authHub from './authHub';
import errorHub from './errorHub';
import pkg from '../package.json';
import './scss/site.scss';

const { Toasts } = auroraAppCore;

export default {
  name: 'App',
  components: {
    Desktop,
    Mobile,
    Footer,
    ErrorToast,
    MiniCart,
    SavedCarts,
    MobileSavedCarts,
    Toasts,
    Feedback,
  },
  computed: {
    resolveLayout() {
      if (this.$vuetify.breakpoint.mobile) {
        return 'Mobile';
      }
      this.$log.info('loading desktop layout');
      return 'Desktop';
    },
  },
  async mounted() {
    clarity.consent();
    clarity.start({
      projectId: 'ocakea3y6i',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
    });
    clarity.set('Version', pkg.version);
    await this.LOAD_stockCategories();
  },
  created() {
    console.log('registering event listeners');
    errorHub.$on('network.error', () => {
      this.error = '';
      this.$nextTick(() => {
        this.error = 'There was an error communicating with the server';
      });
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', () => {
      this.LOGOUT();
      this.$router.push('/login');
    });
    this.$root.$on('auth:login', async () => {
      this.$router.push('/');
      console.log('auth:login');
      await this.LOAD_clientAppSettings();
      this.$root.$emit('toast:notify', 'Logged in successfully');
      try {
        this.$log.info('Loading customer info');
        await this.LOAD_customerInfo();
      } catch (error) {
        this.$log.info('This is a public user with no customer info');
      }
    });
    this.$root.$on('toast:notify', (msg) => {
      this.message = '';
      this.$nextTick(() => {
        this.message = msg;
      });
    });
    this.$root.$on('toast:error', (msg) => {
      if ((this.errorMessage ?? '').length === 0) {
        this.$nextTick(() => {
          this.errorMessage = msg;
        });
      }
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    errorHub.$on('network.error');
    this.$root.$off('auth:login');
    this.$root.$off('toast:notify');
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_stockCategories']),
    ...mapActions('app', ['LOGOUT']),
    ...mapActions('clientAppSettings', ['LOAD_clientAppSettings']),
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
    logout() {
      this.LOGOUT();
      this.$router.push('/auth/login');
    },
  },
};
</script>
<style lang="sass" src="./scss/style.sass"></style>
