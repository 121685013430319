<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormSalesRep
      :SalesRep="editSalesRep"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: 'admin-ListSalesRep' })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSalesRep from '../components/FormSalesRep.vue';

export default {
  components: {
    FormSalesRep,
  },
  name: 'EditSalesRep',
  module: 'SalesRep',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing SalesRep. Default to {}.
      */
      editSalesRep: {},
      /**
       * Query Id for SalesRep
       */
      salesRepId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('salesReps', ['salesReps', 'salesRep']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.salesRepId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_salesRep(this.salesRepId);
      this.editSalesRep = this.salesRep;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('salesReps', ['LOAD_salesRep', 'CREATE_salesRep', 'UPDATE_salesRep']),
    /** Method used to save the item
     */
    async save(salesRep) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_salesRep(salesRep);
      } else {
        await this.UPDATE_salesRep(salesRep);
      }
      this.isSaving = false;
      this.$router.push({ name: 'admin-ListSalesRep' });
    },
  },
};
</script>

<docs>
# EditSalesRep example

'''js
<EditSalesRep :SalesRep="SalesRep" />
'''
</docs>
