export default {
  error: undefined,
  loading: false,
  token: undefined,
  username: undefined,
  roles: [],
  provinces: [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape',
  ],
};
