<template>
  <v-app :class="{ ios: isIos }">
    <div class="fill-width top-sticky">
      <div class="d-flex justify-space-between align-center bg-primary">
        <div>&nbsp;</div>
        <div>
          <v-btn text :href="`mailto:${supportEmail}`" class="text-lowercase white--text">
            {{ supportEmail }}
          </v-btn>
          <v-btn class="white--text" href="tel:+27215112001" text v-if="showNumber">
            +27 (0)21 511 2001
          </v-btn>
        </div>
        <div class="mr-10">
          <Feedback />
        </div>
      </div>
    </div>
    <v-app-bar
      app
      color="white"
      dark
      class="px-6 mt-9 flex justify-space-between"
      :height="appBarHeight"
      elevation="0"
    >
        <router-link :to="{ name: 'Home Page' }" style="text-decoration: none;">
          <img :src="logoSrc" alt="Logo" style="height: 120px; width: 220px" />
        </router-link>
        <v-btn
          text
          class="black--text top-menu-button"
          to="/products/product-list"
          link
          active-class="none"
        >
          Shop
        </v-btn>
        <v-btn
          text
          class="black--text top-menu-button"
          v-for="category in visibleStockCategories"
          :key="category.key"
          :data-cy="`App-Category-${category.key}`"
          :to="{ name: 'products-ProductList', query: { filterCategory: category.key } }"
        >
          {{ category.value }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          data-cy="App-MyAccount"
          v-if="isLoggedIn && !isPublic"
          text
          class="black--text"
          :to="{ name: 'MyAccount' }"
        >
          My Account
        </v-btn>
        <!-- <Feedback /> -->
        <MiniCart />
        <div class="mr-2" />
        <SavedCarts v-if="isLoggedIn && !isPublic" />
        <v-btn
          v-if="!isLoggedIn"
          elevation="0"
          color="primary"
          class="rounded-pill"
          to="/auth/login"
        >
          Login
        </v-btn>
        <v-btn text class="black--text rounded-pill" v-if="!isLoggedIn" to="/auth/register">
          Register
        </v-btn>
        <v-btn text class="black--text" v-else @click="logout">
          Logout
        </v-btn>
        <v-menu offset-y v-if="isAdministrators">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="black--text">mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :to="{name: 'products-AdminBrands'}">
              Brands
            </v-list-item>
            <v-list-item
              :to="{name: 'auth-CustomerUser'}">
              Customer Accounts
            </v-list-item>
            <v-list-item
              :to="{name: 'auth-CustomerProduct'}">
              Customer Products
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-HangfireJobs'}">
              Integration Jobs
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-ListPages'}">
              Pages
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-ProductList'}">
              Products
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-Prices'}">
              Pricing
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-ListSalesRep'}">
              Sales Reps
            </v-list-item>
            <v-list-item
              :to="{name: 'products-ListSuperDeal'}">
              Super Deals
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-Tags'}">
              Tags
            </v-list-item>
            <v-list-item
              :to="{name: 'auth-VermontProducts'}">
              Vermont Products
            </v-list-item>
            <v-list-item
              :to="{name: 'auth-ProductGroupDeals'}">
              Product Group Deals
            </v-list-item>
            <v-list-item
              :to="{name: 'FeedbackItems'}">
              Feedback List
            </v-list-item>
            <v-list-item
              :to="{name: 'admin-OmniPricelists'}">
              Omni Pricelists
            </v-list-item>
            <!-- <v-list-item
              :to="{name: 'auth-AdminUserManagement'}">
              User Accounts
            </v-list-item> -->
          </v-list>
        </v-menu>
    </v-app-bar>
    <div class="align-start mb-5 fill-height" :class="{ 'contained-width': isContained }">
      <v-main>
        <router-view />
      </v-main>
    </div>
    <Footer class="hidden-md-and-down"/>
    <Toasts :text="message" color="success" :timeout="850" />
    <ErrorToast :text="errorMessage" @close="errorMessage = ''" />
  </v-app>
</template>

<script>
import { components as auroraAppCore } from '@codehq/aurora-app-core';
import ErrorToast from '@/components/ErrorToast.vue';
import Footer from '@/components/Footer.vue';
import MiniCart from '@/components/MiniCart.vue';
import SavedCarts from '@/components/SavedCarts.vue';
import Feedback from '@/components/Feedback.vue';
import layout from '../mixins/layout';
import '../scss/site.scss';

const { Toasts } = auroraAppCore;

export default {
  name: 'Desktop',
  components: {
    Footer,
    ErrorToast,
    MiniCart,
    SavedCarts,
    Toasts,
    Feedback,
  },
  mixins: [layout],
  methods: {
    logout() {
      this.$store.dispatch('app/LOGOUT');
      this.$router.push('/');
    },
  },
};
</script>
  <style lang="sass" src="../scss/style.sass"></style>
