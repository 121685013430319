<template>
  <v-data-table
    :loading="isSaving"
    :items="items"
    item-key="id"
    :headers="headers"
    sort-by="created"
    :sort-desc="true"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col offset="6" cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.salesRep="{ item }">
      <a v-if="item.salesRepEmail" :href="`mailto:${item.salesRepEmail}`">
        {{ item.salesRep }}
      </a>
      <span v-else>
        {{ item.salesRep }}
      </span>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn color="error" @click="remove(item)">Delete</v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'CustomerUserList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Customer Account', value: 'customerAccount' },
        { text: 'Sales Rep', value: 'salesRep' },
        { text: 'Created', value: 'created' },
        { text: '', value: 'actions' },
      ],
      search: '',
    };
  },
  methods: {
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async remove(item) {
      const res = await this.$confirm(`Are you sure you want to delete ${item.username} for ${item.customerAccount}`, { title: 'Delete' });
      if (res) {
        this.$emit('delete', item);
      }
    },
  },
};
</script>
