export default {
  data() {
    return {

      /**
      * validation rules
      */
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
};
