<template>
  <div>
    <v-footer height="auto" color="primary" dark>
      <v-container fluid grid-list-xs>
        <v-row>
          <v-col cols="4">
            Support
          </v-col>
          <v-col offset="2" cols="2">
            <span>Visit Us</span>
          </v-col>
          <v-col offset="2" cols="2">
            Payment Methods
          </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row class="mt-1">
          <v-col cols="4">
            <v-row dense>
              <v-col cols="12">
                <v-btn
                  v-if="showNumber"
                  color="primary"
                  dark
                  href="tel:+27215112001"
                  >+27 21 511 2001</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  dark
                  :href="'mailto:' + supportEmail"
                  >{{ supportEmail }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="2" cols="2">
            <a :href="mapLink" target="_blank" class="mapLink">34 Natal Steet</a>
            <a :href="mapLink" target="_blank" class="mapLink">Paarden Eiland</a>
            <a :href="mapLink" target="_blank" class="mapLink">Cape Town</a>
            <a :href="mapLink" target="_blank" class="mapLink">7405</a>
          </v-col>
          <v-col offset="2" cols="2">
            <v-img contain width="160" src="../assets/payment-methods.png" />
          </v-col>
        </v-row>
            <v-row>
              <v-col cols="2">
                <router-link
                  :to="{ name: 'DisplayPage', params: { slug: 'privacy-policy' }}"
                  style="color: white;"
                >
                  Privacy Policy
                </router-link>
              </v-col>
              <v-col cols="8" class="d-flex align-center justify-center">
                <span class="text-subtitle-2">v{{ version}}</span>
              </v-col>
              <v-col cols="2">
                <router-link
                  :to="{ name: 'DisplayPage', params: { slug: 'terms-and-conditions' }}"
                  style="color: white;"
                >
                  Terms and Conditions
                </router-link>
              </v-col>
            </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import pkg from '../../package.json';

export default {
  name: 'Footer',
  data() {
    return {
      toggle_paymentmethod: 2,
      mapLink: 'https://goo.gl/maps/mMrWEZYULN8ZnzGS9',
    };
  },
  computed: {
    showNumber() {
      return process.env.VUE_APP_ShowNumber === 'true';
    },
    supportEmail() {
      return process.env.VUE_APP_Support_Email;
    },
    version() {
      return pkg.version;
    },
  },
};
</script>
<style scoped>
.mapLink {
  text-decoration: none;
  display: block;
  color: white;
  margin-bottom: 1px;
}
</style>
