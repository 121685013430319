<template>
  <v-card>
    <v-card-title primary-title>
      Products with Tags
    </v-card-title>
    <v-card-text>
      <v-alert type="error" v-for="(r, i) in uploadResult" :key="i">
        {{ r }}
      </v-alert>
      <v-progress-linear v-if="isLoading" :indeterminate="true">
      </v-progress-linear>
      <ServerDataTable
        :headers="headers"
        endpoint="products"
        :query="query"
        id-field="stockCode"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <!-- <v-btn
              color="success"
              data-cy="SalesRep-button-new"
              @click="dialog = true"
            >
              <v-icon class="mr-2">
                mdi-plus
              </v-icon>
              New
            </v-btn> -->
            <v-btn class="ml-2" color="error" @click="deleteAll()">
              Delete All
            </v-btn>
            <v-btn class="ml-2" color="success"
              @click="exportToExcel"
              :loading="isExporting"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>
              Export to Excel
            </v-btn>
            <v-btn class="ml-2" color="error" @click="clearCache()">
              Clear Cache
            </v-btn>
            <a class="ml-5" href="../assets/TagsImportTemplate.xlsx">Import Template</a>
            <v-spacer></v-spacer>
            <upload-file @id="fileUploaded" />
            <v-dialog
              v-model="dialog"
              scrollable
              persistent :overlay="false"
              max-width="500px"
              transition="dialog-transition"
            >
            <v-card>
              <v-card-title>
                <span class="headline">New Tag</span>
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  :items="allProducts"
                  :item-text="p => `${p.stockCode} - ${p.stockDescription}`"
                  item-value="stockCode"
                  filled
                  data-cy="FormSuperDeal-stockCode"
                  label="Product"
                  v-model="stockCode"
                  append-outer-icon="mdi-cloud-download-outline"
                  @click:append-outer="loadProducts"
                  :loading="loadingItems.includes('allProducts')"
                />
                <v-text-field
                  v-model="newTag"
                  label="Tag"
                  required
                  autofocus
                  append-outer-icon="mdi-plus"
                  :loading="isSaving"
                  @keyup.enter="createTag(newTag, stockCode)"
                  @click:append-outer="createTag(newTag, stockCode)"
                ></v-text-field>
                <v-simple-table v-if="tags.length > 0" class="mb-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>
                          Tag
                        </th>
                        <th>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tag) in tags" :key="tag.id">
                        <td><h4>{{ tag.tag }}</h4></td>
                        <td>
                          <v-btn color="error" icon @click="remove(tag)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:body.prepend>
          <tr>
            <td />
            <td>
              <v-text-field v-model="filterStockCode" type="text" label="Contains" />
            </td>
            <td>
              <v-text-field v-model="filterTag" type="text" label="Contains" />
            </td>
          </tr>
        </template>
        <template v-slot:item.tags="{ item }">
          <v-chip
            v-for="tag in item.tags"
            :key="tag.id"
            class="ma-2"
            color="primary"
          >
            {{ tag.tag }}
          </v-chip>
        </template>
        <template v-slot:item.merchandiseImgUrl="{ item }">
          <v-img :src="item.merchandiseImgUrl" width="128" contain></v-img>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small @click="edit(item)">Edit</v-btn>
        </template>
      </ServerDataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import { sortBy } from 'lodash';
import { json2excel } from 'js2excel';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ServerDataTable from '../components/ServerDataTable.vue';
import UploadFile from '../components/UploadFile.vue';

export default {
  name: 'Tags',
  module: 'Products',
  mixins: [loading],
  components: {
    ServerDataTable,
    UploadFile,
  },
  data() {
    return {
      dialog: false,
      isExporting: false,
      newTag: '',
      stockCode: '',
      products: [],
      allProducts: [],
      loadingItems: [],
      uploadResult: [],
      items: [],
      filterStockCode: '',
      filterTag: '',
      search: '',
      localSearch: '',
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('productTags', ['productTags']),
    ...mapState('vermontProductTags', ['vermontProductTags']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'merchandiseImgUrl',
        },
        {
          text: 'Stock Code',
          value: 'stockCode',
        },
        {
          text: 'Tags',
          value: 'tags',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    query() {
      const { filterStockCode, filterTag } = this;
      let $filter = 'Tags/any()';
      let $expand = 'Tags';
      if (filterTag?.length > 0) {
        $expand = `Tags($filter=contains(Tag,'${filterTag}'))`;
      }
      if (filterStockCode?.length > 0) {
        $filter = `contains(StockCode,'${filterStockCode}')`;
      }
      return { $filter, $expand };
    },
    tags() {
      const { items, stockCode } = this;
      console.log(stockCode);
      return items?.filter((i) => i.stockCode === stockCode) ?? [];
    },
    exportData() {
      return this.items.map((c) => ({
        'Stock Code': c.stockCode,
        Tags: c.tags.map((t) => t.tag).join(';'),
      }));
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('productTags', ['LOAD_productTags', 'DELETE_productTag', 'CREATE_productTag']),
    ...mapActions('vermontProductTags', ['LOAD_vermontProductTags', 'DELETE_vermontProductTag', 'CREATE_vermontProductTag']),
    ...mapActions('products', ['LOAD_allProductWithTags', 'LOAD_allProducts']),
    async loadNetwork(networkObj) {
      this.loadingItems.push(networkObj.name);
      const result = await networkObj.method();
      this.loadingItems.pop(networkObj.name);
      return result;
    },
    async exportToExcel() {
      try {
        this.isExporting = true;
        const { filterStockCode, filterTag } = this;
        let $filter = 'Tags/any()';
        let $expand = 'Tags';
        if (filterTag?.length > 0) {
          $expand = `Tags($filter=contains(Tag,'${filterTag}'))`;
        }
        if (filterStockCode?.length > 0) {
          $filter = `contains(StockCode,'${filterStockCode}')`;
        }
        const response = await this.$http.get('odata/products', {
          params: {
            $filter,
            $expand,
          },
        });
        const data = response.data.value.map((c) => ({
          'Stock Code': c.stockCode,
          Tags: c.tags.map((t) => t.tag).join(';'),
        }));
        await json2excel({
          data,
          name: 'Tags',
          formateDate: 'yyyy/mm/dd',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isExporting = false;
      }
    },
    async loadProducts() {
      this.loadingItems.push('allProducts');
      const result = await this.LOAD_allProducts();
      this.loadingItems.pop('allProducts');
      this.allProducts = sortBy(result, (r) => r.stockCode);
    },
    async createTag(tag, stockCode) {
      this.isSaving = true;
      console.debug(stockCode, stockCode.toLowerCase().startsWith('v'));
      if (stockCode.toLowerCase().startsWith('v')) {
        await this.CREATE_vermontProductTag({
          tag,
          product: {
            model: stockCode.replace('V', ''),
          },
        });
      } else {
        await this.CREATE_productTag({
          tag,
          stockCode,
        });
      }
      this.$root.$emit('refresh');
      this.isSaving = false;
    },
    edit(item) {
      this.dialog = true;
      this.stockCode = item.stockCode;
    },
    /** Delete SalesRep from the collection
     */
    async remove(item) {
      console.debug(item);
      // const result = await this.$confirm('Are you sure you want to delete this?');
      // if (result) {

      // }
      this.isLoading = true;
      if (item.type === 'vermont') {
        await this.DELETE_vermontProductTag(item.id);
      } else {
        await this.DELETE_productTag(item.id);
      }
      this.isLoading = false;
      await this.refresh();
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async fileUploaded(id) {
      this.uploadResult = [];
      await this.$nextTick();
      this.isLoading = true;
      const result = await this.$http.post('/api/uploads/tags', { fileId: id });
      this.uploadResult = result.data;
      console.debug(this.uploadResult);
      await this.$nextTick();
      this.$root.$emit('toast:notify', 'Tags imported completed');
      this.isLoading = false;
    },
    async clearCache() {
      const res = await this.$confirm('Are you sure you want to clear the cache?', { title: 'Confirm' });
      if (res) {
        this.isLoading = true;
        await this.$http.post('api/tasks/cache/clear');
        this.$root.$emit('toast:notify', 'Cache cleared successfully');
        this.isLoading = false;
      }
    },
    async deleteAll() {
      const res = await this.$confirm('Are you sure you want to delete all the product tags', { title: 'Confirm' });
      if (res) {
        this.isLoading = true;
        await this.$http.post('api/admin/DeleteAllProductTags');
        await this.$nextTick();
        this.$root.$emit('toast:notify', 'All tags deleted successfully');
        this.isLoading = false;
      }
    },
    searchChanged() {
      const { search } = this;
      this.localSearch = search;
    },
  },
};
</script>
