import { sortBy } from 'lodash';

export default {
  getCustomer: (state) => state.customer,
  customerAccounts: (state) => state.customers?.map((c) => c.customerAccount).sort() ?? [],
  industries: (state) => state.customers?.map((c) => c.category).sort() ?? [],
  customerCategories: (state) => state.customers?.map((c) => c.customerCategory).sort() ?? [],
  sortedCustomers: (state) => sortBy(state.customers, (c) => c.customerAccount),
  sortedCustomerCategories: (state) => sortBy(state.customerCategories, (c) => c.value),
};
