<template>
  <v-card>
    <v-card-text style="padding-bottom: 30px;">
      <UserManagement />
      <br />
    </v-card-text>
  </v-card>
</template>

<script>
import UserManagement from '@codehq/aurora-app-core/src/components/UserManagement.vue';

export default {
  name: 'AdminUserManagement',
  module: 'Auth',
  components: {
    UserManagement,
  },
};
</script>
