<template>
  <v-card>
    <v-card-title primary-title>
      Customer Pricing
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="isLoading"
        :items="customerPricing"
        item-key="customerAccount"
        :headers="headers"
        sort-by="processedDate"
        :sort-desc="true"
        :search="search"
      >
        <template v-slot:top>
          <v-row no-gutters>
            <v-col offset="6" cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.processedDate="{ item }">
          {{ formatDateTime(item.processedDate) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="primary" @click="reloadPricing(item)">Reload Prices</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CustomerList from '../modules/auth/src/components/CustomerList.vue';

export default {
  name: 'CustomerPricing',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CustomerList,
  },
  data() {
    return {
      customerPricing: [],
      headers: [
        { text: 'Customer Account', value: 'customerAccount' },
        { text: 'Active', value: 'active' },
        { text: 'Last Processed', value: 'processedDate' },
        { text: 'Duration (ms)', value: 'duration' },
        { text: '', value: 'actions' },
      ],
      search: '',
    };
  },
  computed: {
    ...mapState('customers', ['customers']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('customers', ['LOAD_customers']),
    async refresh() {
      this.isLoading = true;
      const response = await this.$http.get('api/reports/pricing/processed');
      this.customerPricing = response.data;
      this.isLoading = false;
    },
    async reloadPricing(item) {
      const { customerAccount } = item;
      try {
        this.isLoading = true;
        await this.$http.post(`api/tasks/pricing/reload/${customerAccount}`);
        await this.refresh();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
