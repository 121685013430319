<template>
  <div v-if="page">
    <v-btn color="primary" @click="dialog = true" v-if="canEdit">
      Add page item
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          Add Page Item
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-select v-model="type" :items="types" label="Page Item Type"></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="addPageItem">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import content from '../mixins/content';

export default {
  name: 'PageItemManager',
  mixins: [content],
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      page: undefined,
      type: '',
      types: ['Content', 'New Arrivals', 'Brands Carousel'],
    };
  },
  mounted() {
    this.refresh();
    this.$root.$on('pages.updated', () => {
      this.refresh();
    });
  },
  methods: {
    ...mapActions('pageItems', ['CREATE_pageItem']),
    ...mapActions('pages', ['LOAD_pageByName']),
    async addPageItem() {
      await this.CREATE_pageItem({
        pageId: this.page.id,
        pageItemType: this.type,
      });
      this.$root.$emit('pages.updated');
      this.dialog = false;
    },
    async refresh() {
      this.page = await this.LOAD_pageByName(this.pageName);
    },
  },
};
</script>
