<template>
  <div>
    <ProgressBarTimeout v-if="isLoading" />
    <div v-else>
      <h1>{{ page.title }}</h1>
      <div v-for="pageItem in pageItems" :key="pageItem.id">
        <component :is="pageItem.type" :pageItem="pageItem" />
        <VueEditor v-model="pageItem.content" />
        <v-btn
          color="success"
          class="float-right cursor-pointer mt-5"
          @click="savePageItem(pageItem)"
          title="Save changes"
        >
          <v-icon class="mr-2">mdi-check</v-icon>
          Save changes
        </v-btn>
        <v-btn
          class="float-right cursor-pointer mt-5 mr-5"
          @click="refresh"
          title="Cancel changes"
        >
          <v-icon class="mr-2">mdi-close</v-icon>
          Cancel
        </v-btn>
      </div>
      <v-btn
        v-if="pageItems.length === 0"
        color="primary" class="mt-5" @click="dialog = true"
      >
        Add page item
      </v-btn>
      <v-btn
        color="primary"
        class="float-right cursor-pointer mt-5 mr-2"
        :to="{ name: 'DisplayPage', params: { slug: page.slug }}"
        target="_blank"
      >
        <v-icon class="mr-2">mdi-eye</v-icon>
        Preview
      </v-btn>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            Add Page Item
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-select v-model="type" :items="types" label="Page Item Type"></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="dialog = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="addPageItem">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressBarTimeout from '../components/ProgressBarTimeout.vue';

export default {
  name: 'EditPage',
  components: {
    ProgressBarTimeout,
    VueEditor,
  },
  mixins: [loading],
  data() {
    return {
      pageId: this.$route.query.id,
      dialog: false,
      page: {
        name: 'Loading...',
      },
      pageItems: [],
      type: '',
      types: ['Content', 'New Arrivals', 'Brands Carousel'],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('pages', ['LOAD_page']),
    ...mapActions('pageItems', ['CREATE_pageItem', 'LOAD_pageItemsByPageName', 'UPDATE_pageItem']),
    async addPageItem() {
      await this.CREATE_pageItem({
        pageId: this.page.id,
        pageItemType: this.type,
      });
    },
    async refresh() {
      this.isLoading = true;
      this.page = await this.LOAD_page(this.pageId);
      this.pageItems = await this.LOAD_pageItemsByPageName(this.page.slug);
      this.isLoading = false;
      console.log('Refresh');
    },
    async savePageItem(pageItem) {
      await this.UPDATE_pageItem(pageItem);
      await this.refresh();
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
  },
};
</script>
