<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" xl="3" lg="3">
        <v-card class="search-card" :elevation="elev" :class="{ hidden: !showFilters }">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Filter by:
              <v-btn
                class="hidden-md-and-up"
                icon @click="showFilters = !showFilters">
                <v-icon v-if="showFilters">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              </h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-btn
              v-if="isFiltered"
              class="mb-5 clear-filters"
              text
              block
              @click="RESET_filters"
            >
              <v-icon>mdi-close</v-icon>
              Clear filters
            </v-btn>
            <v-text-field
              id="productSearch"
              class="mb-2"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-debounce:800="searchChanged" :debounce-events="'input'"
              label="Search All Products"
            ></v-text-field>
            <div class="filter-container pa-2 rounded-lg mb-5">
              <v-checkbox
                style="display: block; margin-bottom: 15px"
                v-for="filterType in filterTypes"
                :key="filterType"
                :value="filterType"
                v-model="localFilter"
                hide-details
                :label="filterType"
                class="ml-2"
              />
            </div>
            <KeyValueFilter
              class="mb-5"
              title="Categories"
              :items="stockCategories"
              v-model="localFilterCategory"
            />
            <KeyValueFilter
              class="mb-5"
              title="Groups"
              :items="productGroups"
              v-model="localFilterProductGroup"
              :canSearch="true"
            />
            <KeyValueFilter
              title="Brands"
              :items="productBrands"
              v-model="localFilterBrand"
              :canSearch="true"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else cols="12">
        <v-text-field
          id="productSearch"
          class="mb-2"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          v-debounce:800="searchChanged" :debounce-events="'input'"
          label="Search All Products"
          append-icon="mdi-filter"
          @click:append="dialog = true"
        ></v-text-field>
        <v-dialog
          v-model="dialog"
          scrollable fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-title primary-title>
              Filter
            </v-card-title>
            <v-card-text>
              <v-btn
                v-if="isFiltered"
                class="mb-5 clear-filters"
                text
                block
                @click="RESET_filters();dialog = false"
              >
                <v-icon>mdi-close</v-icon>
                Clear filters
              </v-btn>
              <v-btn
                v-if="isFiltered"
                class="mb-5"
                block
                color="primary"
                @click="dialog = false"
              >
                <v-icon>mdi-check</v-icon>
                Apply
              </v-btn>
              <div
                v-if="filterTypes.length > 0 && !isLoading"
                class="filter-container pa-2 rounded-lg mb-5">
                <v-checkbox
                  style="display: block; margin-bottom: 15px"
                  v-for="filterType in filterTypes"
                  :key="filterType"
                  :value="filterType"
                  v-model="localFilter"
                  hide-details
                  :label="filterType"
                  class="ml-2"
                />
              </div>
              <KeyValueFilter
                class="mb-5"
                title="Categories"
                :items="stockCategories"
                v-model="localFilterCategory"
              />
              <KeyValueFilter
                class="mb-5"
                title="Groups"
                :items="productGroups"
                v-model="localFilterProductGroup"
                :canSearch="true"
              />
              <KeyValueFilter
                title="Brands"
                :items="productBrands"
                v-model="localFilterBrand"
                :canSearch="true"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" xl="9" lg="9">
        <v-data-iterator
          :loading="isLoading"
           id="productList"
          :items="productList"
          :search.sync="localSearch"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000, -1] }"
          :items-per-page.sync="itemsPerPage"
          :sort-by="localSortBy"
          :sort-desc.sync="localSortDesc"
          :server-items-length="totalProducts"
          :page.sync="page"
          loading-text="Searching..."
          no-results-text="Oops. The product you are searching for could not be found"
          no-data-text="Oops. The product you are searching for could not be found"
        >
          <template v-slot:header>
            <v-toolbar
              elevation="0"
              class="mb-1 hidden-md-and-down"
            >
              <template>
                <span class="mr-3 title">Sort By:</span>
                <v-select
                  v-model="localSortBy"
                  flat
                  class="primary--text select-field"
                  hide-details
                  :items="sortColumns"
                  item-text="name"
                  item-value="value"
                  outlined
                  label="Sort by"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn-toggle
                  v-model="localSortDesc"
                  mandatory
                >
                  <v-btn
                    large
                    depressed
                    :value="false"
                  >
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    large
                    depressed
                    :value="true"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
            <v-progress-linear :indeterminate="true" v-if="isLoading"></v-progress-linear>
          </template>

          <template v-slot:default="props">
            <v-row>
              <v-col
                cols="12" sm="12" md="6" lg="4" xl="4"
                v-for="(item, index) in props.items"
                :key="`${index}-${item.id}`"
                class="list-product-item"
                :data-cy="`product-item-${index}`"
              >
                <ProductItem :item="item" />
              </v-col>
            </v-row>
          </template>

        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
// eslint-disable-next-line no-unused-vars
import { debounce } from 'lodash';
import ProductItem from '../components/ProductItem.vue';
import KeyValueFilter from '../components/KeyValueFilter.vue';
import filters from './filters';

export default {
  name: 'ProductList',
  components: {
    ProductItem,
    KeyValueFilter,
  },
  mixins: [filters],
  data() {
    return {
      dialog: false,
      showFilters: this.$vuetify.breakpoint.lgAndUp,
      isLoading: true,
      itemsPerPage: 50,
      showAllGroups: false,
      sortColumns: [{
        name: 'Price',
        value: 'ExclUnitSellingPrice',
      }, {
        name: 'Description',
        value: 'StockDescription',
      }, {
        name: 'Available',
        value: 'Available',
      }, {
        name: 'Stock Code',
        value: 'StockCode',
      }],
      options: {},
      page: 1,
      productDeals: [],
    };
  },
  computed: {
    /** Vuex getters
     */
    ...mapGetters('clientAppSettings', ['getClientAppSetting']),
    ...mapGetters('filters', ['isFiltered']),
    ...mapGetters('products', ['productList', 'productGroups', 'brands']),
    ...mapState('products', ['favourites', 'totalProducts', 'stockCategories', 'productGroups', 'productBrands']),
    ...mapState('app', ['isLoggedIn']),
    filterTypes() {
      const result = [];
      if (this.productDeals.includes('SuperDeal')) {
        result.push('Super Deals');
      }
      if (this.favourites?.length > 0) {
        result.push('Favourites');
      }
      return result;
    },
    showVermontProducts() {
      return this.getClientAppSetting('showVermontProducts');
    },
    searchQuery() {
      const {
        search,
        itemsPerPage,
        sortBy,
        sortDesc,
        filter,
        filterCategory,
        filterProductGroup,
        filterBrand,
        options,
        page,
        favourites,
      } = this;
      return {
        search,
        itemsPerPage,
        sortBy,
        sortDesc,
        filter,
        filterCategory,
        filterProductGroup,
        filterBrand,
        options,
        favourites,
        page,
      };
    },
    visibleProductGroups() {
      if (this.showAllGroups) {
        return this.productGroups;
      }
      return this.productGroups.slice(0, 5);
    },
  },
  watch: {
    searchQuery: {
      immediate: true,
      deep: true,
      handler() {
        this.loadData();
      },
    },
    page() {
      const element = document.getElementById('productSearch');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    },
    // watch for route changes and refresh
    $route() {
      this.refreshFilters();
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products',
      [
        'LOAD_products',
        'LOAD_quickProducts',
        'DELETE_product',
        'LOAD_favouriteProducts',
        'LOAD_productGroups',
        'LOAD_stockCategories',
        'LOAD_productBrands',
        'LOAD_productDeals',
      ]),
    ...mapActions('customerDeals', ['LOAD_customerDeals']),
    ...mapActions('products', ['LOAD_products', 'LOAD_quickProducts', 'DELETE_product', 'LOAD_favouriteProducts']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    ...mapMutations('filters', ['SET_search']),
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.loadData();
        })(),
        (async () => {
          if (this.isLoggedIn) {
            await this.LOAD_customerDeals();
          }
        })(),
        (async () => {
          if (this.isLoggedIn) {
            await this.LOAD_favouriteProducts();
          }
        })(),
        (async () => {
          await this.LOAD_productGroups();
        })(),
        (async () => {
          await this.LOAD_stockCategories();
        })(),
        (async () => {
          await this.LOAD_productBrands();
        })(),
        (async () => {
          this.productDeals = await this.LOAD_productDeals();
        })(),
      ]);
      this.isLoading = false;
    },
    // eslint-disable-next-line func-names
    loadData: debounce(async function () {
      this.isLoading = true;
      const { searchQuery } = this;
      await this.LOAD_products(searchQuery);
      this.isLoading = false;
    }),
    /** Delete product from the collection
     */
    async remove(product) {
      this.isLoading = true;
      await this.DELETE_product(product);
      this.isLoading = false;
    },
    searchChanged(val) {
      this.localSearch = val;
    },
  },
};
</script>
<style>
.v-dialog--fullscreen {
  top: 100px;
  max-height: 80vh;
}
.clear-filters {
  color: white !important;
  background-color: #ED2A2A !important;
}
.search-card {
  background-color: #f2f2f2;
}
.select-field {
  max-width: 250px;
}
.c-grey {
  background-color:#F8F8F8 !important;
}
.c-available {
  color: #FFA500;
}
.c-available span {
  position: absolute;
  top: 25px;
  left: -5px;
  font-size: .65em !important;
}
.item-description {
  position: absolute;
  bottom: 5px;
  left: 22%;
  width: 100%;
}
.icon {
  float: right;
}
.search-card.hidden .v-card__text {
  display: none;
}
</style>

<style scoped>
.filter-container {
  border: 1px solid #424242;
}
</style>
<docs>
# Product example

'''js
<Product />
'''
</docs>
