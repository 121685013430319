<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
    >
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
      <template v-slot:item.createdBy="{ item }">
        <a
          :href="`mailto:${item.createdBy}?subject=${truncate(item.message, 20)}`"
        >
          {{ item.createdBy }}
        </a>
      </template>
      <template v-slot:item.screenshot="{ item }">
        <img
          style="cursor: pointer;"
          v-bind:src="item.screenshot"
          height="92"
          @click="showScreenshot(item)" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="default" small @click="completeItem(item)">
          Complete
        </v-btn>
        <v-btn color="error" small @click="remove(item)">
          Delete
        </v-btn>
      </template>
    </v-data-table>
      <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition">
        <v-card elevation="3">
          <v-card-actions>
            <v-btn @click="dialog = false">Close</v-btn>
          </v-card-actions>
          <v-card-text>
            <img v-bind:src="screenshot" />
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListFeedbackItems',
  module: 'common',
  props: ['items'],
  mixins: [loading],
  data() {
    return {
      dialog: false,
      screenshot: undefined,
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'screenshot',
          value: 'screenshot',
        },
        {
          text: 'message',
          value: 'message',
        },
        {
          text: 'created',
          value: 'created',
        },
        {
          text: 'createdBy',
          value: 'createdBy',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  methods: {
    ...mapActions('feedbackItems', ['UPDATE_feedbackItem', 'DELETE_feedbackItem']),
    /**
     * Executes completeItem
     */
    async completeItem(item) {
      this.isLoading = true;
      const updatedItem = { ...item };
      updatedItem.isActive = false;
      await this.UPDATE_feedbackItem(updatedItem);
      this.isLoading = false;
    },
    /**
     * Show modal with screenshot
     */
    showScreenshot(item) {
      this.dialog = true;
      this.screenshot = item.screenshot;
    },
    /** Delete Supplier from the collection
     */
    async remove(feedbackItem) {
      const result = await this.$confirm('Are you sure you want to delete this item?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_feedbackItem(feedbackItem.id);
        this.isLoading = false;
      }
    },
    truncate(input, len) {
      if (input.length > len) {
        return `${input.substring(0, len)}...`;
      }
      return input;
    },
  },
};
</script>
