<template>
  <v-container fluid>
    <v-row v-if="displayCart.length === 0" style="min-height: 1000px">
      <v-col cols="12">
        Your is empty.
        <v-btn class="mt-10" block color="success"
        to="/products/product-list"
        >Go Shopping</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="9">
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
            >
              Confirm Order
            </v-stepper-step>

            <v-divider v-if="!localIsLoggedIn"></v-divider>

            <v-stepper-step
              v-if="!localIsLoggedIn"
              :complete="currentStep > 2"
              step="2"
            >
              Registration
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="currentStep > deliveryDetailStep"
              :step="deliveryDetailStep"
            >
              Delivery Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :step="paymentStep"
            >
              Payment
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <CartCard />
              <div class="w-full d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="currentStep += 1"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content v-if="!localIsLoggedIn" step="2">
              <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                grow
              >
                <v-tab>
                  Register
                </v-tab>
                <v-tab>
                  Login
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <FormRegister @register="onRegister" />
                </v-tab-item>
                <v-tab-item>
                  <FormLogin @login="onLogin" />
                </v-tab-item>
              </v-tabs-items>
              <div class="full-width d-flex justify-space-between mt-2">
                <v-btn
                  text
                  @click="currentStep -= 1"
                >
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  @click="currentStep += 1"
                  :disabled="!$store.state.app.isLoggedIn"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content :step="deliveryDetailStep">
              <DeliveryDetails />
              <div class="full-width d-flex justify-space-between mt-2">
                <v-btn
                  text
                  @click="currentStep -= 1"
                >
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  @click="currentStep += 1"
                  :disabled="!deliveryDetailsComplete"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content :step="paymentStep">
              <div>
                <h3>Payment</h3>
                <p>
                  You will redirected to Yoco for your payment of R {{ formatCurrency(total) }}.
                  Please follow the instructions on the Yoco page to complete your transaction.
                </p>
              </div>
              <div class="full-width d-flex justify-space-between mt-2">
                <v-btn
                  text
                  @click="currentStep -= 1"
                >
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="isCheckingOut"
                  :disabled="isCheckoutSuccess"
                  @click="checkoutYoco"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="12" lg="3">
        <OrderSummary />
      </v-col>
    </v-row>
  </v-container>
  </template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import CartCard from './CartCard.vue';
import DeliveryDetails from './DeliveryDetails.vue';
import FormRegister from '../../../auth/src/components/FormRegister.vue';
import FormLogin from '../../../auth/src/components/FormLogin.vue';
import OrderSummary from './OrderSummary.vue';
import cartMixin from './cartMixin';

export default {
  name: 'CartPublic',
  mixins: [cartMixin],
  components: {
    CartCard,
    DeliveryDetails,
    FormLogin,
    FormRegister,
    OrderSummary,
  },
  data() {
    return {
      currentStep: 1,
      localIsLoggedIn: false,
      isCheckingOut: false,
      isCheckoutSuccess: false,
      tab: 0,
    };
  },
  computed: {
    ...mapGetters('app', ['isLoggedIn']),
    ...mapState('cart', ['deliveryDetails']),
    deliveryDetailStep() {
      if (this.localIsLoggedIn) {
        return 2;
      }
      return 3;
    },
    deliveryDetailsComplete() {
      if (!this.deliveryDetails.deliver) {
        return true;
      }
      if (this.deliveryDetails.physicalAddress5 === 'Western Cape') {
        return true;
      }
      if (this.deliveryDetails.deliver && this.deliveryDetails.selectedRate) {
        return true;
      }
      return false;
    },
    paymentStep() {
      if (this.localIsLoggedIn) {
        return 3;
      }
      return 4;
    },
    total() {
      let total = Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.total))), 0));

      if (this.deliveryDetails.selectedRate
        && this.deliveryDetails.deliver
      ) {
        total += this.deliveryDetails.selectedRate.rate;
      }
      return total;
    },
  },
  mounted() {
    this.localIsLoggedIn = this.isLoggedIn;
  },
  methods: {
    ...mapMutations('app', ['SET_token', 'SET_username']),
    ...mapActions('newOrder', ['SAVE_newPublicOrder']),
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
    async checkoutYoco() {
      this.isCheckingOut = true;
      const lines = this.displayCart.map((c) => ({
        stockCode: c.product.stockCode,
        orderedQty: c.amount,
        quantity: c.amount,
        vatRate: c.vat,
        unitSellingPriceExcl: c.product.exclUnitSellingPrice,
        stockDescription: c.product.description,
      }));
      try {
        const deliveryType = this.deliveryDetails.deliver ? 'D' : 'C';
        const result = await this.SAVE_newPublicOrder({
          salesOrder: {
            documentDate: new Date(),
            customerOrderNo: this.orderNumber,
            salesOrderLines: lines,
            deliveryDate: this.deliveryDate,
            deliveryDetails: deliveryType,
          },
          customer: this.localCustomerInfo,
          deliveryAddress1: this.deliveryDetails.physicalAddress1,
          deliveryAddress2: this.deliveryDetails.physicalAddress2,
          deliveryAddress3: this.deliveryDetails.physicalAddress3,
          deliveryAddress4: this.deliveryDetails.physicalAddress4,
          deliveryAddress5: this.deliveryDetails.physicalAddress5,
          selectedRateCode: this.deliveryDetails.selectedRate?.service_level.code,
          selectedRateName: this.deliveryDetails.selectedRate?.service_level.name,
          collectionDate: this.deliveryDetails.selectedRate?.service_level.collection_date,
          deliveryDate: this.deliveryDetails.selectedRate?.service_level.delivery_date_to,
          selectedRate: this.deliveryDetails.selectedRate?.rate,
        });
        this.isCheckoutSuccess = true;
        window.location.href = result.redirectUrl;
      } catch (error) {
        this.$root.$emit('toast:error', 'There was an error processing your order');
      }
      this.isCheckingOut = false;
      // const { data } = await axios.post('/api/payments/checkout', {
      //   amount: this.total,
      // });
      // const parsedData = JSON.parse(data);
    },
    async onRegister(registerData) {
      const result = await this.$auth.login(registerData.email, registerData.password);
      this.SET_token(result.data.access_token);
      this.SET_username(result.data.userName);
      this.currentStep += 1;
    },
    async onLogin() {
      try {
        await this.LOAD_customerInfo();
      } catch (error) {
        this.$root.$emit('toast:error', 'There was an error logging in');
      }
      this.currentStep += 1;
    },
    streamToString(stream) {
      const reader = stream.getReader();
      const decoder = new TextDecoder('utf-8');

      return reader.read().then((result) => decoder.decode(result.value));
    },
  },
};
</script>
<style>
  div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #666;
    padding: 40px;
    font-size: 25px;
  }
</style>
<!--
  <style scoped>
  .price-col {
    width: 100px;
  }
  .action-col {
    width: 50px;
  }
  .qty-col {
    width: 150px;
  }
  .stock-code-col {
    width: 150px;
  }
  .container {
    position: relative;
  }
  .sticky-div {
      position: sticky;
      top: 0;
  }
  </style> -->
