<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="brands"
          :search="search"
          :loading="isLoading"
          :items-per-page="150"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                class="mr-2"
                color="success"
                data-cy="SalesRep-button-new"
                @click="create"
              >
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                New
              </v-btn>
              <v-toolbar-title>Brands</v-toolbar-title>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Brand</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <FormBrand
                        @save="save"
                        @cancel="dialog = false"
                        :brand="brand" />
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.imageUrl="{ item }">
            <img :src="item.imageUrl" class="w-100" />
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDate(item.created) }}
          </template>
          <template v-slot:item.lastUpdated="{ item }">
            <span v-if="item.lastUpdated">{{ formatDate(item.lastUpdated) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn-toggle>
              <v-btn color="primary" small @click="edit(item)">Edit</v-btn>
              <v-btn color="danger" small @click="remove(item)">Delete</v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminBrands',
  mixins: [loading],
  components: {
    FormBrand: () => import('../components/FormBrand.vue'),
  },
  data() {
    return {
      dialog: false,
      search: '',
      brand: {},
      headers: [
        {
          text: '',
          value: 'imageUrl',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: 'Order',
          value: 'sortOrder',
        },
        // {
        //   text: 'Last Updated',
        //   value: 'lastUpdated',
        // },
        // {
        //   text: 'Last Updated By',
        //   value: 'lastUpdatedBy',
        // },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('brands', ['brands']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('brands',
      ['LOAD_brands', 'CREATE_brand', 'UPDATE_brand', 'DELETE_brand']),
    create() {
      this.brand = {};
      this.dialog = true;
    },
    edit(item) {
      this.brand = item;
      this.dialog = true;
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_brands();
      this.isLoading = false;
    },
    async remove(item) {
      const result = await this.$confirm('Are you sure you want to delete this item?');
      if (result) {
        this.DELETE_brand(item.id);
      }
    },
    async save(item) {
      this.dialog = false;
      this.isLoading = true;
      if (item.id) {
        await this.UPDATE_brand(item);
        await this.refresh();
      } else {
        await this.CREATE_brand(item);
      }
      this.isLoading = false;
    },
  },
};
</script>
