<template>
  <v-card ref="card" class="item-card my-5">
    <v-card-text>
      <v-row v-for="cart in displayCart" :key="cart.id">
        <v-col cols="4" md="2">
          <router-link :to="{
          name: 'products-ProductDetail',
          query: { productCode: cart.product.productCode }
          }">
            <img :src="getProductImage(cart)" class="cart-product-image">
          </router-link>
        </v-col>
        <v-col cols="8" md="5">
          <div class="d-flex flex-column pa-5 flex-grow-1">
            <span>{{ cart.product.description }}</span>
            <span>Code: {{ cart.product.stockCode }}</span>
            <div v-if="cart.product.available > 0">
              {{cart.product.available}} available
            </div>
            <div v-else style="color: #FF4500;">
            Backordered
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            outlined
            filled
            v-model="cart.amount"
            type="number"
            class="field"
            :rules="[rules.non_zero]"
            append-icon="mdi-plus"
            @click:append="increment_amount(cart.product.stockCode)"
            :prepend-inner-icon="getDecreaseIcon(cart.amount)"
            @click:prepend-inner="decrement_amount(cart.product.stockCode)">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <div class="d-flex flex-column align-end">
            <span>
              R {{formatCurrency(cart.total)}}
            </span>
            <span>
              ({{ cart.product.discountPercentage }}% off)
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn color="error" icon @click="remove(cart.product.stockCode)">
              <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
        <h3 v-if="cart.length > 0" class="mt-0 text-right" style="display: block">
        <span>Subtotal:</span>
        R {{ formatCurrency(total_amount) }}
        </h3>
        <h3 v-if="cart.length > 0" class="mt-0 mb-2 text-right" style="display: block">
        <span>VAT:</span>
        R {{ formatCurrency(totalVat) }}
        </h3>
        <h1 v-if="cart.length > 0" class="mb-5 text-right">
        <span>Total:</span>
        R {{ formatCurrency(total) }}
        </h1>
    </v-card-text>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import cartMixin from './cartMixin';

export default {
  name: 'CartCard',
  mixins: [cartMixin],
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {
    ...mapGetters('app', ['isLoggedIn', 'isPublic']),
    fixedCheckout() {
      return false;
    },
    totalVat() {
      return Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.vat))), 0));
    },
    total() {
      return Math.round(this.displayCart
        .reduce((acc, item) => (Number(acc) + (Number(item.total))), 0));
    },
  },
  methods: {
    getProductImage(cart) {
      return cart.product.merchandiseImgUrl ?? process.env.VUE_APP_Logo;
    },
  },
};
</script>
