<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-progress-linear class="mb-10" v-if="isLoading" :indeterminate="true"></v-progress-linear>
        <v-autocomplete
          :items="allProducts"
          :item-text="p => `${p.stockCode} - ${p.stockDescription}`"
          item-value="stockCode"
          filled
          data-cy="FormSuperDeal-stockCode"
          label="Product"
          v-model="localSuperDeal.stockCode"
          append-outer-icon="mdi-cloud-download-outline"
          @click:append-outer="loadProducts"
          :loading="loadingItems.includes('allProducts')"
        />
        <v-autocomplete
          :items="productGroups"
          item-text="value"
          item-value="key"
          filled
          data-cy="FormSuperDeal-productGroup"
          label="Product Group"
          v-model="localSuperDeal.productGroup"
          append-outer-icon="mdi-cloud-download-outline"
          @click:append-outer="loadNetwork({
            name: 'productGroups',
            method: LOAD_productGroups,
          })"
          :loading="loadingItems.includes('productGroups')"
        />
        <v-autocomplete
          :items="productBrands"
          item-text="value"
          item-value="key"
          filled
          data-cy="FormSuperDeal-brand"
          label="Product Brand"
          v-model="localSuperDeal.brand"
          append-outer-icon="mdi-cloud-download-outline"
          @click:append-outer="loadNetwork({
            name: 'productBrands',
            method: LOAD_productBrands,
          })"
          :loading="loadingItems.includes('productBrands')"
        />
        <v-autocomplete
          :items="sortedCustomers"
          item-text="customerName"
          item-value="customerAccount"
          filled
          data-cy="FormSuperDeal-customerAccount"
          label="Customer Account"
          v-model="localSuperDeal.customerAccount"
          append-outer-icon="mdi-cloud-download-outline"
          @click:append-outer="loadNetwork({
            name: 'customers',
            method: LOAD_customers,
          })"
          :loading="loadingItems.includes('customers')"
        />
        <v-autocomplete
          :items="customerCategories"
          filled
          data-cy="FormSuperDeal-category"
          label="Category"
          v-model="localSuperDeal.category"
          append-outer-icon="mdi-cloud-download-outline"
          @click:append-outer="loadNetwork({
            name: 'customers',
            method: LOAD_customers,
          })"
          :loading="loadingItems.includes('customers')"
        />
        <v-select
          :items="industries"
          filled
          data-cy="FormSuperDeal-industry"
          label="Industry"
          v-model="localSuperDeal.industry"
        />
        <v-text-field
          type="number"
          filled
          data-cy="FormSuperDeal-discountPercentage"
          label="Discount Percentage"
          v-model="localSuperDeal.discountPercentage"
          width="100"
        >
          <template v-slot:append>
            %
          </template>
        </v-text-field>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormSuperDeal-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormSuperDeal-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormSuperDeal',
  module: 'SuperDeal',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    SuperDeal: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      allProducts: [],
      isLoading: false,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSuperDeal
      */
      localSuperDeal: {},
      loadingItems: [],
    };
  },
  computed: {
    ...mapGetters('customers', ['customerCategories', 'industries', 'sortedCustomers']),
    ...mapState('products', ['productGroups', 'productBrands']),
    groups() {
      return this.productGroups.map((p) => ({ text: p.key, val: p.value }));
    },
  },
  watch: {
    SuperDeal() {
      this.localSuperDeal = this.SuperDeal;
    },
  },
  async mounted() {
    this.isLoading = false;
  },
  created() {
    this.localSuperDeal = this.SuperDeal;
  },
  methods: {
    async loadProducts() {
      this.loadingItems.push('allProducts');
      const result = await this.LOAD_allProducts();
      this.loadingItems.pop('allProducts');
      this.allProducts = result;
    },
    async loadNetwork(networkObj) {
      this.loadingItems.push(networkObj.name);
      await networkObj.method();
      this.loadingItems.pop(networkObj.name);
    },
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_allProducts', 'LOAD_productGroups', 'LOAD_productBrands']),
    ...mapActions('customers', ['LOAD_customers']),
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSuperDeal);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormSuperDeal example

'''js
<FormSuperDeal :SuperDeal="SuperDeal" />
'''
</docs>
