<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormTradeReference
      :TradeReference="editTradeReference"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormTradeReference from '../components/FormTradeReference.vue';

export default {
  components: {
    FormTradeReference,
  },
  name: 'EditTradeReference',
  module: 'Documents',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing TradeReference. Default to {}.
      */
      editTradeReference: {},
      /**
       * Query Id for TradeReference
       */
      tradeReferenceId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('tradeReferences', ['tradeReferences', 'tradeReference']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.tradeReferenceId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_tradeReference(this.tradeReferenceId);
      this.editTradeReference = this.tradeReference;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('tradeReferences', ['LOAD_tradeReference', 'CREATE_tradeReference', 'UPDATE_tradeReference']),
    /** Method used to save the item
     */
    async save(tradeReference) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_tradeReference(tradeReference);
      } else {
        await this.UPDATE_tradeReference(tradeReference);
      }
      this.isSaving = false;
      this.$router.push({ name: 'trade-reference-ListTradeReference' });
    },
  },
};
</script>

<docs>
# EditTradeReference example

'''js
<EditTradeReference :TradeReference="TradeReference" />
'''
</docs>
