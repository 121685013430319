<template>
  <div data-cy="FileUpload-input">
    <v-file-input
      hide-input
      v-model="file"
      :loading="isLoading"
      @change="fileChanged"
      :accept="accept"
      :prepend-icon="icon"
      :error-messages="errors"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiCamera } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ImageUpload',
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to image/*.
    */
    accept: {
      type: String,
      default: 'image/*',
    },
    /** Error message for max file size. Defaults to File is larger than the limit of 2Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 5Mb',
    },
    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: mdiCamera,
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Image',
    },
    /** Max file size. Defaults to 10000000 (10Mb).
    */
    maxFileSize: {
      type: Number,
      default: 50000000,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errors: [],
      file: null,
    };
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      } else if (file !== null) {
        this.upload();
      }
    },
    async upload() {
      const { file } = this;
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      this.$emit('input', `${baseUrl}/auroraApi/files/${result.id}`);
      this.file = null;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.v-text-field {
  margin-top: 0;
  padding-top: 0;
}
</style>
