<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-select
          filled
          :items="['Vermont']"
          v-model="localProductGroupDeal.dealType"
          label="Type"
        ></v-select>
        <v-select
          :items="customerCategories"
          item-text="value"
          item-value="key"
          filled
          data-cy="FormProductGroupDeal-customerCategory"
          label="Customer Category"
          v-model="localProductGroupDeal.customerCategory"
          :rules="rules.required"
        />
        <v-select
          :items="productGroups"
          item-text="value"
          item-value="key"
          filled
          data-cy="FormProductGroupDeal-productGroupAndDescription"
          label="Product Group"
          v-model="localProductGroupDeal.productGroupAndDescription"
          :rules="rules.required"
        />
        <v-text-field
          filled
          label="Discount %"
          type="number"
          v-model="localProductGroupDeal.discountPercentage"
        ></v-text-field>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormProductGroupDeal-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="$parent.isSaving"
            data-cy="FormProductGroupDeal-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormProductGroupDeal',
  module: 'auth',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    ProductGroupDeal: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      rules: {
        required: [
          (v) => !!v || 'This field is required',
        ],
      },
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCustomerProduct
      */
      localProductGroupDeal: {},
    };
  },
  computed: {
    ...mapGetters('customers', ['sortedCustomerCategories']),
    ...mapGetters('products', ['sortedProductGroups']),
    productGroups() {
      const { sortedProductGroups } = this;
      const any = {
        key: '*',
        value: 'Any Product Group',
      };
      const vermontProductGroups = sortedProductGroups.filter((p) => p.productType === 'Vermont');
      const result = [any, ...vermontProductGroups];
      return result;
    },
    customerCategories() {
      const { sortedCustomerCategories } = this;
      const any = {
        key: '*',
        value: 'Any Category',
      };
      const result = [any, ...sortedCustomerCategories];
      return result;
    },
  },
  watch: {
    ProductGroupDeal() {
      this.localProductGroupDeal = this.ProductGroupDeal;
    },
  },
  created() {
    this.localProductGroupDeal = this.ProductGroupDeal;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localProductGroupDeal);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
