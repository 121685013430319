<template>
  <div class="home" style="padding-bottom: 30px;">
    <!-- <div class="top-section" /> -->
    <topSection v-if="$vuetify.breakpoint.lgAndUp" />
    <MobileCategories v-else />
    <PageItemManager pageName="Home" />
    <PageItems pageName="Home" />
  </div>
</template>

<script>
import PageItemManager from '../../../../components/PageItemManager.vue';
import PageItems from '../../../../components/PageItems.vue';
import topSection from '../components/mainpage/topSection.vue';
import MobileCategories from '../components/MobileCategories.vue';

export default {
  name: 'ProductHome',
  components: {
    PageItemManager,
    PageItems,
    MobileCategories,
    topSection,
  },
};

</script>

<style scoped>
/* .top-section {
  height: 298px;
  width: 100%;
  position: fixed;
  background-color: #70c3e5;
  top: 0;
  left: 0;
  margin-top: 121px;
} */
</style>
