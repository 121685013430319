import { sumBy } from 'lodash';

export default {
  cart: (state) => state.cart,
  total_items: (state) => sumBy(state.cart, (c) => c.amount) ?? 0,
  displayCart: (state) => state.cart.map((c) => {
    const result = { ...c };
    let { price } = c.product;
    if (c.product.discountPercentage > 0) {
      price *= ((100 - c.product.discountPercentage) / 100);
    }
    result.subtotal = c.amount * price;
    result.vat = result.subtotal * 0.15;
    result.total = result.subtotal + result.vat;
    return result;
  }),
  exportCart: (state) => ({
    created: new Date(),
    items: state.cart.map((c) => ({
      quantity: c.amount,
      stockCode: c.product.stockCode,
      discountPercentage: c.product.discountPercentage,
      exclUnitSellingPrice: c.product.exclUnitSellingPrice,
    })),
  }),
  total_amount: (state, getters) => sumBy(getters.displayCart, (c) => c.subtotal),
};
