import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('creditApplication', 'creditApplications');

const customActions = {
  async LOAD_creditApplication({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`creditApplications(${id})`)
      .query({ $expand: 'BankSignatures,DebtorSignatures,SuretySignatures,SuretyWitnessSignatures,TradeReferences,WitnessSignatures' });
    commit('SET_creditApplication', data);
    return data;
  },
};

export default { ...actions, ...customActions };
