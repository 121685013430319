export default {
  products: [],
  newProducts: [],
  product: undefined,
  productBrands: [],
  favourites: [],
  totalProducts: 0,
  stockCategories: [],
  productGroups: [],
  productDeals: [],
};
