<template>
  <v-data-table
    :items="items"
    item-key="customerAccount"
    :headers="headers"
    sort-by="created"
    :sort-desc="true"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col offset="6" cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'CustomerList',
  data() {
    return {
      headers: [
        { text: 'Customer Name', value: 'customerName' },
        { text: 'Customer Account', value: 'customerAccount' },
        { text: 'SalesRep Name', value: 'salesRepName' },
        { text: 'Register Code', value: 'registerCode' },
        { text: 'Created', value: 'created' },
      ],
      search: '',
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
  },
};
</script>
