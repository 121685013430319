<template>
  <v-card :loading="isLoading">
    <v-card-title primary-title>
      Registered Customers
      <v-spacer></v-spacer>
      <ExportToExcelButton
        :data="exportCustomerUsers"
        name="Registered Customers"
      />
    </v-card-title>
    <v-card-text v-if="!isLoading">
      <CustomerUserList :items="mappedItems" @delete="remove" :isSaving="isSaving" />
    </v-card-text>
    <v-card-title primary-title>
      Customers
      <v-spacer></v-spacer>
      <ExportToExcelButton
        :data="exportCustomers"
        name="Customers"
      />
    </v-card-title>
    <v-card-text v-if="!isLoading">
      <CustomerList :items="mappedCustomers" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CustomerList from '../components/CustomerList.vue';
import CustomerUserList from '../components/CustomerUserList.vue';
import ExportToExcelButton from '../components/ExportToExcelButton.vue';

export default {
  name: 'CustomerUser',
  meta: {
    roles: ['Administrators'],
  },
  mixins: [loading],
  components: {
    CustomerList,
    CustomerUserList,
    ExportToExcelButton,
  },
  computed: {
    ...mapState('customers', ['customers']),
    ...mapState('customerUsers', ['customerUsers']),
    ...mapState('salesReps', ['salesReps']),
    mappedItems() {
      const { customers, customerUsers, salesReps } = this;
      return customerUsers.map((i) => {
        const result = { ...i };
        const customer = customers.find((c) => c.customerAccount === i.customerAccount);
        result.salesRep = customer?.salesRepName ?? 'No sales rep found';
        result.salesRepEmail = salesReps.find((s) => s.code === customer?.salesRep)?.email;
        return result;
      });
    },
    mappedCustomers() {
      const { customers, salesReps } = this;
      return customers.map((i) => {
        const result = { ...i };
        result.salesRep = i?.salesRepName ?? 'No sales rep found';
        result.salesRepEmail = salesReps.find((s) => s.code === i?.salesRep)?.email;
        return result;
      });
    },
    exportCustomerUsers() {
      return this.mappedItems.map((c) => ({
        Username: c.username,
        'Customer Account': c.customerAccount,
        'Sales Rep': c.salesRep,
        'Sales Email': c.salesRepEmail,
      }));
    },
    exportCustomers() {
      return this.mappedCustomers.map((c) => ({
        'Customer Account': c.customerAccount,
        'Customer Name': c.customerName,
        'SalesRep Name': c.salesRep,
        'Sales Email': c.salesRepEmail,
        'Register Code': c.registerCode,
      }));
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customerUsers();
      })(),
      (async () => {
        await this.LOAD_salesReps();
      })(),
      (async () => {
        await this.LOAD_customers();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapActions('customers', ['LOAD_customers']),
    ...mapActions('salesReps', ['LOAD_salesReps']),
    ...mapActions('customerUsers', ['LOAD_customerUsers', 'DELETE_customerUser']),
    remove(item) {
      this.isSaving = true;
      this.DELETE_customerUser(item.id);
      this.isSaving = false;
    },
  },
};
</script>
