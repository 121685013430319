import axios from 'axios';
import { filter } from 'lodash';

export default {
  namespaced: true,
  state: {
    roles: undefined,
    users: undefined,
    user: undefined,
  },
  getters: {
  },
  mutations: {
    roles(state, payload) {
      state.roles = payload;
    },
    users(state, payload) {
      state.users = payload;
    },
    user(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async loadRoles({ commit }) {
      const response = await axios.get('/api/userManagement/roles');
      commit('roles', response.data);
    },
    async loadUsers({ commit }) {
      const response = await axios.get('/api/userManagement/users');
      commit('users', response.data);
    },
    async removeUser({ commit, state }, user) {
      await axios.delete(`/api/userManagement/delete/${user.id}`);
      let users = Object.assign(state.users);
      users = filter(users, (u) => u.id !== user.id);
      commit('users', users);
    },
    async saveUser({ commit }, user) {
      await axios.put('/api/userManagement', user);
      commit('user', user);
    },
  },
};
