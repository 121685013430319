import { odata } from '../../conf';

export default {
  async LOAD_customerInfo({ commit, rootGetters }) {
    try {
      const data = await odata.o(rootGetters)
        .get('customerInfo')
        .query();
      commit('SET_customerInfo', data);
    } catch (error) {
      console.error(error);
    }
  },
  async UPDATE_customerInfo({ commit, rootGetters }, data) {
    const requestData = { ...data };
    delete requestData['@odata.context'];
    delete requestData['@odata.etag'];
    await await odata.o(rootGetters)
      .patch('customerInfo', requestData)
      .query();

    commit('SET_customerInfo', data);
    return data;
  },
};
