<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-left">
      <h1 class="d-block text-center mb-5">Credit Application</h1>
      <h3>Instructions:</h3>
      <ul class="mb-5">
        <li>Fill in all relevant info on all pages.</li>
        <li>Initial each page on the bottom right-hand corner.</li>
        <li>Date and sign both the credit facility and suretyship section.</li>
        <li>Attach the following documentation:</li>
      </ul>
      <h3>Company Information</h3>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormCreditApplication-registeredCompanyName"
              :label="$$t('registeredCompanyName')"
              v-model="localCreditApplication.registeredCompanyName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormCreditApplication-tradingName"
              :label="$$t('tradingName')"
              v-model="localCreditApplication.tradingName"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormCreditApplication-companyRegistrationNumber"
              :label="$$t('companyRegistrationNumber')"
              v-model="localCreditApplication.companyRegistrationNumber"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormCreditApplication-vatRegistrationNumber"
              :label="$$t('vatRegistrationNumber')"
              v-model="localCreditApplication.vatRegistrationNumber"
            />
          </v-col>
        </v-row>
        <h3>Credit Information</h3>
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormCreditApplication-expectedValueOfFirstOrder"
              :label="$$t('expectedValueOfFirstOrder')"
              v-model="localCreditApplication.expectedValueOfFirstOrder"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormCreditApplication-creditRequiredPerMonth"
              :label="$$t('creditRequiredPerMonth')"
              v-model="localCreditApplication.creditRequiredPerMonth"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10">Accounts Contact Person</h3>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-accountsContactName"
              :label="$$t('accountsContactName')"
              v-model="localCreditApplication.accountsContactName"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-accountsContactEmail"
              :label="$$t('accountsContactEmail')"
              v-model="localCreditApplication.accountsContactEmail"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-accountsContactPhone"
              :label="$$t('accountsContactPhone')"
              v-model="localCreditApplication.accountsContactPhone"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10">Procurement Contact Person</h3>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-procurementContactName"
              :label="$$t('procurementContactName')"
              v-model="localCreditApplication.procurementContactName"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-procurementContactEmail"
              :label="$$t('procurementContactEmail')"
              v-model="localCreditApplication.procurementContactEmail"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-procurementContactPhone"
              :label="$$t('procurementContactPhone')"
              v-model="localCreditApplication.procurementContactPhone"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10">Delivery Information</h3>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetNumber"
              :label="$$t('deliveryStreetNumber')"
              v-model="localCreditApplication.deliveryStreetNumber"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetName"
              :label="$$t('deliveryStreetName')"
              v-model="localCreditApplication.deliveryStreetName"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetUnit"
              :label="$$t('deliveryStreetUnit')"
              v-model="localCreditApplication.deliveryStreetUnit"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetSuburb"
              :label="$$t('deliveryStreetSuburb')"
              v-model="localCreditApplication.deliveryStreetSuburb"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetCity"
              :label="$$t('deliveryStreetCity')"
              v-model="localCreditApplication.deliveryStreetCity"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-deliveryStreetPostalCode"
              :label="$$t('deliveryStreetPostalCode')"
              v-model="localCreditApplication.deliveryStreetPostalCode"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10">Bank Details</h3>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-bankName"
              :label="$$t('bankName')"
              v-model="localCreditApplication.bankName"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-bankAccountNumber"
              :label="$$t('bankAccountNumber')"
              v-model="localCreditApplication.bankAccountNumber"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              data-cy="FormCreditApplication-bankBranchCode"
              :label="$$t('bankBranchCode')"
              v-model="localCreditApplication.bankBranchCode"
            />
          </v-col>
        </v-row>
        <h4 class="mt-10">
          Director Signatures ({{ localCreditApplication.bankSignatures.length }})</h4>
        <v-row>
          <v-col
            v-for="(bankSignature, index) in localCreditApplication.bankSignatures"
            :key="index"
            cols="12"
          >
            <FormSignature
              :Signature="bankSignature"
              type="creditApplication"
              :showPosition="false"
              :showIdNumber="true"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-addBankSignature"
              @click="addBankSignature"
              color="primary"
              >Add Director
            </v-btn>
          </v-col>
        </v-row>
        <h4>Trade References ({{ localCreditApplication.tradeReferences.length }})</h4>
        <v-row class="mb-10">
          <v-col
            v-for="(tradeReference, index) in localCreditApplication.tradeReferences"
            :key="index"
            cols="12"
          >
            <FormTradeReference
              :TradeReference="tradeReference"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-addTradeReference"
              @click="addTradeReference"
              color="primary"
              >
              Add Trade References
            </v-btn>
          </v-col>
        </v-row>
        <CreditApplicationTerms />
        <h3 class="mt-10 mb-5">Authorized Signatory</h3>
        <v-row>
          <v-col cols="1">
            <v-checkbox v-model="localCreditApplication.signeeCheck">
            </v-checkbox>
          </v-col>
          <v-col cols="11">
            <table>
              <tr>
                <td>
                  I,
                </td>
                <td class="input-td">
                  <v-text-field
                    outlined
                    class="ml-2"
                    hint="your name"
                    v-model="localCreditApplication.signatory.name"
                  />
                </td>
                <td>
                  , the undersigned, in my capacity as
                </td>
                <td>
                  <v-text-field
                    outlined
                    class="ml-2"
                    hint="your position"
                    v-model="localCreditApplication.signatory.position"
                  />
                </td>
                <td>
                  , of {{ localCreditApplication.registeredCompanyName }},
                  and duly authorized thereto,
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  declare that the above particulars
                  are true and correct and that I fully understand
                  and agree to the terms and conditions of sale by Legg & Wessel c.c.
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <FormSignature
              :Signature="localCreditApplication.signatory"
              :showDate="true"
              type="signatory"
            />
          </v-col>
        </v-row>
        <h3 class="mt-10">Witnesses ({{ localCreditApplication.witnessSignatures.length }})</h3>
        <v-row>
          <v-col
            v-for="(signature, index) in localCreditApplication.witnessSignatures"
            :key="index"
            cols="12"
          >
            <FormSignature
              :Signature="signature"
              type="creditApplication"
              :showPosition="false"
              :showIdNumber="false"
              :showDate="false"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-addDebtorSignatures"
              @click="addDebtorSignatures"
              color="primary"
              >Add Debtor
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class=" text-center" offset="5" cols="4">
            <v-checkbox
              data-cy="FormCreditApplication-isSurety"
              v-model="localCreditApplication.denySurety"
              label="Deny Surety"
            ></v-checkbox>
          </v-col>
        </v-row>
        <div v-if="!localCreditApplication.denySurety">
        <v-row>
          <v-col cols="12">
            <h2 class="d-block text-center">
              Deed of Suretyship
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center" cols="12">
            Mandatory for companies in business less than 3 years
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(suretySignature, index) in localCreditApplication.suretySignatures"
            :key="index"
            cols="12"
          >
            <FormSignature
              :Signature="suretySignature"
              type="creditApplication"
              :showPosition="false"
              :showIdNumber="true"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-addBankSignature"
              @click="addSuretySignature"
              color="primary"
              >Add Director
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            Do hereby bind myself as Surety for and Co-Principal Debtor
            jointly and severally in solidium with
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              :label="$$t('registeredCompanyName')"
              v-model="localCreditApplication.registeredCompanyName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            (hereinafter referred to as “The Debtor”) for the due and punctual payment by
            it of all amounts and sums of money which may now or at any time hereafter and
            from time to time become due to
          </v-col>
        </v-row>
        <h3 class="d-block text-center">LEGG & WESSEL c.c.</h3>
        <v-row>
          <v-col cols="12">
            I/WE declare that all admissions and acknowledgements of indebtedness by the Debtor
            shall be binding on ME/Us and the Creditor shall be a liberty
            (without effecting its rights hereunder) to realize securities given or granted
            in its favour, given time to or compound or make any other arrangements with Debtor
            and in the event of liquidation of the Debtor or compromise being effected with its
            Creditors no dividends or payments which the Creditor may receive shall prejudice its
            rights to recover from ME/US to the full extent of the amount owing to the Debtor as
            at date of liquidation or compromise as the case may be.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            I/WE hereby renounce the benefits of the legal exceptions “no numeratae pecuniae”,
            “exception non cause debiti”, “errore calculi”,
            “beneficium de doubus vel pluribus reis debendi”,
            “beneficium ordinis se execussionis et divisionis”, “no value received”,
            with the full meaning force and effect whereof I/WE declare MYSELF/OURSELVES to
            be fully acquainted and choose domicilium citandi et executandi for
            all purposes at the business premises.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <table>
              <tr>
                <td>
                  Debtor dated the
                </td>
                <td>
                  <DatePicker v-model="localCreditApplication.suretyDate" />
                </td>
                <td>
                  furthermore, this Suretyship
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            shall remain as a covering Suretyship for so long as any
            moneys arising out of any cause of indebtedness whatsoever
            shall remain owing by the Debtor to the Creditor and any
            extension of time or indulgences which may be granted by
            the Creditor to the Debtor shall not be construed as a
            waiver of any of the Creditor’s rights against ME/US hereunder.
            I/WE hereby consent to the jurisdiction of the Magistrate’s
            court notwithstanding that the amount in issue may exceed
            the jurisdiction of such court.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <table>
              <tr>
                <td>
                  Dated at:
                </td>
                <td>
                  <DatePicker v-model="localCreditApplication.suretyDate" />
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <h3>Debtors ({{ localCreditApplication.debtorSignatures.length }})</h3>
        <v-row>
          <v-col
            v-for="(signature, index) in localCreditApplication.debtorSignatures"
            :key="index"
            cols="12"
          >
            <FormSignature
              :Signature="signature"
              type="creditApplication"
              :showPosition="false"
              :showIdNumber="false"
              :showDate="false"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-addDebtorSignatures"
              @click="addDebtorSignatures"
              color="primary"
              >Add Debtor
            </v-btn>
          </v-col>
        </v-row>
        <h3>Witnesses ({{ localCreditApplication.suretyWitnessSignatures.length }})</h3>
        <v-row>
          <v-col
            v-for="(signature, index) in localCreditApplication.suretyWitnessSignatures"
            :key="index"
            cols="12"
          >
            <FormSignature
              :Signature="signature"
              type="creditApplication"
              :showPosition="false"
              :showIdNumber="false"
              :showDate="false"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              data-cy="FormCreditApplication-suretyWitnessSignatures"
              @click="addSuretyWitnessSignatures"
              color="primary"
              >Add Witness
            </v-btn>
          </v-col>
        </v-row>
        </div>
        <Definitions />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCreditApplication-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormCreditApplication-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
    </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CreditApplicationTerms from './CreditApplicationTerms.vue';
import FormSignature from './FormSignature.vue';
import FormTradeReference from './FormTradeReference.vue';
import DatePicker from './DatePicker.vue';
import Definitions from './Definitions.vue';
import signatoryContent from './SignatoryContent.json';

export default {
  name: 'FormCreditApplication',
  module: 'Documents',
  components: {
    CreditApplicationTerms,
    FormSignature,
    FormTradeReference,
    DatePicker,
    Definitions,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CreditApplication: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      signatoryContent,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCreditApplication
      */
      localCreditApplication: {},
    };
  },
  // computed: {
  //   checkboxText() {
  //     return this.signatoryContent.check
  //       .replace('{{signeeName}}', this.localCreditApplication.signatory?.name)
  //       .replace('{{signeePosition}}', this.localCreditApplication.signatory?.position)
  //       .replace('{{registeredCompanyName}}', this.localCreditApplication.registeredCompanyName);
  //   },
  // },
  watch: {
    CreditApplication() {
      this.localCreditApplication = this.CreditApplication;
    },
  },
  created() {
    this.localCreditApplication = this.CreditApplication;
  },
  methods: {
    /**
     * Executes addBankSignature
     */
    addBankSignature() {
      this.localCreditApplication.bankSignatures.push({
        name: '',
      });
    },
    addSuretySignature() {
      this.localCreditApplication.suretySignatures.push({});
    },
    /**
     * Executes addSuretyWitnessSignatures
     */
    addSuretyWitnessSignatures() {
      this.localCreditApplication.suretyWitnessSignatures.push({});
    },
    /**
     * Executes addDebtorSignatures
     */
    addDebtorSignatures() {
      this.localCreditApplication.debtorSignatures.push({});
    },
    /**
     * Executes addTradeReference
     */
    addTradeReference() {
      this.localCreditApplication.tradeReferences.push({
        companyName: '',
        contactName: '',
        contactNumber: '',
      });
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCreditApplication);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style>
.input-td {
  width: 250px;
}
</style>
