<template>
  <v-btn class="ml-2" color="success"
    v-show="data"
    @click="exportToExcel"
    :loading="isLoading"
  >
    <v-icon class="mr-2">mdi-file-excel</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
import { json2excel } from 'js2excel';

export default {
  name: 'ExportToExcelButton',
  props: ['data', 'name', 'label'],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async exportToExcel() {
      this.isLoading = true;
      const { data, name } = this;
      await json2excel({
        data,
        name,
        formateDate: 'yyyy/mm/dd',
      });
      this.isLoading = false;
    },
  },
};
</script>
