<template>

  <v-form ref="form">
    <v-row dense>
      <v-col cols="6">
        <v-radio-group v-model="localDeliveryDetails.deliver" row>
          <v-radio :value="true" label="Delivery">
          </v-radio>
          <v-radio :value="false" label="Collection">
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <div v-if="localDeliveryDetails.deliver">
      <h5>Delivery Address (Free delivery to the Western Cape)</h5>
      <v-text-field
        v-model="localDeliveryDetails.physicalAddress1"
        class="rounded-0"
        placeholder="Street Address Line 1"
        filled outlined
      />
      <v-text-field
        v-model="localDeliveryDetails.physicalAddress2"
        class="rounded-0"
        placeholder="Street Address Line 2"
        filled outlined
      />
      <v-text-field
        v-model="localDeliveryDetails.physicalAddress3"
        class="rounded-0"
        placeholder="City"
        filled
        outlined
      />
      <v-text-field
        v-model="localDeliveryDetails.physicalAddress4"
        class="rounded-0"
        placeholder="Code"
        filled
        outlined
      />
      <v-select
        v-model="localDeliveryDetails.physicalAddress5"
        :items="provinces"
        class="rounded-0"
        placeholder="Province"
        filled
        outlined
      />
    </div>
    <div v-if="localDeliveryDetails.deliver === false">
      <h3>Collection Address</h3>
      <div>We are open from 8am to 4pm weekdays</div>
      <a :href="mapLink" target="_blank" class="mapLink d-block">34 Natal Street</a>
      <div class="d-block">Paarden Eiland</div>
      <div class="d-block">Cape Town</div>
      <div class="d-block">7405</div>
    </div>
    <div
      v-if="isPublic
        && localDeliveryDetails.physicalAddress5 !== 'Western Cape'
        && localDeliveryDetails.deliver"
      class="w-full d-flex justify-end"
    >
      <v-btn v-if="rates.length === 0" :loading="isLoadingEstimate" @click="getEstimate">
        Get Estimate
      </v-btn>
      <v-select
        v-if="rates.length > 0"
        filled
        outlined
        v-model="localDeliveryDetails.selectedRate"
        label="Select Rate"
        :items="rates"
        :item-text="r => getRateText(r)" return-object>
      </v-select>
    </div>
    <v-text-field
      v-if="!isPublic"
      outlined
      filled
      name="OrderNumber"
      label="Order Number"
      v-model="orderNumber"
      data-cy="cart-orderNumber"
      required
      :rules="[rules.required]"
    />
  </v-form>
</template>

<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
import validation from './validation';

export default {
  name: 'DeliveryDetails',
  mixins: [validation],
  data() {
    return {
      localDeliveryDetails: {
        deliver: true,
      },
      mapLink: 'https://goo.gl/maps/mMrWEZYULN8ZnzGS9',
      isLoadingEstimate: false,
      rates: [],
      orderNumber: '',
      selectedRate: undefined,
      deliveryDate: (new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)))
        .toISOString()
        .substring(0, 10), // defaults to 2 days from now,
    };
  },
  computed: {
    ...mapGetters('app', ['isPublic']),
    ...mapGetters({
      cart: 'cart/cart',
      total_amount: 'cart/total_amount',
    }),
    ...mapState('customerInfo', ['customerInfo']),
    ...mapState('app', ['provinces']),
    ...mapState('cart', ['deliveryDetails']),
  },
  mounted() {
    const { deliveryDetails } = this;
    this.localDeliveryDetails = {
      physicalAddress1: deliveryDetails.physicalAddress1,
      physicalAddress2: deliveryDetails.physicalAddress2,
      physicalAddress3: deliveryDetails.physicalAddress3,
      physicalAddress4: deliveryDetails.physicalAddress4,
      physicalAddress5: deliveryDetails.physicalAddress5,
      deliver: deliveryDetails.deliver ?? true,
    };
  },
  watch: {
    localDeliveryDetails() {
      this.SET_deliveryDetails(this.localDeliveryDetails);
    },
  },
  methods: {
    ...mapMutations('cart', ['SET_deliveryDetails']),
    getRateText(r) {
      return `R ${r.rate} - ${r.service_level.name} - ${this.formatDate(r.service_level.delivery_date_to)}`;
    },
    async getEstimate() {
      //
      this.isLoadingEstimate = true;
      try {
        const lines = this.cart.map((c) => ({
          stock_code: c.product.stockCode,
          quantity: c.amount,
        }));
        const { data } = await this.$http.post('api/delivery-quotes', {
          salesOrder: {
            salesOrderLines: lines,
            deliveryDate: this.deliveryDate,
            deliveryDetails: 'D',
          },
          customer: {
            physical_address_1: this.localDeliveryDetails.physicalAddress1,
            physical_address_2: this.localDeliveryDetails.physicalAddress2,
            physical_address_3: this.localDeliveryDetails.physicalAddress3,
            physical_address_4: this.localDeliveryDetails.physicalAddress4,
          },
        });
        this.rates = data.rates.filter((r) => r.service_level.code !== 'SDX');
      } catch (error) {
        console.error(error);
        this.$root.$emit('toast:notify', 'Estimate not available');
      } finally {
        this.isLoadingEstimate = false;
      }
    },
  },
};
</script>
