<template>
  <div class="home-page-box d-flex align-end"
    :style='{ backgroundColor }'>
    <div :style="style">
        <v-container fluid fill-height>
          <slot name="content">
            <v-layout align-center justify-center>
              <v-flex>
                <h2>
                  {{ text }}
                </h2>
                <v-btn
                  class="rounded-lg"
                  elevation="4"
                  tile
                  x-large
                  :to="href"
                >{{ buttonText }}</v-btn>
              </v-flex>
            </v-layout>
          </slot>
        </v-container>
    </div>
  </div>
</template>

<script>
import HomePageBoxItem from './HomePageBoxItem';

export default {
  mixins: [HomePageBoxItem],
};
</script>

<style lang="scss" scoped>
  .btn-section {
    position: relative;
    top: 120px;
  }

  .home-page-box {
    width: 100%;
    height: 298px;
    box-sizing: border-box;
    margin-top: -12px;
  }
  .home-page-box h2 {
    color: #fff;
    margin-bottom: 50px;
    position: relative;
    top: 0;
  }
  .home-page-box > div {
    z-index: 2;
    height: 100%;
    padding: 20px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
</style>
