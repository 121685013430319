import { set } from '../../utils';

const customMutations = {
  SET_sortBy: set('sortBy'),
  SET_sortDesc: set('sortDesc'),
  SET_search: set('search'),
  SET_filter: set('filter'),
  SET_filterCategory: set('filterCategory'),
  SET_filterProductGroup: set('filterProductGroup'),
  SET_filterBrand: set('filterBrand'),
  RESET_filters(state) {
    state.sortBy = 'stockDescription';
    state.sortDesc = true;
    state.search = '';
    state.filter = '';
    state.filterCategory = [];
    state.filterProductGroup = [];
    state.filterBrand = [];
  },
};

export default customMutations;
