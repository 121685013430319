import {
  mapActions, mapMutations, mapGetters,
} from 'vuex';

export default {
  data() {
    return {
      savingCart: false,
      rules: {
        non_zero: (value) => (value <= 0 ? 'Add proper Ammount' : true),
      },
    };
  },
  computed: {
    ...mapGetters('cart', ['cart', 'displayCart', 'total_amount', 'total_items', 'exportCart']),
  },
  methods: {
    ...mapActions('cart', ['decrement_amount', 'increment_amount']),
    ...mapActions('carts', ['CREATE_cart']),
    ...mapMutations('cart', ['RESET_CART']),
    getDecreaseIcon(amount) {
      if (amount > 1) {
        return 'mdi-minus';
      }
      return ' ';
    },
    remove(id) {
      this.$store.commit('cart/REMOVE_CART', id);
      this.$store.commit('SET_SNACKBAR', {
        message: 'Successfully Removed item from the cart.',
        value: true,
        status: 'success',
      });
    },
    increment() {
      this.amount = parseInt(this.amount, 10) + 1;
    },
    decrement() {
      this.amount = parseInt(this.amount, 10) - 1;
    },
    getDiscountPrice(item) {
      console.debug(item);
      return item.product.price * ((100 - item.product.discountPercentage) / 100);
    },
    async saveCart() {
      const { exportCart } = this;
      this.savingCart = true;
      await this.CREATE_cart(exportCart);
      this.savingCart = false;
      this.$root.$emit('refresh');
      this.$root.$emit('toast:notify', 'Saved cart');
      this.RESET_CART();
    },
  },
};
