<template>
  <v-row class="mb-20 mt-1">
    <v-col
      v-for="(item, index) in items" :key="index"
      cols="6" lg="4" xl="3" class="text-center">
      <router-link :to="item.to">
        <v-img
          :src="item.src"
          class="mx-auto mobile-rounded"
          contain
        >
        <span class="category-text white--text">
          {{ item.text }}
        </span>
        </v-img>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable global-require */

export default {
  name: 'MobileCategories',
  computed: {
    items() {
      return [
        {
          src: require('../assets/mobile-images/LW_Web_Deals_300x300.png'),
          text: '',
          to: { name: 'products-ProductList', query: { filterDeal: 'SuperDeals' } },
        },
        {
          src: require('../assets/mobile-images/LW_RecentPurchases_300x300.png'),
          text: '',
          to: { name: 'products-ProductList', query: { filter: 'Favourites' } },
        },
        {
          src: require('../assets/mobile-images/LW_Web_Hygiene_300x300.png'),
          text: 'Hygiene',
          to: { name: 'products-ProductList', query: { filterCategory: 'HYGI' } },
        },
        {
          src: require('../assets/mobile-images/LW_Web_Packaging_300x300.png'),
          text: 'Packaging',
          to: { name: 'products-ProductList', query: { filterCategory: 'PACK' } },
        },
        {
          src: require('../assets/mobile-images/LW_Web_Safety2_300x300.png'),
          text: 'Safety',
          to: { name: 'products-ProductList', query: { filterCategory: 'SAFE' } },
        },
        {
          src: require('../assets/mobile-images/LW_Web_Tools_300x300.png'),
          text: 'Tools',
          to: { name: 'products-ProductList', query: { filterCategory: 'TOOL' } },
        },
      ];
    },
  },
};
</script>
<style scoped>
.category-text {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}
.mobile-rounded {
  border-radius: 5px;
}
</style>
