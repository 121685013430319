<template>
  <div class="mt-10">
    <ProgressBarTimeout v-if="isLoading" color="primary" />
    <v-btn
      v-if="mode === 'view' && canEdit"
      @click="mode = 'edit'"
      title="Edit content"
    >
      <v-icon class="mr-2" color="secondary">{{ mdiPencil }}</v-icon>
      Edit Content
    </v-btn>
    <div v-if="mode === 'view' && pageItem" v-html="pageItem.content">
    </div>
    <div v-else-if="mode === 'edit'">
      <VueEditor v-model="content" />
      <v-btn
        color="success"
        class="float-right cursor-pointer mt-5"
        @click="save"
        title="Save changes"
      >
        <v-icon class="mr-2">{{ mdiCheck }}</v-icon>
        Save changes
      </v-btn>
      <v-btn
        class="float-right cursor-pointer mt-5 mr-5"
        @click="cancel"
        title="Cancel changes"
      >
        <v-icon class="mr-2">mdi-close</v-icon>
        Cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPencil, mdiCheck } from '@mdi/js';
import { VueEditor } from 'vue2-editor';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import content from '../mixins/content';
import PageItemManager from './PageItemManager.vue';
import ProgressBarTimeout from './ProgressBarTimeout.vue';

export default {
  name: 'Content',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PageItemManager,
    ProgressBarTimeout,
    VueEditor,
  },
  mixins: [content, loading],
  props: {
    /**
     * Holds the pageItemId value, which is used to fetch the pageItem from the API.
     * @type {String}
     * @required
     */
    pageItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiCheck,
      mdiPencil,
      content: '',
      pageItem: undefined,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('pageItems', ['LOAD_pageItem', 'UPDATE_pageItem']),
    async refresh() {
      this.isLoading = true;
      this.pageItem = await this.LOAD_pageItem(this.pageItemId);
      this.content = this.pageItem.content;
      this.isLoading = false;
    },
    async cancel() {
      this.isLoading = true;
      this.content = this.pageItem.content;
      this.isLoading = false;
      this.mode = 'view';
    },
    async save() {
      this.isLoading = true;
      await this.UPDATE_pageItem({
        ...this.pageItem,
        content: this.content,
      });
      this.isLoading = false;
      await this.refresh();
      this.mode = 'view';
    },
  },
};
</script>
