<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :block="block"
        class="rounded-lg"
        color="primary"
        v-bind="attrs"
        v-on="on"
        data-cy="cart-confirmCheckout"
      >Confirm Checkout</v-btn>
    </template>

    <v-card>
      <v-card-title>
        Order
      </v-card-title>

      <v-card-text>
        <div v-if="!isSuccess">
          <v-form ref="form">
            Total: <b>R {{ formatCurrency(total_amount) }}</b>
            <v-radio-group v-model="deliver">
              <v-radio :value="true" label="Delivery">
              </v-radio>
              <v-radio :value="false" label="Collection">
              </v-radio>
            </v-radio-group>
            <div v-if="deliver">
            <h5>Delivery Date</h5>
              <v-date-picker
                v-model="deliveryDate"
              ></v-date-picker>
              <h5>Delivery Address</h5>
              <v-text-field
                v-model="localCustomerInfo.physicalAddress1"
                class="rounded-0"
                placeholder="Street Address Line 1"
                filled
                outlined
              ></v-text-field>
              <v-text-field
                v-model="localCustomerInfo.physicalAddress2"
                class="rounded-0"
                placeholder="Street Address Line 2"
                filled
                outlined
              ></v-text-field>
              <v-text-field
                v-model="localCustomerInfo.physicalAddress3"
                class="rounded-0"
                placeholder="City"
                filled
                outlined
              ></v-text-field>
              <v-text-field
                v-model="localCustomerInfo.physicalAddress4"
                class="rounded-0"
                placeholder="Code"
                filled
                outlined
              ></v-text-field>
              <v-select
                v-model="localCustomerInfo.physicalAddress5"
                :items="provinces"
                class="rounded-0"
                placeholder="Province"
                filled
                outlined
              ></v-select>
            </div>
            <div v-if="isPublic && localCustomerInfo.physicalAddress5 !== 'Western Cape'">
              <v-btn
                v-if="rates.length === 0"
                :loading="isLoadingEstimate" @click="getEstimate"
              >
                Get Estimate
              </v-btn>
              <v-select
                v-if="rates.length > 0"
                label="Select Rate"
                v-model="selectedRate"
                :items="rates"
                :item-text="r => `R ${r.rate} - ${r.base_rate.group_name}`"
                return-object
              >
              </v-select>
            </div>
            <v-text-field
              v-if="!isPublic"
              outlined
              filled
              name="OrderNumber"
              label="Order Number"
              v-model="orderNumber"
              data-cy="cart-orderNumber"
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </div>
        <div v-else data-cy="cart-completed-message">
          <p>Thank you very much! Your order has been placed successfully.</p>
          <p>If we have all the stock you require, we will invoice your order and you will
            receive an email with a tracking link from our delivery team.</p>
          <p>If you have any questions, please no not hesitate to call or email us.</p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isSuccess"
          color="primary"
          :loading="isLoading"
          @click="do_checkout"
          data-cy="cart-buttonCheckout"
        >
          Checkout
        </v-btn>
        <v-btn
          v-else
          color="primary"
          text
          @click="complete"
          data-cy="cart-buttonCompleted"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
import validation from './validation';

export default {
  mixins: [validation],
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isSuccess: false,
      localCustomerInfo: {},
      isLoadingEstimate: false,
      rates: [],
      orderNumber: '',
      selectedRate: undefined,
      deliver: true,
      deliveryDate: (new Date(Date.now() + (2 * 24 * 60 * 60 * 1000)))
        .toISOString()
        .substring(0, 10), // defaults to 2 days from now,
    };
  },
  computed: {
    ...mapGetters('app', ['isPublic']),
    ...mapGetters({
      cart: 'cart/cart',
      total_amount: 'cart/total_amount',
    }),
    ...mapState('customerInfo', ['customerInfo']),
    ...mapState('app', ['provinces']),
  },
  watch: {
    customerInfo() {
      this.localCustomerInfo = this.customerInfo;
    },
  },
  mounted() {
    if (this.customerInfo) {
      this.localCustomerInfo = this.customerInfo;
    }
  },
  methods: {
    ...mapActions('cart', ['checkout']),
    ...mapActions('newOrder', ['SAVE_newOrder']),
    ...mapActions('customerInfo', ['UPDATE_customerInfo']),
    ...mapMutations('cart', ['RESET_CART']),
    streamToString(stream) {
      const reader = stream.getReader();
      const decoder = new TextDecoder('utf-8');

      return reader.read().then((result) => decoder.decode(result.value));
    },
    async do_checkout() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const lines = this.cart.map((c) => ({
          stockCode: c.product.stockCode,
          orderedQty: c.amount,
          quantity: c.amount,
          vatRate: c.vatRate,
          unitSellingPriceExcl: c.product.exclUnitSellingPrice,
          stockDescription: c.product.description,
        }));
        try {
          await this.SAVE_newOrder({
            salesOrder: {
              documentDate: new Date(),
              customerOrderNo: this.orderNumber,
              salesOrderLines: lines,
              deliveryDate: this.deliveryDate,
              deliveryDetails: 'D',
            },
            customer: this.localCustomerInfo,
          });
          this.isSuccess = true;
        } catch (error) {
          const errorMsg = JSON.parse(await this.streamToString(error.body));
          console.log(error, error.body, errorMsg);
          this.$root.$emit('toast:error', errorMsg?.error?.innererror?.message);
        }
        this.isLoading = false;
      }
    },
    complete() {
      this.RESET_CART();
      this.dialog = false;
      this.$router.push({ name: 'products-ProductList' });
    },
    async getEstimate() {
      //
      this.isLoadingEstimate = true;
      try {
        const lines = this.cart.map((c) => ({
          stock_code: c.product.stockCode,
          quantity: c.amount,
        }));
        const { data } = await this.$http.post('api/delivery-quotes', {
          salesOrder: {
            salesOrderLines: lines,
            deliveryDate: this.deliveryDate,
            deliveryDetails: 'D',
          },
          customer: {
            physical_address_1: this.localCustomerInfo.physicalAddress1,
            physical_address_2: this.localCustomerInfo.physicalAddress2,
            physical_address_3: this.localCustomerInfo.physicalAddress3,
            physical_address_4: this.localCustomerInfo.physicalAddress4,
          },
        });
        this.rates = data.rates;
      } catch (error) {
        console.error(error);
        this.$root.$emit('toast:notify', 'Estimate not available');
      } finally {
        this.isLoadingEstimate = false;
      }
    },
  },
};
</script>
