<template>
  <div class="top-section">
    <div class="child-1" />
    <div class="child-2">
      <HomePageBoxItem
        src="header"
        :text="text"
        buttonText="Shop Now"
        :background-color="backgroundColour"
        :href="{ name: 'products-ProductList' }"
      />
    </div>
    <div class="child-3" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HomePageBoxItem from '../HomePageBoxItem.vue';

export default {
  components: {
    HomePageBoxItem,
  },
  data() {
    return {
      text: 'Supplying the world’s best \n\rindustrial products for 150 years.',
    };
  },
  computed: {
    ...mapState('customerInfo', ['customerInfo']),
    ...mapState('app', ['isLoggedIn']),
    backgroundColour() {
      return process.env.VUE_APP_PRIMARY_COLOUR;
    },
    buttonText() {
      if (this.isLoggedIn) {
        return `HELLO, ${this.customerInfo?.customerName}`;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('customerInfo', ['LOAD_customerInfo']),
  },
  async created() {
    if (this.isLoggedIn) {
      await this.LOAD_customerInfo();
    }
  },
};
</script>
<style scoped lang="scss">
.top-section {
  position: relative;
  height: 298px;
}
.top-section > div {
  position: absolute;
}
.child-1 {
  width: 33vw;
  margin-left: -33vw;
  left: 0;
  height: 298px;
  background-color: var(--v-primary-base);
  margin-top: -12px;
}
.child-2 {
  width: 100%;
}
.child-3 {
  width: 33vw;
  margin-right: -33vw;
  right: 0;
  height: 298px;
  background-color: var(--v-primary-base);
  margin-top: -12px;
}
</style>
