export default {
  SET_jobsRecurring(state, value) {
    state.jobsRecurring = value;
  },
  SET_jobsBus(state, value) {
    state.jobsBus = value;
  },
  SET_jobs(state, value) {
    state.jobs = value;
  },
  SET_organizations(state, value) {
    state.organizations = value;
  },
  SET_allOrganizations(state, value) {
    state.allOrganizations = value;
  },
};
