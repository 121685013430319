<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <HangfireListRecurringJob title="Recurring Jobs" :hideActions="true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HangfireListRecurringJob from '../components/HangfireListRecurringJobs.vue';
import HangfireBusJobs from '../components/HangfireBusJobs.vue';

export default {
  name: 'HangfireJobs',
  components: {
    HangfireListRecurringJob,
    // eslint-disable-next-line vue/no-unused-components
    HangfireBusJobs,
  },
};
</script>
