<template>
  <div class="home-page-box d-flex align-end"
    style="min-width: 250px"
    :style='{ backgroundColor }'>
    <div :style="style">
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex>
              <h2>
                {{ text }}
              </h2>
              <v-btn
                class="rounded-lg"
                elevation="4"
                tile
                x-large
                :to="href"
              >{{ buttonText }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
    </div>
  </div>
</template>

<script>
import HomePageBoxItem from './HomePageBoxItem';

export default {
  mixins: [HomePageBoxItem],
};
</script>

<style lang="scss" scoped>
  .home-page-box {
    width: 100%;
    height: 608px;
    box-sizing: border-box;
    margin-top: -12px;
  }
  .home-page-box h2 {
    color: #fff;
    margin-left: 20px;
    margin-bottom: 50px;
  }
  .home-page-box  a {
    margin-left: 20px;
    margin-bottom: 300px;
  }
  .home-page-box > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 0%;
  }
</style>
