<template>
  <div>
    <div v-for="pageItem in pageItems" :key="pageItem.id">
      <component :is="pageItem.pageItemType.replace(' ', '')" :pageItemId="pageItem.id">
      </component>
      <v-btn class="mt-5" color="error" title="Delete this page item"
        @click="remove(pageItem)">
        <v-icon class="mr-2">{{ mdiDelete }}</v-icon>
        Delete
      </v-btn>
      <!-- <v-hover
        v-slot="{ hover }"
        close-delay="200"
      >
      <div>
      </div>
      </v-hover> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiDelete } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import Content from './Content.vue';
import NewArrivals from '../modules/products/src/components/mainpage/newArrivals.vue';
import BrandsCarousel from '../modules/products/src/components/mainpage/logosSlider.vue';

export default {
  name: 'PageItems',
  components: {
    BrandsCarousel,
    Content,
    NewArrivals,
  },
  mixins: [loading],
  props: {
    /**
     * Holds the page name value
     * @type {String}
     * @required
     */
    pageName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      mdiDelete,
      pageItems: [],
    };
  },
  mounted() {
    this.refresh();
    this.$root.$on('pages.updated', () => {
      this.refresh();
    });
  },
  destroyed() {
    this.$root.$off('pages.updated');
  },
  methods: {
    ...mapActions('pageItems', ['LOAD_pageItemsByPageName', 'UPDATE_pageItem']),
    async refresh() {
      this.isLoading = true;
      this.pageItems = await this.LOAD_pageItemsByPageName(this.pageName);
      this.isLoading = false;
    },
    async remove(pageItem) {
      const result = await this.$confirm('Are you sure you want to delete this?');
      if (!result) return;
      console.debug(pageItem);
      await this.UPDATE_pageItem({
        id: pageItem.id,
        isDeleted: true,
      });
      this.$root.$emit('pages.updated');
    },
  },
};
</script>
