import axios from 'axios';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('product', 'products');

const customActions = {
  async LOAD_quickProducts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({ $top: 100, $orderby: 'created DESC' });
    commit('SET_products', data);
  },
  async LOAD_productDeals({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products/ProductDeals')
      .query();
    commit('SET_productDeals', data);
    return data;
  },
  async LOAD_productBrands({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products/ProductBrands')
      .query();
    commit('SET_productBrands', data);
  },
  async LOAD_productGroups({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products/ProductGroups')
      .query();
    commit('SET_productGroups', data);
  },
  async LOAD_stockCategories({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products/StockCategories')
      .query();
    commit('SET_stockCategories', data);
  },
  async LOAD_favouriteProducts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products/Favourites')
      .query();
    commit('SET_favourites', data);
  },
  async LOAD_productByProductCode({ commit, rootGetters }, productCode) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({ $filter: `productCode eq '${productCode}'` });
    commit('SET_product', data);
    return data;
  },
  async LOAD_productFromCode({ commit, rootGetters }, stockCode) {
    const data = await odata.o(rootGetters)
      .get(`products('${stockCode}')`)
      .query();
    commit('SET_product', data);
    return data;
  },
  async LOAD_products({ commit, rootGetters }, searchQuery) {
    const isPublic = rootGetters['app/isPublic'];
    const {
      page,
      itemsPerPage,
      sortBy,
      sortDesc,
      filter,
      filterCategory,
      filterProductGroup,
      filterBrand,
      favourites,
      search,
    } = searchQuery;

    let orderby = sortBy;
    if (sortDesc) {
      orderby = `${orderby} asc`;
    } else {
      orderby = `${orderby} desc`;
    }
    const skip = (page - 1) * itemsPerPage;
    const filters = [];
    if (filterCategory?.length > 0) {
      const stockCategories = filterCategory.map((f) => `'${f}'`).join(',');
      filters.push(`StockCategory in (${stockCategories})`);
    }
    if (filterProductGroup?.length > 0) {
      const productGroups = filterProductGroup.map((f) => `'${f}'`).join(',');
      filters.push(`ProductGroup in (${productGroups})`);
    }
    if (filterBrand?.length > 0) {
      const brands = filterBrand.map((f) => `'${f}'`).join(',');
      filters.push(`MerchandiseBrand in (${brands})`);
    }
    if (filter === 'Favourites') {
      const favs = favourites.map((f) => `'${f}'`).join(',');
      filters.push(`StockCode in (${favs})`);
    }
    if (filter === 'Super Deals') {
      filters.push('DiscountType eq \'SuperDeal\'');
    }
    if (search) {
      let searchStr = `contains(tolower(StockDescription),tolower('${search}')) OR contains(tolower(StockCode),tolower('${search}'))`;
      searchStr += ` OR Tags/any(tag: tolower(tag/Tag) eq tolower('${search}'))`;
      filters.push(searchStr);
    }
    if (isPublic) {
      filters.push('merchandiseImgUrl ne null');
    }
    const q = {
      $orderby: orderby,
      $skip: skip,
      $top: searchQuery.itemsPerPage,
      $count: true,
    };
    if (filters.length > 0) {
      const $filter = filters.join(' AND ');
      q.$filter = $filter;
    }
    const arrayQuery = Object.entries(q);
    let qs = '';
    arrayQuery.forEach((arr) => {
      qs += `${arr.join('=')}&`;
    });
    const result = await axios.get(`odata/products?${qs}`);
    commit('SET_products', result.data.value);
    commit('SET_totalProducts', result.data['@odata.count']);
  },
  async LOAD_allProducts({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $select: 'StockCode,StockDescription',
      });
    return data;
  },
  async LOAD_allProductWithTags({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $select: 'StockCode,StockDescription,MerchandiseImgUrl',
        $filter: 'Tags/any()',
        $expand: 'Tags',
      });
    return data;
  },
  async LOAD_newProducts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('products')
      .query({
        $top: 20,
        $orderby: 'created DESC',
        // $filter: 'available gt 0',
      });
    commit('SET_newProducts', data);
  },
  async UPDATE_product({ rootGetters }, product) {
    const data = await odata.o(rootGetters)
      .patch(`products('${product.stockCode}')`, product)
      .query();
    return data;
  },
};

export default { ...actions, ...customActions };
